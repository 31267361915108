import numeral from "numeral";

export 	const isCreatorFound = (creator, searchValue) => {
	const creatorFirstName = creator?.firstname?.toUpperCase();
	const creatorLastName = creator?.lastname?.toUpperCase();
	const creatorName =  creator?.name?.toUpperCase();
	const newSearch = searchValue?.toUpperCase();
	return creatorFirstName.includes(newSearch) || creatorLastName.includes(newSearch) || creatorName.includes(newSearch);
}
// Numeral utils

export const showDecimalsOnlyIfNonZero = (value) =>{
	let returnValue =  numeral(value);
	if(returnValue.format("0.0 a").includes(".0")){
		return returnValue.format("0 a");
	}else return  returnValue.format("0.0 a")
}

//Handle forms functions Creators

export const handleChangeValue = (e) => {
	switch (e.target.name) {
		case 'external':
			return  e.target.checked;
		case 'followers':
		case 'subscribers':
		return  Number(numeral(e.target.value).value());
		default:
			return e.target.value;
	}
};
export const handleChangeArrayObjectValue = (e, index, attribute, object, objectName) => {
	let newArray = object[e.target.name];
	newArray[index][attribute] = e.target.value;
	object[e.target.name] = newArray;
	return object
};

