import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import {makeStyles} from "@material-ui/core/styles";
import React from "react";
import {Grid, Typography} from "@material-ui/core";

const SpeedDialButton = props =>{
	const useStyles = makeStyles((theme) => ({
		addButton: {
			backgroundColor: "#cd9988",
			color: "white",
			height: 42,
			minWidth: 42,
			width: 42,
			"&:hover": {
				backgroundColor: "#e8ad9c"
			},
		},
		speedDialActionStyle: {
			backgroundColor: "#cd9988",
			color: "white",
			"&:hover": {
				backgroundColor: "#e8ad9c"
			},
		},
		SpeedDial: {
			position: "static",
			marginTop: 10,
		},
	}))

	const { menuOpen, setMenuOpen, speedDialActions, isMobile } = props;
	const classes = useStyles();

	return(
		<Grid
			item
			style={{ height:'5rem', paddingRight:0,  position: "relative"}}
			data-html2canvas-ignore
		>
			<SpeedDial
				className={classes.SpeedDial}
				style={{ right: isMobile ? 0 : "inherit" }}
				FabProps={{ size: "medium", className: classes.addButton }}
				ariaLabel="Profile Actions"
				icon={<SpeedDialIcon />}
				onClose={_ => setMenuOpen(false)}
				onOpen={_ => setMenuOpen(true)}
				open={menuOpen}
				direction="down"
			>
				{speedDialActions.map(
					(action, key) =>
						action !== null && (
							<SpeedDialAction
								key={key}
								icon={
									<Typography
										variant="body1"
										style={{ textTransform: "none" }}
									>
										{action.label}
									</Typography>
								}
								FabProps={{
									classes: { root: classes.speedDialActionStyle },
									variant: "extended",
									style: { height: 42, borderRadius: 3 }
								}}
								onClick={_ => {
									setMenuOpen(false);
									action.action();
								}}
								tooltipTitle={action.label + "2"}
							/>
						)
				)}
			</SpeedDial>
		 </Grid>
	)

}
export default SpeedDialButton;
