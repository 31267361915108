import React, { useState } from "react";
import { connect } from "react-redux";

import { newCreator } from "../../actions/";
import NewCreatorStep1 from "./NewCreatorStep1";
import NewCreatorStep2 from "./NewCreatorStep2";
import NewCreatorStep3 from "./NewCreatorStep3";

import isemail from "isemail";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
	Dialog,
	DialogTitle as MuiDialogTitle,
	IconButton,
	DialogContent,
	DialogActions,
	CircularProgress,
	Grid,
	Divider,
} from "@material-ui/core";
import { Close as CloseIcon, CheckCircleOutlined } from "@material-ui/icons";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {handleChangeArrayObjectValue, handleChangeValue} from "../utils";


const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
	},
	backButton: {
		marginRight: theme.spacing(1),
	},
	mobileStepper: {
		paddingRight: 0,
		paddingLeft: 0,
	},
	instructions: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	closeButton: {
		position: "absolute",
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[900],
	},
	formContainer: {
		maxWidth: 500,
		margin: "auto",
	},
	paper: {
		justifyContent: "center",
		flexWrap: "wrap",
		marginTop: theme.spacing(2),
		padding: theme.spacing(1),
		backgroundColor: "transparent",
		border: "1px solid rgba(0, 0, 0, 0.23)",
	},
	paperTitle: {
		marginTop: -21,
		maxWidth: "fit-content",
		background: "white",
		paddingRight: 5,
		paddingLeft: 5,
	},
	buttonProgress: {
		position: "absolute",
		marginTop: 7,
		marginLeft: -130,
	},
	buttonError: {
		backgroundColor: "#fdb3ad",
	},
	buttonProgressFinish: {
		position: "absolute",
		right: "35px",
		bottom: "13px",
	},
	chip: {
		margin: theme.spacing(1),
	},
}));

function getSteps() {
	return ["Creator's informations", "Public profile", "Assign managers"];
}

const defaultInstagram = {
	male: "",
	female: "",
	likes: "",
	engagement: "",
	storyViews: "",
	postImpressions: "",
	primary: true,
	topAge: [{ id:0, age: "", percentage: "" }],
	topCity: [{ id:0, city: "", percentage: "" }],
	topCountry: [{ id:0, country: "", percentage: "" }],
}

const defaultYoutube = {
	male: "",
	female: "",
	likes: "",
	link:"",
	engagement: "",
	storyViews: "",
	subscribers: "",
	postImpressions: "",
	primary: false,
	topAge: [{ id:0, age: "", percentage: "" }],
	topCity: [{ id:0, city: "", percentage: "" }],
	topCountry: [{ id:0, country: "", percentage: "" }],
}

const defaultTikTok = {
	male: "",
	female: "",
	followers: "",
	likes: "",
	link:"",
	engagement: "",
	storyViews: "",
	postImpressions: "",
	primary: false,
	topAge: [{ id:0, age: "", percentage: "" }],
	topCity: [{ id:0, city: "", percentage: "" }],
	topCountry: [{ id:0, country: "", percentage: "" }],
}

const defaultCreator = {
	bio:"",
	status: "active",
	firstname: "",
	lastname: "",
	instagram: "",
	followers: "",
	email: "",
	phone: "",
	commission: "",
	external: false,
	male: "",
	female: "",
	likes: "",
	engagement: "",
	storyViews: "",
	postImpressions: "",
	topAge: [{ age: "", percentage: "" }],
	topCity: [{ city: "", percentage: "" }],
	topCountry: [{ country: "", percentage: "" }],
	photos: ["", ""],
	currency:"",
	managers: [],
	rates: [],
	address: {
		street: "",
		city: "",
		state: "",
		zip: "",
		country: "",
	},
	instagramStats: defaultInstagram,
	youtubeStats: defaultYoutube,
	tiktokStats: defaultTikTok,
};


const requiredStep1 = {
	firstname: false,
	lastname: false,
	instagram: false,
	followers: false,
	email: false,
	phone: false,
	address: false,
	currency:false
};

const requiredStep2 = {
	photos: false,
	instagramStats: {
		male: false,
		female: false,
		likes: false,
		engagement: false,
		storyViews: false,
		postImpressions: false,
	}
};

const requiredStep3 = {
	commission: false,
};

const NewCreator = (props) => {
	const classes = useStyles();
	const [activeStep, setActiveStep] = useState(0);
	const [creator, setCreator] = useState(defaultCreator);
	const steps = getSteps();
	const isMobile = useMediaQuery(useTheme().breakpoints.down("sm"));
	const [currErrors, setCurrErrors] = useState(requiredStep1);

	const handleNext = () => {
		const errors = checkErrors();
		if (errors) {
			setCurrErrors({ ...errors });
		} else {
			if (activeStep + 1 === steps.length) {
				props.newCreator(creator, (_) => {
					setActiveStep((prevActiveStep) => prevActiveStep + 1);
				});
			} else {
				let required;
				switch (activeStep) {
					case 0:
						required = { ...requiredStep1 };
						break;
					case 1:
						required = { ...requiredStep2 };
						break;
					case 2:
						required = { ...requiredStep3 };
						break;
					default:
						required = {};
						break;
				}
				setCurrErrors({ ...required });
				setActiveStep((prevActiveStep) => prevActiveStep + 1);
			}
		}
	};

	const checkErrors = (_) => {
		let required;
		switch (activeStep) {
			case 0:
				required = { ...requiredStep1 };
				break;
			case 1:
				required = { ...requiredStep2 };
				break;
			case 2:
				required = { ...requiredStep3 };
				break;
			default:
				required = {};
				break;
		}
		for (let [key, value] of Object.entries(creator)) {
			Object.keys(required).forEach((keyReq) => {
				if (Array.isArray(value)) {
					if (key === keyReq && value.some((item) => item === "")) {
						required[key] = true;
					} else if (key === keyReq && !value.some((item) => item === "")) {
						required[key] = false;
					}
				} else if (typeof value === "object") {
					if (
						key === keyReq &&
						Object.values(value).some((item) => item === "")
					) {
						if(key === "instagramStats"){
							for (let [key, value] of Object.entries(creator.instagramStats)) {
								Object.keys(required.instagramStats).forEach((keyReq) => {
									if (key === keyReq && value === ""){
										console.log(key)
										required.instagramStats[key] = true;
									}
									else if (key === keyReq && value !== ""){
											required.instagramStats[key] = false;
									}
								})
							}
						}
						else required[key] = true;
					} else if (
						key === keyReq &&
						!Object.values(value).some((item) => item === "")
					) {
						required[key] = false;
					}
				} else {
					if (keyReq === key && value === "") {
						required[key] = true;
					} else if (
						keyReq === key &&
						key === "email" &&
						!isemail.validate(value)
					) {
						required[key] = true;
					} else if (keyReq === key && value !== "") {
						required[key] = false;
					}
				}
			});
		}
		return Object.values(required).findIndex((item) => item) === -1
			? false
			: required;
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleReset = () => {
		setActiveStep(0);
		setCurrErrors({ ...requiredStep1 });
		setCreator({
			...defaultCreator,
			topAge: [{ age: "", percentage: "" }],
			topCity: [{ city: "", percentage: "" }],
			topCountry: [{ country: "", percentage: "" }],
			photos: ["", ""],
			managers: [],
		});
	};
	const handleChange = (e) => {
		setCreator({ ...creator, [e.target.name]:handleChangeValue(e)});
	}

	const handleChangeObject = (objectName, callFn = ()=>{}) => (e) => {
		let object = { ...creator[objectName] };
		object[e.target.name] = e.target.value;
		setCreator({ ...creator, [objectName]: object });
		callFn(e);
	};

	const handleChangeArray = (index, attribute) => (e) => {
		let newArray = creator[e.target.name];
		newArray[index][attribute] = e.target.value;
		setCreator({ ...creator, [e.target.name]: newArray });
	};

	const handleChangeArrayObject = (index, attribute, object, objectName) => (e) => {
		setCreator({ ...creator, [objectName] : handleChangeArrayObjectValue(e, index, attribute, object) });
	};

	const handleChangeArrayPhotos = (index, value) => {
		let newArray = creator.photos;
		newArray[index] = value;
		setCreator({ ...creator, photos: newArray });
	};

	const handleChangeArrayManagers = (value) => {
		let newArray = creator.managers;
		newArray.push(value);
		setCreator({ ...creator, managers: newArray });
	};

	const handleDeleteArrayManagers = (index) => {
		let newArray = creator.managers;
		newArray.splice(index, 1);
		setCreator({ ...creator, managers: newArray });
	};

	const addField = (voidObject, target, objectName) => {
		let newArray = creator[objectName][target];
		newArray.push(voidObject);
		let object = creator[objectName];
		object[target] = newArray;
		setCreator({ ...creator, [objectName]: object });
	};

	const getStepContent = (stepIndex) => {
		switch (stepIndex) {
			case 0:
				return (
					<NewCreatorStep1
						creator={creator}
						handleChange={handleChange}
						handleChangeObject={handleChangeObject}
						classes={classes}
						errors={currErrors}
					/>
				);
			case 1:
				return (
					<NewCreatorStep2
						handleChange={handleChange}
						creator={creator}
						handleChangeObject={handleChangeObject}
						handleChangeArrayObject={handleChangeArrayObject}
						handleChangeArray={handleChangeArray}
						handleChangeArrayPhotos={handleChangeArrayPhotos}
						addField={addField}
						classes={classes}
						errors={currErrors}
					/>
				);
			case 2:
				return (
					<NewCreatorStep3
						creator={creator}
						handleChange={handleChange}
						handleChangeArrayManagers={handleChangeArrayManagers}
						handleDeleteArrayManagers={handleDeleteArrayManagers}
						classes={classes}
						errors={currErrors}
					/>
				);
			default:
				return "Unknown stepIndex";
		}
	};

	return (
		<Dialog
			onClose={(_) => props.setOpen(false)}
			aria-labelledby="customized-dialog-title"
			open={props.open}
			fullWidth
			maxWidth="lg"
			fullScreen={isMobile}
			onExited={handleReset}
		>
			<MuiDialogTitle disableTypography className={classes.root}>
				<Typography variant="h6">ADD CREATOR</Typography>
				<IconButton
					aria-label="close"
					className={classes.closeButton}
					onClick={(_) => props.setOpen(false)}
				>
					<CloseIcon />
				</IconButton>
			</MuiDialogTitle>
			<DialogContent dividers>
				<Stepper
					activeStep={activeStep}
					alternativeLabel
					className={isMobile ? classes.mobileStepper : null}
				>
					{steps.map((label) => (
						<Step key={label}>
							<StepLabel>{label}</StepLabel>
						</Step>
					))}
				</Stepper>
				<div>
					{activeStep === steps.length ? (
						<div>
							<Grid
								container
								alignItems="center"
								justifyContent="center"
								className={classes.formContainer}
							>
								<Grid item xs={12}>
									<Typography align="center" variant="h1">
										<CheckCircleOutlined fontSize="inherit" color="primary" />
									</Typography>
								</Grid>
								<Grid item xs={9}>
									<Divider />
								</Grid>
								<Grid item xs={12} style={{ marginTop: 15 }}>
									<Typography align="center" variant="h5">
										<b>{creator.firstname + " " + creator.lastname}</b> has been
										created!
									</Typography>
								</Grid>
							</Grid>
						</div>
					) : (
						<div>{getStepContent(activeStep)}</div>
					)}
				</div>
			</DialogContent>
			<DialogActions>
				{activeStep === steps.length ? (
					<Button variant="contained" onClick={(_) => props.setOpen(false)}>
						Close
					</Button>
				) : (
					<>
						<Button
							disabled={activeStep === 0 || props.addingCreator}
							onClick={handleBack}
							className={classes.backButton}
						>
							Back
						</Button>
						<Button
							variant="contained"
							onClick={handleNext}
							disabled={props.addingCreator}
						>
							{activeStep === steps.length - 1 ? "Finish" : "Next"}
						</Button>{" "}
					</>
				)}
				{props.addingCreator && (
					<CircularProgress
						size={24}
						className={classes.buttonProgressFinish}
					/>
				)}
			</DialogActions>
		</Dialog>
	);
};

function mapDispatchToProps(dispatch) {
	return {
		newCreator: (creator, callBack) => dispatch(newCreator(creator, callBack)),
	};
}

function mapStateToProps(state) {
	return {
		creators: state.creators,
		creatorsLoading: state.app.creatorsLoading,
		addingCreator: state.app.addingCreator,
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(NewCreator);
