import React from "react";
import {
	Avatar,
	Box,
	Divider,
	Grid,
	Typography,
} from "@material-ui/core";

const TopCountryDemographics = props =>{
	const {
		classes,
		currentStats,
	} = props;

	if(currentStats?.topCountry[0]?.country){
		return(
			<Grid item xs={5} md={3}>
				<Typography
					variant="h5"
					gutterBottom
					style={{color:"#cd9988", fontWeight:500}}
				>
					Countries
				</Typography>
				{currentStats !== null &&
					currentStats.topCountry.map((country, key) => (
						<Grid
							key={key}
							container
							direction="row"
							alignItems="center"
							justifyContent="flex-start"
							style={{ margin: 1}}
						>
							<Grid item xs={4}>
								<Typography
									variant="h6"
									noWrap
									className={classes.topStats}
								>
									{country.percentage + "%"}
								</Typography>
							</Grid>
							<Grid item xs={2}>
								<Divider
									className={classes.divider}
									orientation="vertical"
									style={{height:15, marginRight:0}}
								/>
							</Grid>
							<Grid item xs={6}>
								<Typography variant="subtitle2">
									{country.country}
								</Typography>
									</Grid>
						</Grid>
					))}

			</Grid>
		);
	}

	return null
}

const TopCityDemographics = props =>{
	const {
		classes,
		currentStats,
	} = props;

	if(currentStats?.topCity[0]?.city){
		return(
			<Grid item xs={4} md={3}>
				<Typography
					variant="h5"
					gutterBottom
					style={{color:"#cd9988", fontWeight:500}}
				>
					Cities
				</Typography>
				{currentStats !== null &&
					currentStats.topCity.map((city, key) => (
							<Grid
								key={key}
								container
								direction="row"
								alignItems="center"
								justifyContent="flex-start"
								style={{ margin: 1}}
							>
								<Grid item xs={4}>
									<Typography
										variant="h6"
										noWrap
										className={classes.topStats}
									>
										{city.percentage + "%"}
									</Typography>
								</Grid>
								<Grid item xs={2}>
									<Divider
										className={classes.divider}
										orientation="vertical"
										style={{height:15, marginRight:0}}
									/>
								</Grid>
								<Grid item xs={6}>
									<Typography variant="subtitle2">
										{city.city}
									</Typography>
								</Grid>
							</Grid>
					))}
			</Grid>
		);
	}

	return null
}

const TopAgeDemographics = props =>{
	const {
		classes,
		currentStats,
	} = props;

	if(currentStats?.topAge[0]?.age){
		return(
			<Grid item id='Ages'>
				<Box m={1}>
				<Grid container justifyContent="center" alignItems="center">
					{currentStats !== null &&
						currentStats.topAge.map((age, key) => (
							<Grid key={key} item style={{paddingLeft:'0.5rem'}}>
								<Avatar className={classes.avatarAge} >
									<Grid container style={{textAlign:'center'}}>
										<Grid item xs={12}>
											<Typography
												className={classes.circularText}
												variant="body2"
											>
												{age.age}
											</Typography>
										</Grid>
										<Grid item xs={12}>
											<Typography
												className={classes.circularText}
												variant="body2"
											>
												<b>{age.percentage}%</b>
											</Typography>
										</Grid>
									</Grid>
								</Avatar>
							</Grid>
						))}
				</Grid>
			</Box>
			</Grid>
		);
	}
	return null
}

const TopGenderDemographics = props =>{
	const {
		classes,
		currentStats,
	} = props;
	if(currentStats?.female || currentStats?.male){
		return(
			<Grid item id='Ages'>
				<Box m={1}>
					{currentStats !== null &&
					<Grid container justifyContent="center" alignItems="center">
							<Grid item style={{paddingLeft:'0.5rem'}}>
								<Avatar className={classes.avatarGender} >
									<Grid container style={{textAlign:'center'}}>
										<Grid item xs={12}>
											<Typography
												className={classes.circularText}
												variant="body2"
											>
												female
											</Typography>
										</Grid>
										<Grid item xs={12}>
											<Typography
												className={classes.circularText}
												variant="body2"
											>
												<b>{currentStats.female}%</b>
											</Typography>
										</Grid>
									</Grid>
								</Avatar>
							</Grid>
							<Grid item style={{paddingLeft:'0.5rem'}}>
								<Avatar className={classes.avatarGender} >
									<Grid container style={{textAlign:'center'}}>
										<Grid item xs={12}>
											<Typography
											className={classes.circularText}
											variant="body2"
											>
												male
											</Typography>
											</Grid>
										<Grid item xs={12}>
											<Typography
											className={classes.circularText}
											variant="body2"
											>
											<b>{currentStats.male}%</b>
											</Typography>
									</Grid>
									</Grid>
								</Avatar>
							</Grid>
					</Grid>
					}
				</Box>
			</Grid>
		);
	}

	return null
}





const CreatorDemographics = props =>{
	// const isMobile = useMediaQuery(useTheme().breakpoints.down("sm"));
	return (
				<Grid container id='CreatorDemographics' style={{ alignItems:'center', paddingLeft:'0.5rem'}} spacing={3}>
					<TopCountryDemographics id='Countries' {...props}/>
					<TopCityDemographics {...props} id='Cities'/>
					<TopAgeDemographics {...props} />
					<TopGenderDemographics {...props} />
				</Grid>
		);
}

export default CreatorDemographics;
