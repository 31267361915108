import database from "../data/Firebase";

export function fetchManagers() {
	return dispatch => {
		database
			.collection("users")
			.where("role", "in", ["manager", "head-manager"])
			.get()
			.then(async function(querySnapshot) {
				let managers = [];
				querySnapshot.forEach(function(doc) {
					managers.push({
						fullName: doc.data().firstname + " " + doc.data().lastname,
						uid: doc.id
					});
				});
				dispatch(fetchManagersFulfilled(managers));
			})
			.catch(error => console.log(error));
	};
}

export function fetchManagersFulfilled(managers) {
	return {
		type: "FETCH_MANAGERS_FULFILLED",
		payload: managers
	};
}
