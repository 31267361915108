import React, { Component } from "react";
import { connect } from "react-redux";
import { DragDropContext, Droppable } from "react-beautiful-dnd";

import {
	Typography,
	Grid,
} from "@material-ui/core";
import { reorderProjects, createOrderedProjects } from "../../actions";
import ProjectListItem from "./ProjectListItem";

class ProjectList extends Component {
  constructor(props) {
    super(props);
    this.projectStatus = props.type === 'current' ? 'active' : 'closed';

	this.state = {
	  projects: props.projects.orderedProjects[this.projectStatus]
	};

    this.onDragEnd = this.onDragEnd.bind(this);

  }

  componentDidUpdate(prevProps){
	  	if(this.props.filterProjects.length !== prevProps.filterProjects.length){
	  		const projects_list = this.props.projects.projects_list;
	  		if (Object.keys(projects_list).length !== 0) {
	  			this.props.createOrderedProjects(this.props.allProjects, projects_list)
	  		}
	  	}

	  	if(this.props.projects.orderedProjects !== prevProps.projects.orderedProjects){
	  		this.setState((state, props) => ({
			   projects: this.props.projects.orderedProjects[this.projectStatus] 
			}));
	  	}
	}

  	reorder(list, startIndex, endIndex){
	  const result = Array.from(list);
	  const [removed] = result.splice(startIndex, 1);
	  result.splice(endIndex, 0, removed);

	  return result;
	};
	
	onDragEnd (result){
    // dropped outside the list
	    if (!result.destination) {
	      return;
	    }

	    const projects = this.reorder(
	      this.state.projects,
	      result.source.index,
	      result.destination.index
	    );

	    this.setState({
	      projects
	    });

	    this.props.reorderProjects(
	    	this.projectStatus,
	    	this.props.creatorId,
	    	result.source.index,
	    	result.destination.index,
	    	projects,
	    	 _ => {
	    	 	
	    	 }
	    )
  }	

  render() {
       return (
       	<DragDropContext onDragEnd={this.onDragEnd}>
	        <Droppable droppableId="droppable">
	        	{ provided =>(
	        		<Grid container
	        			{...provided.droppableProps}
              			ref={provided.innerRef}
	        		>
					{this.state.projects.length === 0 ? (
						<Grid item xs={12}>
							<Typography
								variant="body2"
								color="textSecondary"
								style={{
									textTransform: "uppercase",
									textAlign: "center",
									lineHeight: 1.3,
									marginTop: 20,
									fontWeight: "bold"
								}}
							>
								No {this.props.type === "current" ? "active" : "closed"} projects yet
							</Typography>
						</Grid>
					) : (
			          	this.state.projects.map((item, index) => (
				              		<ProjectListItem 
				              			key={index}
				              			index={index}
				              			project={item}
				              			{...this.props}
				              		/>
			           	))
		           		
		           			)}
					{provided.placeholder}
					</Grid>
	          	)}


	          
          	</Droppable>
	     </DragDropContext>
	    );
	}

}

function mapDispatchToProps(dispatch) {
	return {
		reorderProjects: (projectStatus, creatorId, from, to, callback) =>
			dispatch(reorderProjects(projectStatus, creatorId, from, to, callback)),
		createOrderedProjects:(projects ,projects_list) =>
			dispatch(createOrderedProjects(projects ,projects_list)),
	};
}

function mapStateToProps(state) {
	return {
		user: state.user,
		projects: state.projects
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectList);
