import { createTheme } from "@material-ui/core/styles";
import grey from "@material-ui/core/colors/grey";

const Theme = createTheme({
	palette: {
		primary: {
			main: "#000000",
			light: "#141414",
			dark: "#100c08"
		},
		secondary: grey,
		background: {
			default: "#141414"
		},
		error: {
			main: grey[50]
		},
		pink:{
			main: '#cd9988',
			light: '#e8ad9c',
			dark:'#987466'

		}
	},
	overrides: {
		PrivateRadioButtonIcon: {
			root: {
				'& *':{
					width: 14,
			    	height: 14,
	    		}
			}

		},
		PrivateSwitchBase: {
			root: {
				'& *':{
					width: 14,
			    	height: 14,
	    		}
			}

		},
		MuiFilledInput: {
			root: {
				color: grey[100],
				borderBottom: "0px",
				borderTopLeftRadius: "2px",
				borderTopRightRadius: "2px",
				borderRadius: "2px",
				boxShadow:
					"0px 3px 1px -2px rgba(205, 153, 136,0.2), 0px 2px 2px 0px rgba(205, 153, 136, 0.14), 0px 1px 5px 0px rgba(205, 153, 136,0.12)"
			},
			underline: {
				"&::before": {
					borderBottom: "0px"
				},
				"&:hover:before": {
					borderBottom: "0px"
				},
				"&::after": {
					borderBottomLeftRadius: "4px",
					borderBottomRightRadius: "4px",
					borderColor: grey[200]
				}
			}
		},
		MuiFormLabel: {
			root: {
				color: grey[100],
				"&$focused": {
					color: grey[200]
				}
			}
		},
		MuiAlert: {
			filledError: {
				backgroundColor: "#100c08"
			}
		},
		MuiIconButton: {
			colorSecondary: {
				color: '#cd9988'
			}
		},
		MuiButton: {
			root: {
				borderRadius: "2px",
			},
			containedPrimary: {
				backgroundColor: "#cd9988",
				"&:hover": {
					backgroundColor: '#e8ad9c'
				}
			}
		},
		// MuiButtonBase: {
		// 	root: {
		// 		backgroundColor: "#cd9988",
		// 		color: "white",
		// 	},
		// },
		MuiPaper: {}
	},
	props: {
		MuiTextField: { inputProps: { spellCheck: "false" } }
	}
});

export default Theme;
