import React from "react";
import { connect } from "react-redux";
import { Router } from "@reach/router";
import { Helmet, HelmetProvider } from "react-helmet-async";

import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import "typeface-roboto";
import Theme from "./Theme.js";
import { signOut } from "./actions";

import Initializing from "./components/Initializing";
import Login from "./components/Login";
import Skeleton from "./components/Skeleton";
import Profile from "./components/Profile/Profile";
import PrivacyPolicy from "./components/PrivacyPolicy";

const App = props => {
	return (
		<ThemeProvider theme={Theme}>
			<HelmetProvider>
				<Helmet defaultTitle="I+AM Manager" titleTemplate="%s - I+AM Manager" />
				<CssBaseline />
				{props.app.initialized === true ? (
					props.user !== null ? (
						<Router>
							<Profile path="/profile/:profile" />
							<PrivacyPolicy path="/privacy-policy" />
							<Skeleton default />
						</Router>
					) : (
						<Router>
							<Profile path="/profile/:profile" />
							<PrivacyPolicy path="/privacy-policy" />
							<Login default />
						</Router>
					)
				) : (
					<Initializing />
				)}
			</HelmetProvider>
		</ThemeProvider>
	);
};

function mapStateToProps(state) {
	return {
		user: state.user,
		app: state.app
	};
}

function mapDispatchToProps(dispatch) {
	return {
		signOut: _ => dispatch(signOut())
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
