import React, { useState } from "react";
import { connect } from "react-redux";
import numeral from "numeral";

import { updateProject } from "../../actions";
import NewProject from "../Project/NewProject";

import { makeStyles, useTheme } from "@material-ui/core/styles";

import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Typography,
	Grid,
	AppBar,
	Toolbar,
	Paper,
	CircularProgress,
	useMediaQuery
} from "@material-ui/core";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import { grey } from "@material-ui/core/colors";
import { Edit, ExpandMore } from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
	speedDialActionStyle: {
		backgroundColor: "#cd9988",
		color: "white",
		"&:hover": {
			backgroundColor: "#e8ad9c"
		}
	},
	button: {
		backgroundColor: "#cd9988",
		color: "white",
		"&:hover": {
			backgroundColor: "#e8ad9c"
		}
	},
	tabIndicator: {
		backgroundColor: "#cd9988"
	},
	tabLabel: {
		color: grey[700]
	},
	tabLabelSelected: {
		color: grey[900]
	},
	addButton: {
		backgroundColor: "#cd9988",
		position: "absolute",
		right: 34,
		top: 40,
		color: "white",
		height: 42,
		minWidth: 42,
		width: 42,
		"&:hover": {
			backgroundColor: "#e8ad9c"
		}
	},
	editButton: {
		backgroundColor: "#cd9988",
		color: "white",
		height: 42,
		minWidth: 42,
		width: 42,
		"&:hover": {
			backgroundColor: "#e8ad9c"
		}
	},
	speedDial: {
		position: "absolute",
		top: 12,
		right: 0
	},
	projectBar: {
		backgroundColor: "#000000",
		color: "white"
	},
	rightGrid: {
		textAlign: "right"
	},
	centerGrid: {
		textAlign: "center"
	},
	projectContainer: {
		padding: theme.spacing(3),
		minHeight: 135
	},
	deliItem: {
		paddingTop: 0,
		paddingBottom: 0
	},
	deliList: {
		backgroundColor: grey[200]
	},
	accordionSum:{

		    flexGrow: 0

	}
}));

const PaymentsList = props => {
	const classes = useStyles();
	const [menuOpen, setMenuOpen] = useState(-1);
	const [paymentLoading, setPaymentLoading] = useState(-1);
	const [editProject, setEditProject] = useState(null);
	const [addProject, setAddProject] = useState(false);
	const isMobile = useMediaQuery(useTheme().breakpoints.down("sm"));

	const { creator, user, filterPayments, isCollapsible } = props;
	let uniqueYears=[];
	if(isCollapsible){
		uniqueYears = [...new Set( filterPayments.map(obj => obj.paymentDate.format("YYYY"))) ];
	}

	const speedDialActions = [
		{
			label: "Completed",
			icon: <Edit />,
			action: (projectId, key) => {
				setPaymentLoading(key);
				props.updateProject(
					{
						...creator.projects.find(project => project.id === projectId),
						payment: "completed",
						paymentDate: new Date()
					},
					creator,
					_ => {
						setPaymentLoading(-1);
					}
				);
			}
		},
		{
			label: "Edit",
			icon: <Edit />,
			action: (projectId, key) => {
				setMenuOpen(-1);
				setEditProject(
					creator.projects.find(project => project.id === projectId)
				);
				setAddProject(true);
			}
		},
		{
			label: "Delete",
			icon: <Edit />,
			action: (projectId, key) => {
				setPaymentLoading(key);
				props.updateProject(
					{
						...creator.projects.find(project => project.id === projectId),
						payment: "deleted"
					},
					creator,
					_ => {
						setPaymentLoading(-1);
					}
				);
			}
		}
	];

	return(
		<Grid container>
			{filterPayments.length === 0 ? (
				<Grid item xs={12}>
					<Typography
						variant="body2"
						color="textSecondary"
						style={{
							textTransform: "uppercase",
							textAlign: "center",
							lineHeight: 1.3,
							marginTop: 20,
							fontWeight: "bold",
							marginBottom: 32
						}}
					>
						No {props.label}
					</Typography>
				</Grid>
			) : ( isCollapsible ? (
					uniqueYears.sort().reverse().map(year => {
						const payments = filterPayments.filter(payment=> payment.paymentDate.format("YYYY") === year );
						return (
							<Grid key={year} item xs={12}>
								<Accordion style={{ marginBottom: 46 }}>
							        <AccordionSummary
							          expandIcon={<ExpandMore />}
							          aria-controls="panel1a-content"
							          id="panel1a-header"
							          classes={{content:classes.accordionSum}}
							        >
							          <Typography variant="h6">{ year }</Typography>
							        </AccordionSummary>
							        <AccordionDetails>
							        	<Grid container>
								        	{payments.map((payment, key) => (
								        		<Grid key={key} item xs={12} style={{ marginBottom: 46 }}>
													<AppBar position="relative" className={classes.projectBar}>
														<Toolbar>
															<Grid container alignItems="center">
																<Grid item xs={3}></Grid>
																<Grid item xs={6} className={classes.centerGrid}>
																	<Typography
																		variant="h6"
																		style={{
																			textTransform: "uppercase"
																		}}
																	>
																		{payment.brand}
																	</Typography>
																</Grid>
																<Grid item xs={3} className={classes.rightGrid}>
																	{user.role !== "creator" && (
																		<SpeedDial
																			style={{ top: isMobile ? 6 : 12 }}
																			className={classes.speedDial}
																			FabProps={{
																				size: "medium",
																				className: classes.editButton
																			}}
																			ariaLabel="House Actions"
																			icon={
																				paymentLoading === key ? (
																					<CircularProgress
																						style={{ width: 20, height: 20 }}
																					/>
																				) : (
																					<SpeedDialIcon />
																				)
																			}
																			onClose={_ => setMenuOpen(-1)}
																			onOpen={_ => setMenuOpen(key)}
																			open={menuOpen === key}
																			direction="down"
																		>
																			{speedDialActions.map(
																				(action, keyAction) =>
																					action !== null && (
																						<SpeedDialAction
																							key={keyAction}
																							icon={
																								<Typography
																									variant="body1"
																									style={{ textTransform: "none" }}
																								>
																									{action.label}
																								</Typography>
																							}
																							FabProps={{
																								classes: {
																									root: classes.speedDialActionStyle
																								},
																								variant: "extended",
																								style: { height: 42, borderRadius: 3 }
																							}}
																							onClick={_ => {
																								setMenuOpen(false);
																								action.action(payment.id, key);
																							}}
																							tooltipTitle={action.label + "2"}
																						/>
																					)
																			)}
																		</SpeedDial>
																	)}
																</Grid>
															</Grid>
														</Toolbar>
													</AppBar>
													<Paper className={classes.projectContainer} elevation={4}>
														<Grid
															container
															direction="row"
															justifyContent="center"
															alignItems="center"
														>
															<Grid item lg={3} md={4} sm={6} xs={12}>
																<Typography>
																	<b>Budget:</b> ${numeral(payment.budget).format("0,0")}
																</Typography>
															</Grid>
															<Grid item lg={3} md={4} sm={6} xs={12}>
																<Typography>
																	<b>Payment Terms:</b> {payment.terms}
																</Typography>
															</Grid>
															<Grid item lg={3} md={4} sm={6} xs={12}>
																<Typography>
																	<b>Payment Due:</b> {payment.dueDate ? payment.dueDate.format("MMMM Do"): "No due date"}
																</Typography>
															</Grid>
														</Grid>
													</Paper>
												</Grid>
								        	))}
							        	</Grid>
							        </AccordionDetails>
							    </Accordion>
						    </Grid>
						)
					})
				):(
					filterPayments.map((payment, key) => (
						<Grid key={key} item xs={12} style={{ marginBottom: 46 }}>
							<AppBar position="relative" className={classes.projectBar}>
								<Toolbar>
									<Grid container alignItems="center">
										<Grid item xs={3}></Grid>
										<Grid item xs={6} className={classes.centerGrid}>
											<Typography
												variant="h6"
												style={{
													textTransform: "uppercase"
												}}
											>
												{payment.brand}
											</Typography>
										</Grid>
										<Grid item xs={3} className={classes.rightGrid}>
											{user.role !== "creator" && (
												<SpeedDial
													style={{ top: isMobile ? 6 : 12 }}
													className={classes.speedDial}
													FabProps={{
														size: "medium",
														className: classes.editButton
													}}
													ariaLabel="House Actions"
													icon={
														paymentLoading === key ? (
															<CircularProgress
																style={{ width: 20, height: 20 }}
															/>
														) : (
															<SpeedDialIcon />
														)
													}
													onClose={_ => setMenuOpen(-1)}
													onOpen={_ => setMenuOpen(key)}
													open={menuOpen === key}
													direction="down"
												>
													{speedDialActions.map(
														(action, keyAction) =>
															action !== null && (
																<SpeedDialAction
																	key={keyAction}
																	icon={
																		<Typography
																			variant="body1"
																			style={{ textTransform: "none" }}
																		>
																			{action.label}
																		</Typography>
																	}
																	FabProps={{
																		classes: {
																			root: classes.speedDialActionStyle
																		},
																		variant: "extended",
																		style: { height: 42, borderRadius: 3 }
																	}}
																	onClick={_ => {
																		setMenuOpen(false);
																		action.action(payment.id, key);
																	}}
																	tooltipTitle={action.label + "2"}
																/>
															)
													)}
												</SpeedDial>
											)}
										</Grid>
									</Grid>
								</Toolbar>
							</AppBar>
							<Paper className={classes.projectContainer} elevation={4}>
								<Grid
									container
									direction="row"
									justifyContent="center"
									alignItems="center"
								>
									<Grid item lg={3} md={4} sm={6} xs={12}>
										<Typography>
											<b>Budget:</b> ${numeral(payment.budget).format("0,0")}
										</Typography>
									</Grid>
									<Grid item lg={3} md={4} sm={6} xs={12}>
										<Typography>
											<b>Payment Terms:</b> {payment.terms}
										</Typography>
									</Grid>
									<Grid item lg={3} md={4} sm={6} xs={12}>
										<Typography>
											<b>Payment Due:</b> {payment.dueDate ? payment.dueDate.format("MMMM Do"): "No due date"}
										</Typography>
									</Grid>
								</Grid>
							</Paper>
						</Grid>
					))
				)

			)}
		<NewProject
			setOpen={setAddProject}
			open={addProject}
			creator={creator}
			editProject={editProject}
			setEditProject={setEditProject}
		/>
		</Grid>
	);
};

function mapDispatchToProps(dispatch) {
	return {
		updateProject: (project, creator, callback) =>
			dispatch(updateProject(project, creator, callback))
	};
}

function mapStateToProps(state) {
	return {
		user: state.user
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentsList);
