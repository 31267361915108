export default (state = [], action) => {
	switch (action.type) {
		case "APP_INIT":
			return { ...state, initialized: action.payload };
		case "SIGN_IN_REQUESTED":
			return { ...state, loginLoading: true };
		case "SIGN_IN_FINISHED":
			return { ...state, loginLoading: false };
		case "SIGN_IN_ERROR":
			return { ...state, loginError: action.payload };
		case "USER_FETCH_SUCCESS":
			return { ...state, userFetch: true };
		case "SIGN_OUT_SUCCESS":
			return { ...state, userFetch: false };
		case "LOGIN_ALERT_CLOSE":
			return { ...state, loginError: null };
		case "APP_ONLINE":
			return { ...state, connectionStatus: true };
		case "APP_OFFLINE":
			return { ...state, connectionStatus: false };
		case "RESET_PASSWORD_REQUESTED":
			return { ...state, resetingPassword: true };
		case "RESET_PASSWORD_SUCCESS":
			return {
				...state,
				resetingPassword: false,
				resetPasswordEmailStatus: "success"
			};
		case "RESET_PASSWORD_FAIL":
			return {
				...state,
				resetingPassword: false,
				resetPasswordEmailStatus: "error"
			};
		case "RESET_PASSWORD_ALERT_CLOSE":
			return { ...state, resetPasswordEmailStatus: "none" };
		case "CREATORS_LOADING":
			return { ...state, creatorsLoading: action.payload };
		case "ADD_CREATOR_STATUS":
			return { ...state, addingCreator: action.payload };
		default:
			return state;
	}
};
