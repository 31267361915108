import {Grid, TextField} from "@material-ui/core";
import React from "react";

const LinkInput= props =>{
	const { handleChangeObject, currentStats } = props
	return(
		<Grid item xs={12}>
			<TextField
				fullWidth
				autoCapitalize="false"
				name="link"
				value={currentStats.link}
				onChange={handleChangeObject}
				label="Link"
				margin="dense"
				variant="filled"
				color="primary"
			/>
		</Grid>
	);
}

export default LinkInput;
