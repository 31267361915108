import React from "react";

import {
	Button,
	Grid,
	TextField,
	InputAdornment,
	Paper,
	Typography
} from "@material-ui/core";

const TopCitiesListItem = props => {
	const {
		topCity,
		currentStats,
		handleChangeArrayObject,
		index
	} = props;
	return (
		<Grid container alignItems="center" justifyContent="center" spacing={1}>
			<Grid item xs={6}>
				<TextField
					fullWidth
					autoCapitalize="false"
					name="topCity"
					value={topCity.city}
					onChange={handleChangeArrayObject(topCity.id ?? index, "city",currentStats, topCity)}
					label={"Top City #" + (topCity.id? topCity.id + 1 : index +1)}
					margin="dense"
					variant="filled"
					color="primary"
				/>
			</Grid>
			<Grid item xs={6}>
				<TextField
					fullWidth
					autoCapitalize="false"
					label="Percentage"
					name="topCity"
					margin="dense"
					variant="filled"
					color="primary"
					value={topCity.percentage}
					onChange={handleChangeArrayObject(topCity.id ?? index , "percentage",currentStats, topCity)}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">%</InputAdornment>
						)
					}}
				/>
			</Grid>
		</Grid>
	)
}

const TopCitiesInput = props => {
  	    const {
			classes,
			currentStats,
			currentStatsName,
			addField
		} = props;



   return (
   		<Grid item xs={12}>
			<Paper className={classes.paper} elevation={0}>
				<Typography className={classes.paperTitle}>Top City</Typography>
				<Grid container alignItems="center" justifyContent="center" spacing={1}>
					{currentStats.topCity.map((topCity, index) => (
						<TopCitiesListItem {...props} topCity={topCity} key={index} index={index}/>
					))}
					<Grid item xs={12}>
						<Button
							variant="contained"
							onClick={_ =>
								addField({ id: (currentStats.topCity.length), city: "", percentage: "" }, "topCity", currentStatsName)
							}
							fullWidth
						>
							More Top City
						</Button>
					</Grid>
				</Grid>
			</Paper>
		</Grid>
   	);
};

export default TopCitiesInput;
