import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/database";
import "firebase/functions";
import "firebase/performance";
import "firebase/analytics";
import "firebase/storage";

const firebaseConfig = {
	apiKey: "AIzaSyDYUZpFjKTwEXBizcUUmSVI9sdS9zIjfRo",
	authDomain: "house.dulcedo.com",
	databaseURL: "https://influence-all-house-default-rtdb.firebaseio.com",
	projectId: "influence-all-house",
	storageBucket: "influence-all-house.appspot.com",
	messagingSenderId: "474189333558",
	appId: "1:474189333558:web:31844b7a439e74643c0497",
	measurementId: "G-G34TRLQXJD"
};

firebase.initializeApp(firebaseConfig);

export default firebase.firestore();
export const analytics = firebase.analytics();
export const auth = firebase.auth();
export const connectedRef = firebase.database().ref(".info/connected");
export const firebaseRef = firebase;
export const fbFunctions = firebase.functions();
export const fbPerf = firebase.performance();
export const storageRef = firebase.storage().ref();
