import { auth, analytics, fbFunctions } from "../data/Firebase";
import database from "../data/Firebase";
import store from "../Store";
import {
	appInitializationSuccess,
	appInitializationLoading
} from "./AppActions";

export function signIn(email, password) {
	return dispatch => {
		dispatch(signInRequested());
		auth
			.signInWithEmailAndPassword(email, password)
			.then(data => {
				//console.log(data);
			})
			.catch(error => {
				dispatch(signInFinished());
				dispatch(signInError(error));
			});
	};
}

export function fetchUser(uid) {
	return dispatch => {
		database
			.collection("users")
			.doc(uid)
			.get()
			.then(doc => {
				dispatch(signInFinished());
				dispatch(fetchUserSuccess(doc.data()));
				dispatch(appInitializationSuccess());
			});
	};
}

export function deleteUserCreator(uid, callback) {
	return dispatch => {
		database
		.collection('users')
		.get()
		.then(docs => {
			docs.forEach((doc) => {
				if (doc.data().creator === uid) {
					const deleteUser = fbFunctions.httpsCallable("deleteUserAuth");
					deleteUser(doc.id).then(result => {
						callback();
					});
				}
			})
		})
	};
}

export function addUserWithCallback(user, callback) {
	user = { ...user };
	return dispatch => {
		const addUser = fbFunctions.httpsCallable("addUser");
		addUser(user).then(result => {
			callback();
		});
	};
}

export function updateUser(user) {
	return dispatch => dispatch(updateUserWithCallback(user, _ => null));
}

export function updateUserWithCallback(user, callback) {
	user = { ...user };
	const uid = user.uid;
	delete user.uid;
	delete user.email;
	return dispatch => {
		database
			.collection("users")
			.doc(uid)
			.set(user)
			.then(doc => {
				dispatch(fetchUser(uid));
				callback();
			});
	};
}

export function updateManagerWithCallback(user, callback) {
	user = { ...user };
	const uid = user.uid;
	delete user.uid;
	delete user.email;
	return dispatch => {
		database
			.collection("users")
			.doc(uid)
			.set(user)
			.then(doc => {
				callback();
			});
	};
}

export function updateUserEmailWithCallback(user, callback) {
	return dispatch => {
		const updateUserEmail = fbFunctions.httpsCallable("updateUserEmail");
		updateUserEmail(user).then(result => {
			callback();
		});
	};
}

export function deleteUserWithCallback(user, callback) {
	return dispatch => {
		const deleteUser = fbFunctions.httpsCallable("deleteUser");
		deleteUser(user.uid).then(result => {
			callback();
		});
	};
}

export function fetchUsersWithCallback(callback) {
	return dispatch => {
		database
			.collection("users")
			.where("role", "in", ["manager", "head-manager"])
			.get()
			.then(querySnapshot => {
				const users = [];
				querySnapshot.forEach(function(doc) {
					users.push({ ...doc.data(), uid: doc.id });
				});
				const getUsersEmail = fbFunctions.httpsCallable("getUsersEmail");
				getUsersEmail(users).then(result => {
					callback(JSON.parse(result.data));
				});
			});
	};
}

function fetchUserSuccess(userData) {
	return {
		type: "USER_FETCH_SUCCESS",
		payload: { ...userData }
	};
}

function signInFinished() {
	return {
		type: "SIGN_IN_FINISHED"
	};
}

function signInRequested() {
	return {
		type: "SIGN_IN_REQUESTED"
	};
}

function signInSuccess(user) {
	analytics.logEvent("login");
	return {
		type: "SIGN_IN_SUCCESS",
		payload: user
	};
}

function signInError(error) {
	return {
		type: "SIGN_IN_ERROR",
		payload: error
	};
}

export function signOut() {
	return dispatch => {
		auth.signOut().catch(error => {});
	};
}

function signOutSuccess(user) {
	return {
		type: "SIGN_OUT_SUCCESS"
	};
}

export function sendPasswordResetEmail(email) {
	return dispatch => {
		dispatch(resetPasswordRequested());
		auth
			.sendPasswordResetEmail(email)
			.then(function() {
				dispatch(resetPasswordSuccess());
			})
			.catch(function(error) {
				dispatch(resetPasswordFail());
			});
	};
}

function resetPasswordRequested() {
	return {
		type: "RESET_PASSWORD_REQUESTED"
	};
}

function resetPasswordSuccess() {
	return {
		type: "RESET_PASSWORD_SUCCESS"
	};
}

function resetPasswordFail() {
	return {
		type: "RESET_PASSWORD_FAIL"
	};
}

auth.onAuthStateChanged(function(user) {
	if (user) {
		store.dispatch(appInitializationLoading());
		store.dispatch(signInSuccess({ email: user.email, uid: user.uid }));
		store.dispatch(fetchUser(user.uid));
	} else {
		store.dispatch(signOutSuccess());
	}
});
