import React, { useState, useEffect } from "react";
import numeral from "numeral";

import { Typography } from "@material-ui/core";
import { getCurrenciesRates, computeTotalCurrency, getCurrencySign } from "../../helper";

const PaymentsEarnings = props => {
	const [currencyRates, setCurrencyRates] = useState(null);
	const [total, setTotal] = useState(0);
	const { creator, filterPayments } = props;
	const baseCurrency = creator.currency;

	useEffect(_ =>{
		if(!currencyRates){
			getCurrenciesRates(baseCurrency)
            .then(response => {
                setCurrencyRates(response.data.exchange_rates);
                setTotal(computeTotalCurrency(filterPayments, baseCurrency,response.data.exchange_rates))
            })
            .catch(err => {
                console.log("Cannot get currency rates", err.message);
            });
		}
	}, [currencyRates, baseCurrency, total, filterPayments])

	return (
			<div>
				<Typography
					variant="h6"
					style={{
						textTransform: "uppercase",
						textAlign: "center",
						lineHeight: 1.3,
						marginTop: 20,
						fontWeight: "bold"
					}}
				>
					{props.label}
				</Typography>
				<Typography
					variant="subtitle1"
					style={{
						marginTop: 5,
						textAlign: "center"
					}}
				>
					Total {creator.currency} {getCurrencySign(creator.currency)}<b>{numeral(total).format("0,0")}</b>
				</Typography>
				<Typography
					variant="subtitle1"
					style={{
						textAlign: "center",
						marginBottom: 60,
						marginTop: -5
					}}
					gutterBottom
				>
					Creator's earnings {creator.currency} {getCurrencySign(creator.currency)}
					<b>
						{numeral(
							total * (1 - Number.parseInt(creator.commission) / 100)
						).format("0,0")}
					</b>
				</Typography>
			</div>
	);
};


export default PaymentsEarnings;
