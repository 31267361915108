import React, { useEffect, useState } from "react";
import { navigate } from "@reach/router";
import { connect } from "react-redux";

import { fetchCreatorWithCallback, shareCreator } from "../../actions";


import {
	useMediaQuery,
	CssBaseline,
	Grid,
	Container,
	Toolbar,
	CircularProgress,
	Dialog,
	DialogTitle,
	DialogContent,
	TextField,
	DialogActions,
	Button,
	Snackbar,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import {
	makeStyles,
	useTheme,
	ThemeProvider,
	createTheme
} from "@material-ui/core/styles";
import Theme from "../../Theme";
import { Share, Edit } from "@material-ui/icons";
import { grey } from "@material-ui/core/colors";

import Appbar from "./../Appbar";
import SocialMediaAnalytics from "./SocialMediaAnalytics";
import CreatorBio from "./CreatorBio";
import Rates from "./Rates";
import SocialMediaLinks from "./SocialMediaLinks";

const defaultTheme = createTheme();

const newTheme = createTheme({
	...Theme,
	palette: {
		...Theme.palette,
		...defaultTheme.palette,
		primary: grey
	},
	overrides: {
		...Theme.overrides,
		MuiPaper: { rounded: { borderRadius: "2px" } },
		MuiButton: {
			contained: {
				boxShadow: "none",
				borderTopLeftRadius: "2px",
				borderTopRightRadius: "2px",
				borderRadius: "2px",
				backgroundColor: grey[200],
				"&:hover": {
					boxShadow: "none"
				}
			}
		},
		MuiFormLabel: {
			root: {
				color: grey[700],
				"&$focused": {
					color: grey[600]
				}
			}
		},
		MuiFilledInput: {
			root: {
				backgroundColor: grey[100],
				borderBottom: "0px",
				borderTopLeftRadius: "2px",
				borderTopRightRadius: "2px",
				borderRadius: "2px",
				boxShadow:
					"0px 3px 1px -2px rgba(0,0,0,0.1), 0px 2px 2px 0px rgba(0,0,0,0.1), 0px 1px 5px 0px rgba(0,0,0,0.1)"
			},
			underline: {
				"&::before": {
					borderBottom: "0px"
				},
				"&:hover:before": {
					borderBottom: "0px"
				},
				"&::after": {
					borderBottomLeftRadius: "4px",
					borderBottomRightRadius: "4px"
				}
			}
		},
		MuiTooltip: {
			popper: {
				display: "none"
			}
		},
		MuiAlert: {
			filledSuccess: {
				backgroundColor: "#cd9988"
			}
		}
	}
});

const useStyles = makeStyles(theme => ({
	box:{
		marginTop: theme.spacing(2),
		padding: theme.spacing(1),
		width:'100%',
		height:'100%',
		color:'white',
		textAlign:'center'
	},
	cardProfile: {
		marginTop: -240
	},
	cardBanner: {},
	cardBannerContainer: { padding: 30 },
	container: {
		marginTop: theme.spacing(2),
		// flexWrap: 'wrap'
	},
	cardBannerImage: {
		backgroundPosition: "50% 35%",
		height: 350,
		"&::before": {
			display: "block",
			backgroundImage: "linear-gradient(to bottom, transparent 0%, black 140%)",
			height: "100%",
			width: "100%",
			content: "' '"
		}
	},
	chip: {
		marginRight: 5
	},
	top: { color: "#dadada" },
	bottom: {
		color: grey[900],
		position: "absolute",
		left: 0
	},
	circularRoot: {
		position: "relative"
	},
	circularText: {
		position: "absolute",
		left: "50%",
		top: "50%",
		transform: "translate(-50%, -50%)",
		textAlign: "center"
	},
	imageLeft: {
		maxHeight: 600,
	},
	imageLeftMobile: {
		width: "100%",
		maxHeight: 350
	},
	imageRight: {
		width: "calc(100% + 50px)",

		height: 400,
		marginLeft: -50
	},
	rightItem: {
		display:'flex',
		alignItems:'center',
		justifyContent:'center',
		width:'100%',
		height:'100%',
	},
	buttonProgressReset: {
		position: "absolute",
		right: 35,
		bottom: 13
	},
}));

const Profile = props => {
	const classes = useStyles();
	const [creator, setCreator] = useState(null);
	const [menuOpen, setMenuOpen] = useState(false);
	const [openDialog, setOpenDialog] = useState(false);
	const [shareEmail, setShareEmail] = useState("");
	const [loading, setLoading] = useState(false);
	const [sendingEmail, setSendingEmail] = useState(false);
	const [sendingEmailStatus, setSendingEmailStatus] = useState(false);
	const isMobile = useMediaQuery(useTheme().breakpoints.down("xs"));

	const { fetchCreator, profile, creators } = props;

	useEffect(
		_ => {
			if (creator === null) {
				setLoading(true);
			}
			if (creator === null && !loading) {
				fetchCreator(profile, creatorRes => {
					const creatorFound = { ...creatorRes };
					if (Object.keys(creatorFound).length !== 0) {
						setCreator({ ...creatorFound });
						setLoading(false);
					}
				});
			}
		},
		[fetchCreator, profile, creators, creator, loading]
	);

	const shareProfile = _ => {
		setSendingEmail(true);
		props.shareCreator(creator, shareEmail, _ => {
			setSendingEmail(false);
			setSendingEmailStatus(true);
		});
	};

	const speedDialActions = [
		{
			label: "Share",
			icon: <Share />,
			action: _ => setOpenDialog(true)
		},
		creator === null
			? null
			: (props.user !== null && props.user.role === "head-manager") ||
			  (props.user !== null &&
					props.user.role === "manager" &&
					creator.managers.some(val => val.uid === props.user.uid))
			? {
					label: "Manage",
					icon: <Edit />,
					action: _ => {
						navigate("/house/" + creator.id);
					}
			  }
			: null
	];


	return (
		<ThemeProvider theme={newTheme}>
			<CssBaseline />
			<Appbar />
			<Toolbar />
			<Container id="printingStart" style={{ marginTop: 48, marginBottom: 48 }}>
					<Grid
						container
						className={classes.container}
						justifyContent="flex-end"
						spacing={3}
						direction='row'
					>

						<CreatorBio
							creator={creator}
							menuOpen={menuOpen}
							setMenuOpen={setMenuOpen}
							speedDialActions={speedDialActions}
							isMobile={isMobile}
						/>
						<Grid container justifyContent="center" id='SocialMediaLinksAndRates' >
							<SocialMediaLinks
								creator={creator}
							/>
							<Rates
								creator={creator}
							/>
						</Grid>
					<SocialMediaAnalytics
							classes={classes}
							creator={creator}
							isMobile={isMobile}
						/>
					</Grid>
			</Container>
			<Dialog
				open={openDialog}
				onClose={_ => setOpenDialog(false)}
				aria-labelledby="form-dialog-title"
				fullWidth
			>
				<DialogTitle id="form-dialog-title">Share Profile</DialogTitle>
				<DialogContent>
					<TextField
						margin="dense"
						id="name"
						label="Email"
						type="email"
						variant="filled"
						value={shareEmail}
						onChange={e => setShareEmail(e.target.value)}
						fullWidth
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={_ => setOpenDialog(false)}>Cancel</Button>
					<div>
						<Button
							onClick={shareProfile}
							color="primary"
							variant="contained"
							disabled={sendingEmail}
							className={classes.speedDialActionStyle}
						>
							Share
						</Button>
						{sendingEmail && (
							<CircularProgress
								size={24}
								className={classes.buttonProgressReset}
							/>
						)}
					</div>
				</DialogActions>
				<Snackbar
					anchorOrigin={{
						vertical: "top",
						horizontal: "center"
					}}
					open={sendingEmailStatus}
					onClose={_ => setSendingEmailStatus(false)}
					autoHideDuration={6000}
				>
					<MuiAlert
						elevation={6}
						variant="filled"
						onClose={_ => props.passwordResetAlertClose()}
						severity="success"
					>
						Profile successfully shared with {shareEmail} !
					</MuiAlert>
				</Snackbar>
			</Dialog>
		</ThemeProvider>
	);
};

function mapDispatchToProps(dispatch, ownProps) {
	return {
		fetchCreator: (creator, callback) =>
			dispatch(fetchCreatorWithCallback(creator, callback)),
		shareCreator: (creator, email, callBack) =>
			dispatch(shareCreator(creator, email, "profile", callBack))
	};
}

function mapStateToProps(state) {
	return {
		creators: state.creators,
		user: state.user
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
