import React, { useState } from "react";
import { connect } from "react-redux";
import numeral from "numeral";
import { cloneDeep } from "lodash";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
	Dialog,
	DialogTitle as MuiDialogTitle,
	IconButton,
	DialogContent,
	DialogActions,
	Grid,
	Paper,
	List,
	ListItemText,
	ListItem,
	CircularProgress
} from "@material-ui/core";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import { Close as CloseIcon } from "@material-ui/icons";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { getCurrencySign } from "../../helper";

const useStyles = makeStyles(theme => ({
	root: {
		width: "100%"
	},
	backButton: {
		marginRight: theme.spacing(1)
	},
	mobileStepper: {
		paddingRight: 0,
		paddingLeft: 0
	},
	instructions: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1)
	},
	closeButton: {
		position: "absolute",
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[900]
	},
	formContainer: {
		maxWidth: 500,
		margin: "auto"
	},
	paper: {
		justifyContentContent: "center",
		position: "relative",
		flexWrap: "wrap",
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(3),
		padding: theme.spacing(3),
		paddingTop: 32,
		paddingBottom: 10,
		backgroundColor: "transparent",
		border: "1px solid rgba(0, 0, 0, 0.23)"
	},
	paperTitle: {
		marginTop: -47,
		maxWidth: "fit-content",
		background: "white",
		paddingRight: 5,
		paddingLeft: 5
	},
	button: {
		backgroundColor: "#cd9988",
		color: "white",
		"&:hover": {
			backgroundColor: "#e8ad9c"
		}
	},
	chip: {
		margin: theme.spacing(1)
	},
	speedDialActionStyle: {
		backgroundColor: "#cd9988",
		color: "white",
		"&:hover": {
			backgroundColor: "#e8ad9c"
		}
	},
	editButton: {
		backgroundColor: "#cd9988",
		color: "white",
		width: 32,
		height: 32,
		maxHeight: 32,
		minHeight: 32,
		"&:hover": {
			backgroundColor: "#e8ad9c"
		}
	},
	speedDial: {
		position: "absolute",
		top: -3,
		left: 0
	}
}));

const ViewProject = props => {
	const classes = useStyles();
	const isMobile = useMediaQuery(useTheme().breakpoints.down("sm"));
	const [menuOpen, setMenuOpen] = useState(false);
	const { project, speedDialActions, projectLoading } = props;

	return (
		<Dialog
			onClose={_ => props.setOpen(false)}
			aria-labelledby="customized-dialog-title"
			open={props.open}
			fullWidth
			maxWidth="lg"
			fullScreen={isMobile}
			keepMounted={false}
		>
			{project !== null && (
				<>
					<MuiDialogTitle disableTypography className={classes.root}>
						<Typography
							variant="h6"
							style={{
								textTransform: "uppercase",
								display: "inline"
							}}
						>
							{project.brand}
						</Typography>
						{typeof speedDialActions !== "undefined" && (
							<div
								style={{
									position: "relative",
									display: "inline",
									verticalAlign: "super"
								}}
							>
								<SpeedDial
									className={classes.speedDial}
									FabProps={{
										size: "medium",
										className: classes.editButton
									}}
									ariaLabel="House Actions"
									icon={
										projectLoading === -2 ? (
											<CircularProgress style={{ width: 20, height: 20 }} />
										) : (
											<SpeedDialIcon />
										)
									}
									onClose={_ => setMenuOpen(-1)}
									onOpen={_ => setMenuOpen(-2)}
									open={menuOpen === -2}
									direction="down"
								>
									{speedDialActions.map(
										(action, keyAction) =>
											action !== null && (
												<SpeedDialAction
													key={keyAction}
													icon={
														<Typography
															variant="body1"
															style={{ textTransform: "none" }}
														>
															{action.label}
														</Typography>
													}
													FabProps={{
														classes: {
															root: classes.speedDialActionStyle
														},
														variant: "extended",
														style: { height: 42, borderRadius: 3 }
													}}
													onClick={_ => {
														setMenuOpen(false);
														action.action(project.id, -2);
													}}
													tooltipTitle={action.label + "2"}
												/>
											)
									)}
								</SpeedDial>
							</div>
						)}
						<IconButton
							aria-label="close"
							className={classes.closeButton}
							onClick={_ => props.setOpen(false)}
						>
							<CloseIcon />
						</IconButton>
					</MuiDialogTitle>
					<DialogContent dividers style={{ padding: "5%" }}>
						<Grid container spacing={4}>
							<Grid item md={6} xs={12}>
								<Grid container spacing={1}>
									<Grid item xs={12}>
										<Typography variant="subtitle1">
											<b>Budget:</b> {project.currency} {getCurrencySign(project.currency)}{numeral(project.budget).format("0,0")}
										</Typography>
									</Grid>

									<Grid item xs={12}>
										<Typography variant="subtitle1">
											<b>Payment terms:</b>{" "}
											{project.terms === ""
												? "No payment terms"
												: project.terms}
										</Typography>
									</Grid>
									<Grid item xs={12}>
										<Typography variant="subtitle1">
											<b>Payment due date:</b>{" "}
											{project.dueDate ? project.dueDate.format("MMMM Do YYYY"): "No payment date"}
										</Typography>
									</Grid>
									{project.invoiceType && (
										<Grid item xs={12}>
											<Typography variant="subtitle1">
												<b>Payment Method:</b>{" "}
												{project.paymentMethod}
											</Typography>
										</Grid>
									)}
									{project.invoiceType && (
										<Grid item xs={12}>
											<Typography variant="subtitle1">
												<b>Invoice Type:</b>{" "}
												{project.invoiceType ==="invoiced" ? "Invoiced": "Invoiced upon completion"}
											</Typography>
										</Grid>
									)}
									{project.fees && (
										<Grid item xs={12}>
											<Typography variant="subtitle1">
												<b>Fees:</b>{" "}
												{project.fees}
											</Typography>
										</Grid>
									)}
									<Grid item xs={12}>
										<Typography variant="subtitle1">
											<b>Bio or Swipe up link:</b>{" "}
											{project.link === "" ? "No link" : project.link}
										</Typography>
									</Grid>
									<Grid item xs={12}>
										<Typography variant="subtitle1">
											<b>Hashtags:</b>{" "}
											{project.hashtags === ""
												? "No hashtags"
												: project.hashtags}
										</Typography>
									</Grid>
									<Grid item xs={12}>
										<Typography variant="subtitle1">
											<b>Handles:</b>{" "}
											{project.handles === "" ? "No handles" : project.handles}
										</Typography>
									</Grid>
									<Grid item xs={12}>
										<Typography variant="subtitle1">
											<b>Exclusivity:</b>{" "}
											{project.exclusivity ? project.exclusivity : "None"}
										</Typography>
									</Grid>
									{project.usage && (
										<Grid item xs={12}>
											<Typography variant="subtitle1">
												<b>Usage:</b>{" "}
												{project.usage}
											</Typography>
										</Grid>
									)}
									<Grid item xs={12}>
										<Paper className={classes.paper} elevation={0}>
											<Typography
												variant="subtitle1"
												className={classes.paperTitle}
												style={{ fontWeight: "bold" }}
											>
												Notes
											</Typography>
											<Typography variant="subtitle1">
												{project.notes === "" ? "No notes" : project.notes}
											</Typography>
										</Paper>
									</Grid>
								</Grid>
							</Grid>
							<Grid item md={6} xs={12}>
								<Grid container>
									<Grid item xs={12}>
										<Paper
											className={classes.paper}
											style={{ marginTop: 0 }}
											elevation={0}
										>
											<Typography
												variant="subtitle1"
												className={classes.paperTitle}
												style={{ fontWeight: "bold" }}
											>
												Guidelines
											</Typography>
											<Typography
												variant="subtitle1"
												style={{whiteSpace: "pre-line"}}
											>
												{project.guidelines === ""
													? "No guidelines"
													: project.guidelines}
											</Typography>
										</Paper>
									</Grid>
									<Grid item xs={12}>
										{project.deliverables.map((value, key) => (
											<Paper key={key} className={classes.paper} elevation={0}>
												<Typography
													variant="subtitle1"
													className={classes.paperTitle}
													style={{ fontWeight: "bold" }}
												>
													{value.name}
												</Typography>
												<Grid
													container
													alignItems="center"
													justifyContent="center"
													spacing={1}
												>
													{value.approval.date && (
														<Grid item xs={6}>
															<Typography variant="body1">
																<b>Approval:</b>{" "}
																{value.approval.date.format("MMMM Do YYYY")}
															</Typography>
														</Grid>
													)}
													{value.approval.startDate && (
														<Grid item xs={4}>
															<Typography variant="body1">
																<b>Approval Start:</b>{" "}
																{value.approval.startDate.format("MMMM Do YYYY")}
															</Typography>
														</Grid>
													)}
													{value.approval.endDate && (
														<Grid item xs={4}>
															<Typography variant="body1">
																<b>Approval End:</b>{" "}
																{value.approval.endDate.format("MMMM Do YYYY")}
															</Typography>
														</Grid>
													)}
													<Grid item xs={4}>
														<Typography variant="body1">
															<b>Reminder:</b>{" "}
															{cloneDeep(value.approval.date ? value.approval.date :value.approval.startDate )
																.subtract(value.approval.reminder, "days")
																.format("MMMM Do YYYY")}
														</Typography>
													</Grid>
													{value.post.date && (
														<Grid item xs={6}>
															<Typography variant="body1">
																<b>Post:</b>{" "}
																{value.post.date.format("MMMM Do YYYY")}
															</Typography>
														</Grid>
													)}
													{value.post.startDate && (
														<Grid item xs={4}>
															<Typography variant="body1">
																<b>Post Start:</b>{" "}
																{value.post.startDate.format("MMMM Do YYYY")}
															</Typography>
														</Grid>
													)}
													{value.post.endDate && (
														<Grid item xs={4}>
															<Typography variant="body1">
																<b>Post End:</b>{" "}
																{value.post.endDate.format("MMMM Do YYYY")}
															</Typography>
														</Grid>
													)}
													<Grid item xs={4}>
														<Typography variant="body1">
															<b>Reminder:</b>{" "}
															{cloneDeep(value.post.date ? value.post.date :value.post.startDate)
																.subtract(value.post.reminder, "days")
																.format("MMMM Do YYYY")}
														</Typography>
													</Grid>
												</Grid>
											</Paper>
										))}
									</Grid>
									<Grid item xs={12}>
										<Paper className={classes.paper} elevation={0}>
											<Typography
												className={classes.paperTitle}
												style={{ fontWeight: "bold" }}
											>
												Attachments
											</Typography>
											<Grid
												container
												alignItems="center"
												justifyContent="center"
												spacing={1}
											>
												<List dense className={classes.deliList}>
													{project.attachments.length === 0 ? (
														<ListItem className={classes.deliItem}>
															<ListItemText primary={"No attachments"} />
														</ListItem>
													) : (
														project.attachments.map((value, key) => (
															<ListItem key={key} className={classes.deliItem}>
																<ListItemText
																	primary={
																		<a
																			href={value}
																			rel="noopener noreferrer"
																			target="_blank"
																			style={{
																				color: "black",
																				fontWeight: "bold"
																			}}
																		>
																			{decodeURIComponent(
																				value
																					.split("%2F")[1]
																					.split("?alt=media")[0]
																			)}
																		</a>
																	}
																/>
															</ListItem>
														))
													)}
												</List>
											</Grid>
										</Paper>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</DialogContent>
					<DialogActions>
						{typeof speedDialActions !== "undefined" ? (
							<Button
								variant="contained"
								className={classes.button}
								onClick={_ => props.setOpen(false)}
							>
								Close
							</Button>
						) : (
							<Button
								variant="contained"
								className={classes.button}
								onClick={_ => props.openEdit(project.id)}
							>
								Edit
							</Button>
						)}
					</DialogActions>
				</>
			)}
		</Dialog>
	);
};

function mapStateToProps(state) {
	return {
		user: state.user
	};
}

export default connect(mapStateToProps)(ViewProject);
