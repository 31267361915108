import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet-async";
import { cloneDeep } from "lodash";
import isemail from "isemail";

import { updateUserWithCallback, fetchUsersWithCallback } from "../actions";
import { auth } from "../data/Firebase";

import NewManager from "./NewManager";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Visibility, VisibilityOff, Add } from "@material-ui/icons";

import {
	Grid,
	useMediaQuery,
	Typography,
	Paper,
	TextField,
	Button,
	Divider,
	InputAdornment,
	IconButton,
	CircularProgress,
	Snackbar,
	Fab,
	Fade,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	TableContainer
} from "@material-ui/core";
import { Alert as MuiAlert } from "@material-ui/lab";

const useStyles = makeStyles(theme => ({
	addButton: {
		backgroundColor: "#cd9988",
		color: "white",
		height: 42,
		minWidth: 42,
		width: 42,
		"&:hover": {
			backgroundColor: '#e8ad9c'
		}
	},
	container: {
		marginTop: theme.spacing(4)
	},
	button: {
		backgroundColor: "#cd9988",
		color: "white",
		"&:hover": {
			backgroundColor: "#e8ad9c"
		}
	},
	buttonProgress: {
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12
	},
	progressDiv: {
		position: "relative",
		width: "fit-content",
		display: "inline-block"
	},
	tableHead: {
		cursor: "default",
		"&:hover": {
			background: "none"
		}
	}
}));

const Settings = props => {
	const classes = useStyles();
	const { user, fetchUsers } = props;

	const [userEdit, setUserEdit] = useState(cloneDeep(user));

	const [password, setPassword] = useState("");
	const [passwordRepeat, setPasswordRepeat] = useState("");
	const [oldPassword, setOldPassword] = useState("");
	const [currentPassword, setCurrentPassword] = useState("");
	const [passwordLoading, setPasswordLoading] = useState(false);

	const [accountLoading, setAccountLoading] = useState(false);
	const [snackBarOpen, setSnackBarOpen] = useState("");

	const [showCurrentPassword, setShowCurrentPassword] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const [passwordError, setPasswordError] = useState(0);

	const [userError, setUserError] = useState(false);
	const [users, setUsers] = useState([]);

	const [newManager, setNewManager] = useState(false);
	const [manager, setManager] = useState(null);

	const isMobile = useMediaQuery(useTheme().breakpoints.down("sm"));

	useEffect(
		_ => {
			if (
				users !== null &&
				users.length === 0 &&
				user.role === "head-manager"
			) {
				fetchUsers(users => {
					if (
						users
							.filter(userCurr => userCurr.uid !== user.uid)
							.sort((a, b) => (a.role > b.role ? 1 : -1)).length === 0
					) {
						setUsers(null);
					} else {
						setUsers(
							users
								.filter(userCurr => userCurr.uid !== user.uid)
								.sort((a, b) => (a.role > b.role ? 1 : -1))
						);
					}
				});
			}
		},
		[fetchUsers, setUsers, users, user]
	);

	const setTitle = _ => {
		return (
			<Helmet>
				<title>Settings</title>
			</Helmet>
		);
	};

	const handleChange = e => {
		setUserEdit({ ...userEdit, [e.target.name]: e.target.value });
	};

	const handleMouseDownPassword = event => {
		event.preventDefault();
	};

	const changeUser = _ => {
		setAccountLoading(true);
		if (checkUser()) {
			if (user.email !== userEdit.email) {
				auth
					.signInWithEmailAndPassword(user.email, currentPassword)
					.then(user => {
						auth.currentUser
							.updateEmail(userEdit.email)
							.then(_ => {
								props.updateUser(userEdit, _ => {
									setAccountLoading(false);
									setCurrentPassword("");
									setSnackBarOpen("Account successfully updated");
								});
							})
							.catch(error => console.log(error));
					})
					.catch(error => {
						console.log(error);

						setAccountLoading(false);
						setUserError(true);
					});
			} else {
				props.updateUser(userEdit, _ => {
					setAccountLoading(false);
					setSnackBarOpen("Account successfully updated");
				});
			}
		} else {
			setUserError(true);
			setAccountLoading(false);
		}
	};

	const checkUser = _ => {
		if (
			userEdit.firstname === "" ||
			userEdit.lastname === "" ||
			!isemail.validate(userEdit.email)
		) {
			return false;
		}
		return true;
	};

	const changePassword = _ => {
		setPasswordLoading(true);
		if (checkPassword()) {
			auth
				.signInWithEmailAndPassword(user.email, oldPassword)
				.then(user => {
					auth.currentUser
						.updatePassword(password)
						.then(_ => {
							setPassword("");
							setPasswordRepeat("");
							setOldPassword("");
							setPasswordLoading(false);
							setSnackBarOpen("Password successfully changed");
						})
						.catch(error => {
							console.log(error);
							setPasswordLoading(false);
						});
				})
				.catch(error => {
					setPasswordError(3);
					setPasswordLoading(false);
					console.log(error);
				});
		} else {
			setPasswordLoading(false);
		}
	};

	const checkPassword = _ => {
		if (
			!password.match(
				/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/
			)
		) {
			setPasswordError(2);
			return false;
		} else if (password !== passwordRepeat) {
			setPasswordError(1);
			return false;
		}
		return true;
	};

	return (
		<>
			{setTitle()}
			<Fade in={true}>
				<Grid container className={classes.container} spacing={2}>
					<Grid item xs={12} style={{ marginBottom: 16 }}>
						<Typography variant="h6">My Account</Typography>
					</Grid>
					<Grid item xs={12}>
						<Paper elevation={4}>
							<Grid
								container
								spacing={2}
								justifyContent="center"
								alignContent="center"
								style={{
									padding: 16
								}}
							>
								<Grid item sm={4} xs={6}>
									<TextField
										fullWidth
										autoCapitalize="false"
										label="First Name"
										name="firstname"
										variant="filled"
										color="primary"
										disabled={accountLoading}
										margin="none"
										value={userEdit.firstname}
										onChange={e => {
											handleChange(e);
											setUserError(false);
										}}
										error={
											userError && userEdit.firstname === "" ? true : false
										}
									/>
								</Grid>
								<Grid item sm={4} xs={6}>
									<TextField
										margin="none"
										fullWidth
										disabled={accountLoading}
										autoCapitalize="false"
										label="Last Name"
										name="lastname"
										variant="filled"
										color="primary"
										value={userEdit.lastname}
										onChange={e => {
											handleChange(e);
											setUserError(false);
										}}
										error={userError && userEdit.lastname === "" ? true : false}
									/>
								</Grid>
								<Grid item sm={8} xs={12}>
									<TextField
										fullWidth
										autoCapitalize="false"
										label="Email"
										margin="none"
										disabled={accountLoading}
										name="email"
										variant="filled"
										color="primary"
										value={userEdit.email}
										onChange={e => {
											handleChange(e);
											setUserError(false);
										}}
										error={
											userError && !isemail.validate(userEdit.email)
												? true
												: false
										}
									/>
								</Grid>
								<Grid item sm={8} xs={12}>
									<TextField
										fullWidth
										label="Password"
										disabled={accountLoading}
										variant="filled"
										type={showCurrentPassword ? "text" : "password"}
										value={currentPassword}
										error={userError && checkUser(userEdit) ? true : false}
										helperText={
											userError && checkUser(userEdit)
												? "Password is not valid"
												: "Password is required for email change"
										}
										onChange={e => {
											setCurrentPassword(e.target.value);
											setUserError(false);
										}}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<IconButton
														aria-label="toggle password visibility"
														onClick={_ =>
															setShowCurrentPassword(!showCurrentPassword)
														}
														onMouseDown={handleMouseDownPassword}
														color="secondary"
													>
														{showCurrentPassword ? (
															<Visibility />
														) : (
															<VisibilityOff />
														)}
													</IconButton>
												</InputAdornment>
											)
										}}
									/>
								</Grid>
								<Grid item sm={8} xs={12} style={{ textAlign: "right" }}>
									<div className={classes.progressDiv}>
										<Button
											variant="contained"
											className={classes.button}
											disabled={accountLoading}
											onClick={changeUser}
										>
											Save
										</Button>
										{accountLoading && (
											<CircularProgress
												size={24}
												className={classes.buttonProgress}
											/>
										)}
									</div>
								</Grid>
							</Grid>
							<Divider
								style={{ margin: 32, marginLeft: 48, marginRight: 48 }}
							/>
							<Grid
								container
								spacing={2}
								justifyContent="center"
								alignContent="center"
								style={{
									padding: 16
								}}
							>
								<Grid item sm={4} xs={6}>
									<TextField
										fullWidth
										label="Password"
										disabled={passwordLoading}
										variant="filled"
										type={showPassword ? "text" : "password"}
										value={password}
										error={passwordError === 2 ? true : false}
										helperText={
											passwordError === 2
												? "Password must be between 8 and 15 characters and contain at least one uppercase, one lowercase letter, one digit and one special character"
												: null
										}
										onChange={e => {
											setPassword(e.target.value);
											setPasswordError(0);
										}}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<IconButton
														aria-label="toggle password visibility"
														onClick={_ => setShowPassword(!showPassword)}
														onMouseDown={handleMouseDownPassword}
														color="secondary"
													>
														{showPassword ? <Visibility /> : <VisibilityOff />}
													</IconButton>
												</InputAdornment>
											)
										}}
									/>
								</Grid>
								<Grid item sm={4} xs={6}>
									<TextField
										fullWidth
										label={isMobile ? "Repeat" : "Repeat Password"}
										variant="filled"
										type={showPassword ? "text" : "password"}
										value={passwordRepeat}
										disabled={passwordLoading}
										error={
											passwordError === 1 || passwordError === 2 ? true : false
										}
										helperText={
											passwordError === 1 ? "Password must match" : null
										}
										onChange={e => {
											setPasswordRepeat(e.target.value);
											setPasswordError(0);
										}}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<IconButton
														aria-label="toggle password visibility"
														onClick={_ => setShowPassword(!showPassword)}
														onMouseDown={handleMouseDownPassword}
														color="secondary"
													>
														{showPassword ? <Visibility /> : <VisibilityOff />}
													</IconButton>
												</InputAdornment>
											)
										}}
									/>
								</Grid>
								<Grid item sm={8} xs={12}>
									<TextField
										fullWidth
										label="Old Password"
										disabled={passwordLoading}
										variant="filled"
										type={showPassword ? "text" : "password"}
										value={oldPassword}
										error={passwordError === 3 ? true : false}
										helperText={
											passwordError === 3
												? "This is not the current password"
												: null
										}
										onChange={e => {
											setOldPassword(e.target.value);
											setPasswordError(0);
										}}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<IconButton
														aria-label="toggle password visibility"
														onClick={_ => setShowPassword(!showPassword)}
														onMouseDown={handleMouseDownPassword}
														color="secondary"
													>
														{showPassword ? <Visibility /> : <VisibilityOff />}
													</IconButton>
												</InputAdornment>
											)
										}}
									/>
								</Grid>
								<Grid item sm={8} xs={12} style={{ textAlign: "right" }}>
									<div className={classes.progressDiv}>
										<Button
											variant="contained"
											className={classes.button}
											disabled={passwordLoading}
											onClick={changePassword}
										>
											Change
										</Button>
										{passwordLoading && (
											<CircularProgress
												size={24}
												className={classes.buttonProgress}
											/>
										)}
									</div>
								</Grid>
							</Grid>
						</Paper>
					</Grid>
					{user.role === "head-manager" && (
						<>
							<Grid
								item
								xs={10}
								md={11}
								style={{ marginTop: 50, marginBottom: 16 }}
							>
								<Typography variant="h6">Managers</Typography>
							</Grid>
							<Grid
								item
								xs={2}
								md={1}
								style={{ marginTop: 40, textAlign: "right", paddingRight: 16 }}
							>
								<Fab
									className={classes.addButton}
									aria-label="new"
									onClick={_ => setNewManager(true)}
									size="medium"
								>
									<Add />
								</Fab>
							</Grid>
							<Grid item xs={12} style={{ marginBottom: 48 }}>
								<Paper elevation={4}>
									<Grid
										container
										spacing={2}
										justifyContent="center"
										alignContent="center"
										style={{
											padding: 16
										}}
									>
										{users === null ? (
											<Grid item style={{ paddingTop: 32, paddingBottom: 32 }}>
												<Typography>No users to show</Typography>
											</Grid>
										) : users.length === 0 ? (
											<Grid item style={{ paddingTop: 32, paddingBottom: 32 }}>
												<CircularProgress size={24} />
											</Grid>
										) : (
											<Fade in={true}>
												<Grid item sm={9} xs={12}>
													<TableContainer>
														<Table className={classes.table}>
															<TableHead>
																<TableRow className={classes.tableHead}>
																	<TableCell>Name</TableCell>
																	<TableCell>Email</TableCell>
																	<TableCell>Role</TableCell>
																</TableRow>
															</TableHead>
															<TableBody>
																{users.map((user, key) => (
																	<TableRow
																		key={key}
																		onClick={_ => {
																			setNewManager(true);
																			setManager(user);
																		}}
																	>
																		<TableCell component="th" scope="row">
																			{user.firstname + " " + user.lastname}
																		</TableCell>
																		<TableCell>{user.email}</TableCell>
																		<TableCell>
																			{user.role === "head-manager"
																				? "Head Manager"
																				: "Manager"}
																		</TableCell>
																	</TableRow>
																))}
															</TableBody>
														</Table>
													</TableContainer>
												</Grid>
											</Fade>
										)}
									</Grid>
								</Paper>
							</Grid>
						</>
					)}
				</Grid>
			</Fade>
			<Snackbar
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center"
				}}
				open={snackBarOpen === "" ? false : true}
				onClose={_ => {
					setSnackBarOpen("");
				}}
				autoHideDuration={6000}
			>
				<MuiAlert
					elevation={6}
					variant="filled"
					onClose={_ => {
						setSnackBarOpen("");
					}}
					severity="success"
				>
					{snackBarOpen}
				</MuiAlert>
			</Snackbar>
			<NewManager
				manager={manager}
				open={newManager}
				setOpen={setNewManager}
				setManager={setManager}
				setUsers={setUsers}
			/>
		</>
	);
};

function mapDispatchToProps(dispatch) {
	return {
		updateUser: (user, callback) =>
			dispatch(updateUserWithCallback(user, callback)),
		fetchUsers: callback => dispatch(fetchUsersWithCallback(callback))
	};
}

function mapStateToProps(state) {
	return {
		user: state.user
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
