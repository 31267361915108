import {Button, CircularProgress, Grid} from "@material-ui/core";
import {Check, CloudUpload} from "@material-ui/icons";
import React, {useState} from "react";
import {storageRef} from "../../data/Firebase";

const PictureInput = props => {
	const {
		classes,
		creator,
		handleChangeArrayPhotos,
	} = props;
	const [uploading1, setUploading1] = useState(false);
	const [uploading2, setUploading2] = useState(false);

	const uploadPhoto = e => {
		let photo = e.target.files[0];
		const statusSetter = e.target.name === "1" ? setUploading1 : setUploading2;
		const uploadNumber = Number.parseInt(e.target.name) - 1;
		const name =
			"creators/" +
			Date.now() +
			(photo.type === "image/jpeg"
				? ".jpg"
				: photo.type === "image/png"
					? ".png"
					: null);
		var photoRef = storageRef.child(name);
		statusSetter(true);
		photoRef.put(photo).then(function(snapshot) {
			snapshot.ref.getDownloadURL().then(function(downloadURL) {
				statusSetter(false);
				handleChangeArrayPhotos(uploadNumber, downloadURL);
			});
		});
	};

	return (
		<Grid item xs={12}>
			<Grid
				container
				alignItems="center"
				justifyContent="center"
				spacing={2}
				style={{marginTop: 0, marginBottom: 0}}
			>
				<Grid item xs={6}>
					<input
						accept="image/*"
						style={{display: "none"}}
						id="creators-photo-1"
						name="1"
						multiple
						type="file"
						onChange={uploadPhoto}
					/>
					<label htmlFor="creators-photo-1">
						<Button
							disabled={uploading1}
							fullWidth
							variant="contained"
							className={
								props.errors.photos && creator.photos[0] === ""
									? classes.buttonError
									: null
							}
							component="span"
							startIcon={
								uploading1 ? (
									<CircularProgress size={16}/>
								) : creator.photos[0] !== "" ? (
									<Check/>
								) : (
									<CloudUpload/>
								)
							}
						>
							Picture 1
						</Button>
					</label>
				</Grid>
				<Grid item xs={6}>
					<input
						accept="image/*"
						style={{display: "none"}}
						id="creators-photo-2"
						multiple
						name="2"
						type="file"
						onChange={uploadPhoto}
					/>
					<label htmlFor="creators-photo-2">
						<Button
							disabled={uploading2}
							fullWidth
							variant="contained"
							className={
								props.errors.photos && creator.photos[1] === ""
									? classes.buttonError
									: null
							}
							component="span"
							startIcon={
								uploading2 ? (
									<CircularProgress size={16}/>
								) : creator.photos[1] !== "" ? (
									<Check/>
								) : (
									<CloudUpload/>
								)
							}
						>
							Picture 2
						</Button>
					</label>
				</Grid>
			</Grid>
		</Grid>
	)
}

export default PictureInput;
