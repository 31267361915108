import React from "react";

import {
	Grid,
	TextField,
	InputAdornment,
} from "@material-ui/core";

const BasicInput = props => {

  	    const {
			handleChangeObject,
			isInstagram,
			isTikTok,
			classes,
			errors,
			currentStats,
			currentStatsName
		} = props;

   return (
   		<Grid
			container
			alignItems="center"
			justifyContent="center"
			className={classes.formContainer}
		>
			<Grid item xs={12}>
				<Grid container alignItems="center" justifyContent="center" spacing={2}>
					<Grid item xs={6}>
						<TextField
							fullWidth
							autoCapitalize="false"
							name="male"
							value={currentStats.male}
							onChange={handleChangeObject}
							label="Male"
							margin="dense"
							variant="filled"
							color="primary"
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">%</InputAdornment>
								)
							}}
							error={errors[currentStatsName] ? errors[currentStatsName].male : false}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							fullWidth
							autoCapitalize="false"
							label="Female"
							name="female"
							margin="dense"
							variant="filled"
							color="primary"
							value={currentStats.female}
							onChange={handleChangeObject}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">%</InputAdornment>
								)
							}}
							error={errors[currentStatsName] ? errors[currentStatsName].female : false}
						/>
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<Grid container alignItems="center" justifyContent="center" spacing={2}>
					<Grid item xs={6}>
						<TextField
							fullWidth
							autoCapitalize="false"
							name={isInstagram ? "likes" : isTikTok ? "followers": "subscribers"}
							value={isInstagram ? currentStats.likes : isTikTok ? currentStats.followers : currentStats.subscribers}
							onChange={handleChangeObject}
							label= {isInstagram ? "Likes" : isTikTok ? "Followers": "Subscribers"}
							margin="dense"
							variant="filled"
							color="primary"
							error={errors[currentStatsName] ? ( isInstagram ? errors[currentStatsName].likes : isTikTok ? errors[currentStatsName].followers :errors[currentStatsName].subscribers) : false}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							fullWidth
							autoCapitalize="false"
							label={isTikTok ? "Likes" : "Engagement"}
							name={isTikTok ? "likes" : "engagement"}
							margin="dense"
							variant="filled"
							color="primary"
							value={isTikTok ? currentStats.likes : currentStats.engagement}
							onChange={handleChangeObject}
							InputProps={{
								endAdornment: !isTikTok && (
									<InputAdornment position="end">%</InputAdornment>
								)
							}}
							error={errors[currentStatsName] ? ( isTikTok ? errors[currentStatsName].likes : errors[currentStatsName].engagement) : false}
						/>
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<Grid container alignItems="center" justifyContent="center" spacing={2}>
					<Grid item xs={6}>
						<TextField
							fullWidth
							autoCapitalize="false"
							name="storyViews"
							value={currentStats.storyViews}
							onChange={handleChangeObject}
							label={isInstagram ? "Story Views" : "Views/Videos"}
							margin="dense"
							variant="filled"
							color="primary"
							error={errors[currentStatsName] ? errors[currentStatsName].storyViews : false}

						/>
					</Grid>
					{isInstagram && <Grid item xs={6}>
						<TextField
							fullWidth
							autoCapitalize="false"
							label={isInstagram ? "Post Impressions" : "Impressions"}
							name="postImpressions"
							margin="dense"
							variant="filled"
							color="primary"
							value={currentStats.postImpressions}
							onChange={handleChangeObject}
							error={errors[currentStatsName] ? errors[currentStatsName].postImpressions : false}
						/>
					</Grid>
					}
				</Grid>
			</Grid>
		</Grid>
   	);
};

export default BasicInput;
