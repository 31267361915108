import React, { useState, useEffect } from "react";
import { navigate } from "@reach/router";
import { Helmet } from "react-helmet-async";
import { auth } from "../data/Firebase";

import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { InputAdornment, IconButton, Typography } from "@material-ui/core";
import Fade from "@material-ui/core/Fade";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Visibility, VisibilityOff } from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
	container: {
		height: "100%",
		position: "absolute"
	},
	subContainter: {},
	logo: {
		height: 50,
		flexGrow: 1,
		marginBottom: 20
	},
	loader: {
		color: "#b9b9b9"
	},
	slogan: {
		marginTop: "15px",
		marginBottom: "20px"
	},
	button: {
		margin: "5px",
		marginLeft: "0px",
		marginRight: "0px"
	},
	buttonProgress: {
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12
	},
	buttonProgressReset: {
		position: "absolute",
		right: "45px",
		bottom: "13px"
	},
	buttonLoader: {
		position: "relative",
		marginTop: 15
	},
	error: {
		backgroundColor: theme.palette.error.dark
	},
	icon: {
		fontSize: 20
	},
	iconVariant: {
		opacity: 0.9,
		marginRight: theme.spacing(1)
	},
	message: {
		display: "flex",
		alignItems: "center"
	}
}));

const Recover = props => {
	const classes = useStyles();

	const [password, setPassword] = useState("");
	const [passwordRepeat, setPasswordRepeat] = useState("");
	const [passwordError, setPasswordError] = useState(0);
	const [showPassword, setShowPassword] = useState(false);
	const [passwordLoaing, setPasswordLoading] = useState(false);

	useEffect(_ => {
		const urlParams = new URLSearchParams(window.location.search);
		auth.verifyPasswordResetCode(urlParams.get("oobCode")).catch(function() {
			navigate("/");
		});
	});

	const handleMouseDownPassword = event => {
		event.preventDefault();
	};

	const setNewPassword = _ => {
		setPasswordLoading(true);
		if (checkPassword()) {
			const urlParams = new URLSearchParams(window.location.search);
			auth
				.confirmPasswordReset(urlParams.get("oobCode"), password)
				.then(function() {
					navigate("/");
				})
				.catch(function(error) {
					console.log(error);
				});
		} else {
			setPasswordLoading(false);
		}
	};

	const checkPassword = _ => {
		if (
			!password.match(
				/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/
			)
		) {
			setPasswordError(2);
			return false;
		} else if (password !== passwordRepeat) {
			setPasswordError(1);
			return false;
		}
		return true;
	};

	const setTitle = _ => {
		return (
			<Helmet>
				<title>Recover Password</title>
			</Helmet>
		);
	};

	return (
		<>
			{setTitle()}
			<Fade in={true}>
				<Grid
					container
					direction="column"
					justifyContent="center"
					alignItems="center"
					className={classes.container}
				>
					<Grid item>
						<img
							className={classes.logo}
							src="/i+amhouse_handwritten_white.png"
							alt="Creators Manager"
						/>
					</Grid>
					<Grid item style={{ paddingBottom: 10 }}>
						<Typography align="center" color="textSecondary">
							Please set a new password
						</Typography>
					</Grid>
					<Grid
						container
						alignItems="center"
						justifyContent="center"
						className={classes.subContainter}
					>
						<Grid item xs={8} sm={4} md={3} lg={2}>
							<TextField
								fullWidth
								label="New Password"
								margin="dense"
								variant="filled"
								type={showPassword ? "text" : "password"}
								value={password}
								disabled={passwordLoaing}
								onChange={e => setPassword(e.target.value)}
								error={passwordError === 2 ? true : false}
								helperText={
									passwordError === 2
										? "Password must be between 8 and 15 characters and contain at least one uppercase, one lowercase letter, one digit and one special character"
										: null
								}
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<IconButton
												aria-label="toggle password visibility"
												onClick={_ => setShowPassword(!showPassword)}
												onMouseDown={handleMouseDownPassword}
												color="secondary"
											>
												{showPassword ? <Visibility /> : <VisibilityOff />}
											</IconButton>
										</InputAdornment>
									)
								}}
							/>
						</Grid>
					</Grid>
					<Grid
						container
						alignItems="center"
						justifyContent="center"
						className={classes.subContainter}
					>
						<Grid item xs={8} sm={4} md={3} lg={2}>
							<TextField
								fullWidth
								label="Repeat Password"
								margin="dense"
								variant="filled"
								type={showPassword ? "text" : "password"}
								value={passwordRepeat}
								disabled={passwordLoaing}
								onChange={e => setPasswordRepeat(e.target.value)}
								error={
									passwordError === 1 || passwordError === 2 ? true : false
								}
								helperText={passwordError === 1 ? "Password must match" : null}
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<IconButton
												aria-label="toggle password visibility"
												onClick={_ => setShowPassword(!showPassword)}
												onMouseDown={handleMouseDownPassword}
												color="secondary"
											>
												{showPassword ? <Visibility /> : <VisibilityOff />}
											</IconButton>
										</InputAdornment>
									)
								}}
							/>
						</Grid>
					</Grid>
					<Grid
						container
						alignItems="center"
						justifyContent="center"
						className={classes.subContainter}
					>
						<Grid
							item
							xs={8}
							sm={4}
							md={3}
							lg={2}
							className={classes.buttonLoader}
						>
							<Button
								className={classes.button}
								variant="contained"
								color="primary"
								type="submit"
								fullWidth
								onClick={setNewPassword}
								disabled={passwordLoaing}
							>
								Set Password
							</Button>
							{passwordLoaing && (
								<CircularProgress
									size={24}
									className={classes.buttonProgress}
								/>
							)}
						</Grid>
					</Grid>
				</Grid>
			</Fade>
		</>
	);
};

export default Recover;
