import React from "react";

import {
	Button,
	Grid,
	TextField,
	InputAdornment,
	Paper,
	Typography
} from "@material-ui/core";

	const TopCountriesListItem = props => {
		const {
			topCountry,
			currentStats,
			handleChangeArrayObject,
			index
		} = props;
  		return (
  			<Grid container alignItems="center" justifyContent="center" spacing={1}>
  				<Grid item xs={6}>
					<TextField
						fullWidth
						autoCapitalize="false"
						name="topCountry"
						value={topCountry.country}
						onChange={handleChangeArrayObject(topCountry.id ?? index, "country", currentStats, topCountry)}
						label={"Top Country #" + (topCountry.id ? topCountry.id + 1 : index +1)}
						margin="dense"
						variant="filled"
						color="primary"
					/>
				</Grid>
				<Grid item xs={6}>
					<TextField
						fullWidth
						autoCapitalize="false"
						label="Percentage"
						name="topCountry"
						margin="dense"
						variant="filled"
						color="primary"
						value={topCountry.percentage}
						onChange={handleChangeArrayObject(topCountry.id ?? index , "percentage",currentStats, topCountry)}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">%</InputAdornment>
							)
						}}
					/>
				</Grid>
  			</Grid>
  		)
  	}

const TopCountriesInput = props => {
  	    const {
			classes,
			currentStats,
			currentStatsName,
			addField
		} = props;

   return (
   		<Grid item xs={12}>
			<Paper className={classes.paper} elevation={0}>
				<Typography className={classes.paperTitle}>Top Country</Typography>
				<Grid container alignItems="center" justifyContent="center" spacing={1}>
					{currentStats.topCountry.map((topCountry, index) => (
						<TopCountriesListItem {...props} topCountry={topCountry} key={index} index={index}/>
					))}
					<Grid item xs={12}>
						<Button
							variant="contained"
							onClick={_ =>
								addField({ id: (currentStats.topCountry.length), country: "", percentage: "" }, "topCountry", currentStatsName)
							}
							fullWidth
						>
							More Top Country
						</Button>
					</Grid>
				</Grid>
			</Paper>
		</Grid>
   	);
};

export default TopCountriesInput;
