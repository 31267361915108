import React from "react";

import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import { CssBaseline, Container, Typography, Toolbar } from "@material-ui/core";
import Theme from "../Theme";
import { grey } from "@material-ui/core/colors";

import Appbar from "./Appbar";

const defaultTheme = createTheme();

const newTheme = createTheme({
	...Theme,
	palette: {
		...Theme.palette,
		...defaultTheme.palette,
		primary: grey
	},
	overrides: {
		...Theme.overrides,
		MuiPaper: { rounded: { borderRadius: "2px" } },
		MuiButton: {
			contained: {
				boxShadow: "none",
				borderTopLeftRadius: "2px",
				borderTopRightRadius: "2px",
				borderRadius: "2px",
				backgroundColor: grey[200],
				"&:hover": {
					boxShadow: "none"
				}
			}
		},
		MuiFormLabel: {
			root: {
				color: grey[700],
				"&$focused": {
					color: grey[600]
				}
			}
		},
		MuiFilledInput: {
			root: {
				backgroundColor: grey[100],
				borderBottom: "0px",
				borderTopLeftRadius: "2px",
				borderTopRightRadius: "2px",
				borderRadius: "2px",
				boxShadow:
					"0px 3px 1px -2px rgba(0,0,0,0.1), 0px 2px 2px 0px rgba(0,0,0,0.1), 0px 1px 5px 0px rgba(0,0,0,0.1)"
			},
			underline: {
				"&::before": {
					borderBottom: "0px"
				},
				"&:hover:before": {
					borderBottom: "0px"
				},
				"&::after": {
					borderBottomLeftRadius: "4px",
					borderBottomRightRadius: "4px"
				}
			}
		},
		MuiTooltip: {
			popper: {
				display: "none"
			}
		},
		MuiAlert: {
			filledSuccess: {
				backgroundColor: "#cd9988"
			}
		}
	}
});

const PrivacyPolicy = props => {
	return (
		<ThemeProvider theme={newTheme}>
			<CssBaseline />
			<Appbar />
			<Toolbar />
			<Container id="printingStart" style={{ marginTop: 48, marginBottom: 48 }}>
				<Typography gutterBottom variant="h4">
					Privacy Policy
				</Typography>
				<Typography gutterBottom align="justify">
					Last updated: March 17, 2020
				</Typography>
				<Typography gutterBottom align="justify">
					This Privacy Policy describes Our policies and procedures on the
					collection, use and disclosure of Your information when You use the
					Service and tells You about Your privacy rights and how the law
					protects You.
				</Typography>
				<Typography gutterBottom align="justify">
					We use Your Personal data to provide and improve the Service. By using
					the Service, You agree to the collection and use of information in
					accordance with this Privacy Policy. This Privacy Policy has been
					created with the help of Privacy Policy Generator.
				</Typography>
				<br />
				<Typography gutterBottom variant="h5">
					Interpretation and Definitions
				</Typography>
				<Typography gutterBottom variant="h6">
					Interpretation
				</Typography>
				<Typography gutterBottom align="justify">
					The words of which the initial letter is capitalized have meanings
					defined under the following conditions.
				</Typography>
				<Typography gutterBottom align="justify">
					The following definitions shall have the same meaning regardless of
					whether they appear in singular or in plural.
				</Typography>
				<Typography gutterBottom variant="h6">
					Definitions
				</Typography>
				<Typography gutterBottom align="justify">
					For the purposes of this Privacy Policy:
				</Typography>
				<Typography gutterBottom align="justify">
					<b>You</b> means the individual accessing or using the Service, or the
					company, or other legal entity on behalf of which such individual is
					accessing or using the Service, as applicable.
				</Typography>
				<Typography gutterBottom align="justify">
					<b>Company</b> (referred to as either "the Company", "We", "Us" or
					"Our" in this Agreement) refers to 9744576 Canada Inc., 378 rue du
					Square Sir George Etienne Cartier.
				</Typography>
				<Typography gutterBottom align="justify">
					<b>Affiliate</b> means an entity that controls, is controlled by or is
					under common control with a party, where "control" means ownership of
					50% or more of the shares, equity interest or other securities
					entitled to vote for election of directors or other managing
					authority.
				</Typography>
				<Typography gutterBottom align="justify">
					<b>Account</b> means a unique account created for You to access our
					Service or parts of our Service.
				</Typography>
				<Typography gutterBottom align="justify">
					<b>Website</b> refers to Dulcedo I+AM House, accessible from
					https://house.dulcedo.com/
				</Typography>
				<Typography gutterBottom align="justify">
					<b>Service</b> refers to the Website.
				</Typography>
				<Typography gutterBottom align="justify">
					<b>Country</b> refers to: Quebec, Canada
				</Typography>
				<Typography gutterBottom align="justify">
					<b>Service Provider</b> means any natural or legal person who
					processes the data on behalf of the Company. It refers to third-party
					companies or individuals employed by the Company to facilitate the
					Service, to provide the Service on behalf of the Company, to perform
					services related to the Service or to assist the Company in analyzing
					how the Service is used.
				</Typography>
				<Typography gutterBottom align="justify">
					<b>Third-party Social Media Service</b> refers to any website or any
					social network website through which a User can log in or create an
					account to use the Service.
				</Typography>
				<Typography gutterBottom align="justify">
					<b>Personal Data</b> is any information that relates to an identified
					or identifiable individual.
				</Typography>
				<Typography gutterBottom align="justify">
					<b>Cookies</b> are small files that are placed on Your computer,
					mobile device or any other device by a website, containing the details
					of Your browsing history on that website among its many uses.
				</Typography>
				<Typography gutterBottom align="justify">
					<b>Usage Data</b> refers to data collected automatically, either
					generated by the use of the Service or from the Service infrastructure
					itself (for example, the duration of a page visit).
				</Typography>
				<br />
				<Typography gutterBottom variant="h5">
					Collecting and Using Your Personal Data
				</Typography>
				<Typography gutterBottom variant="h6">
					Personal Data
				</Typography>
				<Typography gutterBottom align="justify">
					While using Our Service, We may ask You to provide Us with certain
					personally identifiable information that can be used to contact or
					identify You. Personally identifiable information may include, but is
					not limited to:
				</Typography>
				<ul>
					<li>Email address</li>
					<li>First name and last name</li>
					<li>Phone number</li>
					<li>Address, State, Province, ZIP/Postal code, City</li>
					<li>Usage Data</li>
				</ul>
				<Typography gutterBottom variant="h6">
					Usage Data
				</Typography>
				<Typography gutterBottom align="justify">
					Usage Data is collected automatically when using the Service. Usage
					Data may include information such as Your Device's Internet Protocol
					address (e.g. IP address), browser type, browser version, the pages of
					our Service that You visit, the time and date of Your visit, the time
					spent on those pages, unique device identifiers and other diagnostic
					data. When You access the Service by or through a mobile device, We
					may collect certain information automatically, including, but not
					limited to, the type of mobile device You use, Your mobile device
					unique ID, the IP address of Your mobile device, Your mobile operating
					system, the type of mobile Internet browser You use, unique device
					identifiers and other diagnostic data. We may also collect information
					that Your browser sends whenever You visit our Service or when You
					access the Service by or through a mobile device.
				</Typography>
				<Typography gutterBottom variant="h6">
					Tracking Technologies and Cookies
				</Typography>
				<Typography gutterBottom align="justify">
					We use Cookies and similar tracking technologies to track the activity
					on Our Service and store certain information. Tracking technologies
					used are beacons, tags, and scripts to collect and track information
					and to improve and analyze Our Service. You can instruct Your browser
					to refuse all Cookies or to indicate when a Cookie is being sent.
					However, if You do not accept Cookies, You may not be able to use some
					parts of our Service. Cookies can be "Persistent" or "Session"
					Cookies. Persistent Cookies remain on your personal computer or mobile
					device when You go offline, while Session Cookies are deleted as soon
					as You close your web browser. We use both session and persistent
					Cookies for the purposes set out below: Necessary / Essential Cookies
					Type: Session Cookies Administered by: Us Purpose: These Cookies are
					essential to provide You with services available through the Website
					and to enable You to use some of its features. They help to
					authenticate users and prevent fraudulent use of user accounts.
					Without these Cookies, the services that You have asked for cannot be
					provided, and We only use these Cookies to provide You with those
					services. Cookies Policy / Notice Acceptance Cookies Type: Persistent
					Cookies Administered by: Us Purpose: These Cookies identify if users
					have accepted the use of cookies on the Website. Functionality Cookies
					Type: Persistent Cookies Administered by: Us Purpose: These Cookies
					allow us to remember choices You make when You use the Website, such
					as remembering your login details or language preference. The purpose
					of these Cookies is to provide You with a more personal experience and
					to avoid You having to re-enter your preferences every time You use
					the Website. For more information about the cookies we use and your
					choices regarding cookies, please visit our Cookies Policy.
				</Typography>
				<Typography gutterBottom variant="h6">
					Use of Your Personal Data
				</Typography>
				<Typography gutterBottom align="justify">
					The Company may use Personal Data for the following purposes:
					<ul>
						<li>
							To provide and maintain our Service, including to monitor the
							usage of our Service.
						</li>
						<li>
							To manage Your Account: to manage Your registration as a user of
							the Service. The Personal Data You provide can give You access to
							different functionalities of the Service that are available to You
							as a registered user.
						</li>
						<li>
							For the performance of a contract: the development, compliance and
							undertaking of the purchase contract for the products, items or
							services You have purchased or of any other contract with Us
							through the Service.
						</li>
						<li>
							To contact You: To contact You by email, telephone calls, SMS, or
							other equivalent forms of electronic communication, such as a
							mobile application's push notifications regarding updates or
							informative communications related to the functionalities,
							products or contracted services, including the security updates,
							when necessary or reasonable for their implementation.
						</li>
						<li>
							To provide You with news, special offers and general information
							about other goods, services and events which we offer that are
							similar to those that you have already purchased or enquired about
							unless You have opted not to receive such information.
						</li>
						<li>
							To manage Your requests: To attend and manage Your requests to Us.
						</li>
					</ul>
					We may share your personal information in the following situations:
					<ul>
						<li>
							With Service Providers: We may share Your personal information
							with Service Providers to monitor and analyze the use of our
							Service, to show advertisements to You to help support and
							maintain Our Service, to contact You, to advertise on third party
							websites to You after You visited our Service or for payment
							processing.
						</li>
						<li>
							For Business transfers: We may share or transfer Your personal
							information in connection with, or during negotiations of, any
							merger, sale of Company assets, financing, or acquisition of all
							or a portion of our business to another company.
						</li>
						<li>
							With Affiliates: We may share Your information with Our
							affiliates, in which case we will require those affiliates to
							honor this Privacy Policy. Affiliates include Our parent company
							and any other subsidiaries, joint venture partners or other
							companies that We control or that are under common control with
							Us.
						</li>
						<li>
							With Business partners: We may share Your information with Our
							business partners to offer You certain products, services or
							promotions.
						</li>
						<li>
							With other users: when You share personal information or otherwise
							interact in the public areas with other users, such information
							may be viewed by all users and may be publicly distributed
							outside. If You interact with other users or register through a
							Third-Party Social Media Service, Your contacts on the Third-Party
							Social Media Service may see Your name, profile, pictures and
							description of Your activity. Similarly, other users will be able
							to view descriptions of Your activity, communicate with You and
							view Your profile.
						</li>
					</ul>
				</Typography>
				<Typography gutterBottom variant="h6">
					Retention of Your Personal Data
				</Typography>
				<Typography gutterBottom align="justify">
					The Company will retain Your Personal Data only for as long as is
					necessary for the purposes set out in this Privacy Policy. We will
					retain and use Your Personal Data to the extent necessary to comply
					with our legal obligations (for example, if we are required to retain
					your data to comply with applicable laws), resolve disputes, and
					enforce our legal agreements and policies. The Company will also
					retain Usage Data for internal analysis purposes. Usage Data is
					generally retained for a shorter period of time, except when this data
					is used to strengthen the security or to improve the functionality of
					Our Service, or We are legally obligated to retain this data for
					longer time periods.
				</Typography>
				<Typography gutterBottom variant="h6">
					Transfer of Your Personal Data
				</Typography>
				<Typography gutterBottom align="justify">
					Your information, including Personal Data, is processed at the
					Company's operating offices and in any other places where the parties
					involved in the processing are located. It means that this information
					may be transferred to — and maintained on — computers located outside
					of Your state, province, country or other governmental jurisdiction
					where the data protection laws may differ than those from Your
					jurisdiction. Your consent to this Privacy Policy followed by Your
					submission of such information represents Your agreement to that
					transfer. The Company will take all steps reasonably necessary to
					ensure that Your data is treated securely and in accordance with this
					Privacy Policy and no transfer of Your Personal Data will take place
					to an organization or a country unless there are adequate controls in
					place including the security of Your data and other personal
					information.
				</Typography>
				<Typography gutterBottom variant="h6">
					Disclosure of Your Personal Data
				</Typography>
				<Typography gutterBottom variant="h6">
					Business Transactions
				</Typography>
				<Typography gutterBottom align="justify">
					If the Company is involved in a merger, acquisition or asset sale,
					Your Personal Data may be transferred. We will provide notice before
					Your Personal Data is transferred and becomes subject to a different
					Privacy Policy.
				</Typography>
				<Typography gutterBottom variant="h6">
					Law enforcement
				</Typography>
				<Typography gutterBottom align="justify">
					Under certain circumstances, the Company may be required to disclose
					Your Personal Data if required to do so by law or in response to valid
					requests by public authorities (e.g. a court or a government agency).
				</Typography>
				<Typography gutterBottom variant="h6">
					Other legal requirements
				</Typography>
				<Typography gutterBottom align="justify">
					The Company may disclose Your Personal Data in the good faith belief
					that such action is necessary to:
					<ul>
						<li>Comply with a legal obligation</li>
						<li>Protect and defend the rights or property of the Company</li>
						<li>
							Prevent or investigate possible wrongdoing in connection with the
							Service
						</li>
						<li>
							Protect the personal safety of Users of the Service or the public
						</li>
						<li>Protect against legal liability</li>
					</ul>
				</Typography>
				<Typography gutterBottom variant="h6">
					Security of Your Personal Data
				</Typography>
				<Typography gutterBottom align="justify">
					The security of Your Personal Data is important to Us, but remember
					that no method of transmission over the Internet, or method of
					electronic storage is 100% secure. While We strive to use commercially
					acceptable means to protect Your Personal Data, We cannot guarantee
					its absolute security.
				</Typography>

				<br />
				<Typography gutterBottom variant="h5">
					Children's Privacy
				</Typography>
				<Typography gutterBottom align="justify">
					Our Service does not address anyone under the age of 13. We do not
					knowingly collect personally identifiable information from anyone
					under the age of 13. If You are a parent or guardian and You are aware
					that Your child has provided Us with Personal Data, please contact Us.
					If We become aware that We have collected Personal Data from anyone
					under the age of 13 without verification of parental consent, We take
					steps to remove that information from Our servers. We also may limit
					how We collect, use, and store some of the information of Users
					between 13 and 18 years old. In some cases, this means We will be
					unable to provide certain functionality of the Service to these users.
					If We need to rely on consent as a legal basis for processing Your
					information and Your country requires consent from a parent, We may
					require Your parent's consent before We collect and use that
					information.
				</Typography>

				<br />
				<Typography gutterBottom variant="h5">
					Links to Other Websites
				</Typography>
				<Typography gutterBottom align="justify">
					Our Service may contain links to other websites that are not operated
					by Us. If You click on a third party link, You will be directed to
					that third party's site. We strongly advise You to review the Privacy
					Policy of every site You visit. We have no control over and assume no
					responsibility for the content, privacy policies or practices of any
					third party sites or services.
				</Typography>

				<br />
				<Typography gutterBottom variant="h5">
					Changes to this Privacy Policy
				</Typography>
				<Typography gutterBottom align="justify">
					We may update our Privacy Policy from time to time. We will notify You
					of any changes by posting the new Privacy Policy on this page. We will
					let You know via email and/or a prominent notice on Our Service, prior
					to the change becoming effective and update the "Last updated" date at
					the top of this Privacy Policy. You are advised to review this Privacy
					Policy periodically for any changes. Changes to this Privacy Policy
					are effective when they are posted on this page.
				</Typography>

				<br />
				<Typography gutterBottom variant="h5">
					Contact Us
				</Typography>
				<Typography gutterBottom align="justify">
					If you have any questions about this Privacy Policy, You can contact
					us:
				</Typography>
				<ul>
					<li>By email: iamhouse@dulcedo.com</li>
					<li>
						By visiting this page on our website:
						https://dulcedo.com/
					</li>
				</ul>
			</Container>
		</ThemeProvider>
	);
};

export default PrivacyPolicy;
