import {Box, Grid, List, ListItem, ListItemSecondaryAction, ListItemText, Typography} from "@material-ui/core";
import {getCurrencySign} from "../../helper";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";

const Rates = props =>{
	const useStyles = makeStyles(theme => ({
		box:{
			marginTop: theme.spacing(2),
			width:'100%',
		},
		rateList: {
			alignItems:'center',
			justifyContent:'center',
		},
		rateItem: {
			paddingTop: 0,
			paddingBottom: 0
		}
	}));
	const classes = useStyles();
	const {creator} = props;

	return(

			<Grid item xs={12} md={7} id='rates'>
				<Box className={classes.box} border={4} borderRadius={2} p={2} borderColor="pink.main">
					<List
						dense
						className={classes.rateList}
					>
						<Typography
							variant="h6"
							style={{ paddingLeft: 16, paddingBottom: 8 }}
						>
							Rates
						</Typography>
						{creator?.rates.length === 0 ? (
							<ListItem className={classes.rateItem}>
								<ListItemText primary="No rates yet" />
							</ListItem>
						) : (
							creator?.rates.map((rate, key) => (
								<ListItem key={key} className={classes.rateItem}>
									<ListItemText primary={<Typography variant="h6" style={{	fontWeight:400}} >{rate.name}</Typography>} />
									<ListItemSecondaryAction>
										<Typography variant="h6" style={{	fontWeight:400}}>
											{rate.currency} {getCurrencySign(rate.currency)}{rate.amount}
										</Typography>
									</ListItemSecondaryAction>
								</ListItem>
							))
						)}
					</List>
				</Box>
			</Grid>
	);
}
export default Rates;
