import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import numeral from "numeral";

import { updateProject } from "../../actions";
import { getCurrenciesRates, computeTotalCurrency, getCurrencySign } from "../../helper";

import { makeStyles } from "@material-ui/core/styles";

import {
	Box,
	Grid,
	Fade,
	Tabs,
	Tab,
	Typography
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";

import PaymentsList from './PaymentsList';
import PaymentsEarnings from './PaymentsEarnings';

const useStyles = makeStyles(theme => ({

	tabIndicator: {
		backgroundColor: "#cd9988"
	},
	tabLabel: {
		color: grey[700]
	},
}));

const a11yProps = (index)=> {
	return {
		id: `scrollable-auto-tab-${index}`,
		"aria-controls": `scrollable-auto-tabpanel-${index}`,
		style: { minWidth: "unset", padding: "6px 32px" }
	};
}

const TabPanel = (props) => {
	const { children, value, index, style } = props;

	return (
		<Box style={{ position: "relative" }}>
			{value === index && <Box style={style} p={3}>{children}</Box>}
		</Box>
	);
}

const Payments = props => {
	const { creator, user } = props;
	const classes = useStyles();
	const [tabValue, setTabValue] = useState(0);

	const [currencyRates, setCurrencyRates] = useState(null);
	const [totalNotYetInvoiced, setTotalNotYetInvoiced] = useState(0);
	const [totalCompleted, setTotalCompleted] = useState(0);
	const [totalUpcoming, setTotalUpcoming] = useState(0);

	const baseCurrency = creator.currency;

	const filterPayments = (paymentSatus, invoiceType) =>{

		if(invoiceType){
			return creator.projects.filter(payment =>
			 	(payment.payment === paymentSatus && payment.invoiceType === invoiceType)
			);
		}

		return creator.projects.filter(payment =>
				payment.payment === paymentSatus
			);
	}

	let filterPaymentsNotYetInvoiced = filterPayments("due","invoiceCompletion");
	let filterPaymentsCompleted = filterPayments("completed");
	let filterUpcomingPayments = filterPayments("due","invoiced");

	useEffect(_ =>{
		if(!currencyRates){
			getCurrenciesRates(baseCurrency)
            .then(response => {
                setCurrencyRates(response.data.exchange_rates);
                setTotalNotYetInvoiced(computeTotalCurrency(filterPaymentsNotYetInvoiced, baseCurrency,response.data.exchange_rates))
                setTotalCompleted(computeTotalCurrency(filterPaymentsCompleted, baseCurrency,response.data.exchange_rates))
                setTotalUpcoming(computeTotalCurrency(filterUpcomingPayments, baseCurrency,response.data.exchange_rates))
            })
            .catch(err => {
                console.log("Cannot get currency rates", err.message);
            });
		}
	}, [currencyRates, baseCurrency,filterPaymentsNotYetInvoiced, filterPaymentsCompleted, filterUpcomingPayments])



	const handleChangeTab = (event, newValue) => {
		setTabValue(newValue);
	};



	return (
		<Fade in={true}>
			<div className={classes.root}>
				<TabPanel style={{ padding: 0 }} value={tabValue} index={0}>
					<PaymentsEarnings creator={creator} label="Upcoming Payments" filterPayments={filterPayments("due","invoiced")} />
				</TabPanel>
				<TabPanel style={{ padding: 0 }} value={tabValue} index={1}>
					<PaymentsEarnings creator={creator} label="Not yet invoiced " filterPayments={filterPayments("due","invoiceCompletion")}/>
				</TabPanel>
				<TabPanel style={{ padding: 0 }} value={tabValue} index={2}>
					<PaymentsEarnings creator={creator} label="Completed payments" filterPayments={filterPayments("completed")}/>
				</TabPanel>
				<TabPanel style={{ padding: 0 }} value={tabValue} index={3}>
					<PaymentsEarnings creator={creator} label="all payments" filterPayments={creator.projects}/>
				</TabPanel>
				 <Tabs
			        value={tabValue}
			        onChange={handleChangeTab}
			        style={{paddingBottom:12}}
			        classes={{ indicator: classes.tabIndicator }}
			        indicatorColor="primary"
			        textColor="primary"
			        centered
			     >
			        <Tab className={classes.tabLabel} {...a11yProps(0)} label="Upcoming Payments" />
			        <Tab className={classes.tabLabel} {...a11yProps(1)} label="Not yet invoiced" />
			        <Tab className={classes.tabLabel} {...a11yProps(2)} label="Completed payments" />
			        <Tab className={classes.tabLabel} {...a11yProps(3)} label="all payments" />
			     </Tabs>
			     <TabPanel value={tabValue} index={0}>
					<PaymentsList creator={creator} user={user} label="Upcoming Payments" filterPayments={filterPayments("due","invoiced")}/>
				</TabPanel>
				<TabPanel value={tabValue} index={1}>
					<PaymentsList creator={creator} user={user} label="Not yet invoiced payments" filterPayments={filterPayments("due","invoiceCompletion")}/>
				</TabPanel>
				<TabPanel value={tabValue} index={2}>
					<PaymentsList creator={creator} user={user} label="Completed payments" filterPayments={filterPayments("completed")} isCollapsible/>
				</TabPanel>
				<TabPanel value={tabValue} index={3}>
					<Grid
						container
						alignItems="center"
						justifyContent="center"
					>
						<Grid item xs={4}>
							<Typography
								variant="h6"
								style={{
									marginTop: 5
								}}
							>
								Total Upcoming Payments
							</Typography>
						</Grid>
						<Grid item xs={4}>
							<Typography
								variant="subtitle1"
								style={{
									marginTop: 5,
									textAlign: "right"
								}}
							>
								{creator.currency} {getCurrencySign(creator.currency)}<b>{numeral(totalUpcoming).format("0,0")}</b>
							</Typography>
						</Grid>
					</Grid>
					<Grid
						container
						alignItems="center"
						justifyContent="center"
					>
						<Grid item xs={4}>
							<Typography
								variant="h6"
								style={{
									marginTop: 5
								}}
							>
								Total Not Yet Invoiced
							</Typography>
						</Grid>
						<Grid item xs={4}>
							<Typography
								variant="subtitle1"
								style={{
									marginTop: 5,
									textAlign: "right"
								}}
							>
								{creator.currency} {getCurrencySign(creator.currency)}<b>{numeral(totalNotYetInvoiced).format("0,0")}</b>
							</Typography>
						</Grid>
					</Grid>

					<Grid
						container
						alignItems="center"
						justifyContent="center"
					>
						<Grid item xs={4}>
							<Typography
								variant="h6"
								style={{
									marginTop: 5
								}}
							>
								Total Completed Payments
							</Typography>
						</Grid>
						<Grid item xs={4}>
							<Typography
								variant="subtitle1"
								style={{
									marginTop: 5,
									textAlign: "right"
								}}
							>
								{creator.currency} {getCurrencySign(creator.currency)}<b>{numeral(totalCompleted).format("0,0")}</b>
							</Typography>

						</Grid>
					</Grid>
				</TabPanel>
			</div>
		</Fade>
	);
};

function mapDispatchToProps(dispatch) {
	return {
		updateProject: (project, creator, callback) =>
			dispatch(updateProject(project, creator, callback))
	};
}

function mapStateToProps(state) {
	return {
		user: state.user
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Payments);
