import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import numeral from "numeral";


import { getCurrenciesRates, computeTotalCurrency, getCurrencySign } from "../../helper";
import { updateProject, deleteProject, createOrderedProjects } from "../../actions";
import NewProject from "./NewProject";
import ViewProject from "./ViewProject";
import ProjectList from "./ProjectList";

import { makeStyles, useTheme } from "@material-ui/core/styles";

import {
	Typography,
	Fab,
	Fade,
	Grow,
	useMediaQuery
} from "@material-ui/core";

import { grey } from "@material-ui/core/colors";
import { Share, Edit, Add } from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
	speedDialActionStyle: {
		backgroundColor: "#cd9988",
		color: "white",
		"&:hover": {
			backgroundColor: "#e8ad9c"
		}
	},
	button: {
		backgroundColor: "#cd9988",
		color: "white",
		"&:hover": {
			backgroundColor: "#e8ad9c"
		}
	},
	tabIndicator: {
		backgroundColor: "#cd9988"
	},
	tabLabel: {
		color: grey[700]
	},
	tabLabelSelected: {
		color: grey[900]
	},
	addButton: {
		backgroundColor: "#cd9988",
		position: "absolute",
		right: 34,
		top: 40,
		color: "white",
		height: 42,
		minWidth: 42,
		width: 42,
		"&:hover": {
			backgroundColor: "#e8ad9c"
		}
	},
	editButton: {
		backgroundColor: "#cd9988",
		color: "white",
		height: 42,
		minWidth: 42,
		width: 42,
		"&:hover": {
			backgroundColor: "#e8ad9c"
		}
	},
	speedDial: {
		position: "absolute",
		top: 12,
		right: 0
	},
	projectBar: {
		backgroundColor: "#000000",
		color: "white"
	},
	rightGrid: {
		textAlign: "right"
	},
	centerGrid: {
		textAlign: "center"
	},
	projectContainer: {
		padding: theme.spacing(3)
	},
	deliItem: {
		paddingTop: 0,
		paddingBottom: 0
	},
	deliList: {
		backgroundColor: grey[200]
	},
	deliListContainer: {
		minWidth:500
	}
}));

const Projects = props => {
	const classes = useStyles();
	const [menuOpen, setMenuOpen] = useState(-1);
	const [projectLoading, setProjectLoading] = useState(-1);
	const [editProject, setEditProject] = useState(null);
	const [currencyRates, setCurrencyRates] = useState(null);
	const [addProject, setAddProject] = useState(false);
	const [viewProject, setViewProject] = useState(null);
	const [view, setView] = useState(false);
	const [budgetTotal, setBudgetTotal] = useState(0);
	const isMobile = useMediaQuery(useTheme().breakpoints.down("sm"));

	const { creator, user, type, projects, createOrderedProjects } = props;
	const prevEditProject = usePrevious(editProject);
	const prevBaseCurrency = usePrevious(creator.currency);

	useEffect( _ => {
		if (prevEditProject && editProject === null && prevEditProject !== null) {
			if (projects.projects_list) {
				createOrderedProjects(creator.projects, projects.projects_list)
			}
		}

	},[projects, creator, editProject, prevEditProject, createOrderedProjects]);

	// Hook
	function usePrevious(value) {
	  // The ref object is a generic container whose current property is mutable ...
	  // ... and can hold any value, similar to an instance property on a class
	  const ref = useRef();

	  // Store current value in ref
	  useEffect(() => {
	    ref.current = value;
	  }, [value]); // Only re-run if value changes

	  // Return previous value (happens before update in useEffect above)
	  return ref.current;
	}

	const filterProjects = _ =>
		creator.projects.filter(project =>
			type === "current"
				? project.status === "active"
					? true
					: false
				: project.status === "closed"
				? true
				: false
		);

	let baseCurrency = creator.currency;
	let creatorProjects = creator.projects;
	let currentFilterProjects = filterProjects();

	useEffect(_ =>{
		if(!currencyRates || prevBaseCurrency !== baseCurrency ){
			getCurrenciesRates(baseCurrency)
            .then(response => {
                setCurrencyRates(response.data.exchange_rates);
                setBudgetTotal(computeTotalCurrency(currentFilterProjects, baseCurrency,response.data.exchange_rates))
            })
            .catch(err => {
                console.log("Cannot get currency rates", err.message);
            });
		}
		if (prevEditProject && editProject === null && prevEditProject !== null) {
			if(prevEditProject.currency !== creatorProjects.find(project => project.id === prevEditProject.id).currency){
				getCurrenciesRates(baseCurrency)
	            .then(response => {
	                setCurrencyRates(response.data.exchange_rates);
	                setBudgetTotal(computeTotalCurrency(currentFilterProjects, baseCurrency,response.data.exchange_rates))
	            })
	            .catch(err => {
	                console.log("Cannot get currency rates", err.message);
	            });
			}
		}
	}, [currencyRates, baseCurrency, budgetTotal, currentFilterProjects, prevBaseCurrency, prevEditProject, editProject, creatorProjects])


	const speedDialActions = [
		{
			label: "Edit",
			icon: <Edit />,
			action: (projectId, key) => {
				setMenuOpen(-1);
				setView(false);
				setEditProject(
					creator.projects.find(project => project.id === projectId)
				);
				setAddProject(true);
			}
		},
		(props.user !== null && props.user.role === "head-manager") ||
		(props.user !== null &&
			props.user.role === "manager" &&
			creator.managers.some(val => val.uid === props.user.uid))
			? {
					label: type === "current" ? "Close" : "Reopen",
					icon: <Share />,
					action: (projectId, key) => {
						setProjectLoading(key);
						props.updateProject(
							{
								...creator.projects.find(project => project.id === projectId),
								status: type === "current" ? "closed" : "active"
							},
							creator,
							_ => {
								setProjectLoading(-1);
								setView(false);
							}
						);
					}
			  }
			: null,
		(props.user !== null && props.user.role === "head-manager") ||
		(props.user !== null &&
			props.user.role === "manager" &&
			creator.managers.some(val => val.uid === props.user.uid))
			? {
					label: "Delete",
					icon: <Share />,
					action: (projectId, key) => {
						setProjectLoading(key);
						props.deleteProject(
							{
								...creator.projects.find(project => project.id === projectId)
							},
							creator,
							_ => {
								setProjectLoading(-1);
								setView(false);
							}
						);
					}
			  }
			: null
	];

	return (
		<Fade in={true}>
			<div className={classes.root}>
				<Typography
					variant="h6"
					style={{
						textTransform: "uppercase",
						textAlign: "center",
						lineHeight: 1.3,
						marginTop: 20,
						fontWeight: "bold"
					}}
				>
					{type + " Projects"}
				</Typography>
				<Typography
					variant="subtitle1"
					style={{
						marginTop: 5,
						textAlign: "center"
					}}
				>
					Total {creator.currency} {getCurrencySign(creator.currency)}
					<b>{numeral(budgetTotal).format("0,0")}</b>
				</Typography>
				<Typography
					variant="subtitle1"
					style={{
						textAlign: "center",
						marginBottom: 60,
						marginTop: -5
					}}
					gutterBottom
				>
					Creator's earnings {creator.currency} {getCurrencySign(creator.currency)}
					<b>
						{numeral(
							budgetTotal * (1 - Number.parseInt(creator.commission) / 100)
						).format("0,0")}
					</b>
				</Typography>
				{type === "current" &&
					(user.role === "manager" || user.role === "head-manager" || (user.role ==="creator" && creator.external)) && (
						<Grow in={true}>
							<Fab
								className={classes.addButton}
								style={isMobile ? { top: 80 } : null}
								aria-label="new"
								onClick={_ => setAddProject(true)}
								size="medium"
							>
								<Add />
							</Fab>
						</Grow>
					)}
				<ProjectList
					projects={projects}
					allProjects={creator.projects}
					filterProjects={filterProjects()}
					creatorId={creator.id}
					classes={classes}
					isMobile={isMobile}
					projectLoading={projectLoading}
					menuOpen={menuOpen}
					setMenuOpen={setMenuOpen}
					speedDialActions={speedDialActions}
					setView={setView}
					setViewProject={setViewProject}
					type={type}
				/>
				<NewProject
					setOpen={setAddProject}
					open={addProject}
					creator={creator}
					editProject={editProject}
					setEditProject={setEditProject}
				/>
				<ViewProject
					setOpen={setView}
					open={view}
					project={viewProject}
					speedDialActions={speedDialActions}
					projectLoading={projectLoading}
				/>
			</div>
		</Fade>
	);
};

function mapDispatchToProps(dispatch) {
	return {
		updateProject: (project, creator, callback) =>
			dispatch(updateProject(project, creator, callback)),
		deleteProject: (project, creator, callback) =>
			dispatch(deleteProject(project, creator, callback)),
		createOrderedProjects:(project, projectList)=>
			dispatch(createOrderedProjects(project, projectList))
	};
}

function mapStateToProps(state) {
	return {
		user: state.user,

	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Projects);
