import React from "react";
import { connect } from "react-redux";
import { Router } from "@reach/router";

import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import Theme from "../Theme";
import CssBaseline from "@material-ui/core/CssBaseline";
import { grey } from "@material-ui/core/colors";

import Appbar from "./Appbar";
import Toolbar from "@material-ui/core/Toolbar";
import { Container } from "@material-ui/core";

import ManageCreators from "./ManageCreators";
import CreatorHouse from "./CreatorHouse";
import Settings from "./Settings";

const defaultTheme = createTheme();

const newTheme = createTheme({
	...Theme,
	palette: {
		...Theme.palette,
		...defaultTheme.palette,
		primary: grey
	},
	overrides: {
		...Theme.overrides,
		MuiPaper: { rounded: { borderRadius: "2px" } },
		MuiStepIcon: {
			text: {
				fill: "white"
			},
			root: {
				"&$active": {
					color: "black"
				},
				"&$completed": {
					color: "black"
				}
			},
			completed: {
				color: "black"
			}
		},
		MuiButton: {
			contained: {
				boxShadow: "none",
				borderTopLeftRadius: "2px",
				borderTopRightRadius: "2px",
				borderRadius: "2px",
				backgroundColor: grey[200],
				"&:hover": {
					boxShadow: "none"
				}
			},
			textPrimary: {
				color: "black"
			}
		},
		MuiFilledInput: {
			root: {
				backgroundColor: grey[100],
				borderBottom: "0px",
				borderTopLeftRadius: "2px",
				borderTopRightRadius: "2px",
				borderRadius: "2px",
				boxShadow:
					"0px 3px 1px -2px rgba(0,0,0,0.1), 0px 2px 2px 0px rgba(0,0,0,0.1), 0px 1px 5px 0px rgba(0,0,0,0.1)"
			},
			underline: {
				"&::before": {
					borderBottom: "0px"
				},
				"&:hover:before": {
					borderBottom: "0px"
				},
				"&::after": {
					borderBottomLeftRadius: "4px",
					borderBottomRightRadius: "4px"
				}
			}
		},
		MuiInput: {
			underline: {
				"&::before": {
					borderBottom: "2px solid " + grey[300]
				},
				"&:hover:not($disabled):before": {
					borderBottom: "2px solid rgba(0, 0, 0, 0.40)"
				},
				"&::after": {
					borderBottom: "2px solid rgba(0, 0, 0, 0.80)"
				}
			}
		},
		MuiSwitch: {
			switchBase: {
				"&$checked $thumb": {
					color: grey[900]
				},
				"&$checked $track": {
					backgroundColor: grey[700]
				}
			}
		},
		MuiTooltip: {
			popper: {
				display: "none"
			}
		},
		MuiAlert: {
			filledSuccess: {
				backgroundColor: "#cd9988"
			}
		},
		MuiFormLabel: {
			root: {
				color: grey[700],
				"&$focused": {
					color: grey[600]
				}
			}
		},
		MuiTab: {
			textColorPrimary: {
				"&$selected": {
					color: grey[900]
				}
			}
		},
		MuiPickersToolbar: {
			toolbar: {
				backgroundColor: "#cd9988"
			}
		},
		MuiIconButton: {
			colorSecondary: {
				color: grey[700]
			}
		},
		MuiPickersToolbarText: {
			toolbarBtnSelected: {
				color: "white"
			},
			toolbarTxt: {
				color: grey[600]
			}
		},
		MuiPickersDay: {
			daySelected: {
				backgroundColor: "#cd9988",
				color: "white"
			}
		},
		MuiTableRow: {
			root: {
				cursor: "pointer",
				transition:
					"background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
				"&:hover": {
					background: grey[200]
				}
			}
		}
	}
});

const Skeleton = props => {
	return (
		<ThemeProvider theme={newTheme}>
			<CssBaseline />
			<Appbar />
			<Toolbar />
			<Container>
				{props.user.role === "creator" ? (
					<Router>
						<Settings path="/settings" />
						<CreatorHouse default />
					</Router>
				) : (
					<Router>
						<CreatorHouse path="/house/:creatorId" />
						<Settings path="/settings" />
						<ManageCreators default />
					</Router>
				)}
			</Container>
		</ThemeProvider>
	);
};

function mapDispatchToProps(dispatch) {
	return {};
}

function mapStateToProps(state) {
	return { user: state.user };
}

export default connect(mapStateToProps, mapDispatchToProps)(Skeleton);
