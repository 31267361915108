import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import isemail from "isemail";
import { cloneDeep } from "lodash";

import {
	updateManagerWithCallback,
	updateUserEmailWithCallback,
	addUserWithCallback,
	deleteUserWithCallback
} from "../actions/";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
	Dialog,
	DialogTitle as MuiDialogTitle,
	IconButton,
	DialogContent,
	DialogActions,
	DialogTitle,
	DialogContentText,
	Grid,
	TextField,
	MenuItem,
	CircularProgress
} from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles(theme => ({
	root: {
		width: "100%"
	},
	backButton: {
		marginRight: theme.spacing(1)
	},
	mobileStepper: {
		paddingRight: 0,
		paddingLeft: 0
	},
	instructions: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1)
	},
	closeButton: {
		position: "absolute",
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[900]
	},
	formContainer: {
		maxWidth: 500,
		margin: "auto"
	},
	paper: {
		justifyContent: "center",
		position: "relative",
		flexWrap: "wrap",
		marginTop: theme.spacing(3),
		padding: theme.spacing(1),
		backgroundColor: "transparent",
		border: "1px solid rgba(0, 0, 0, 0.23)"
	},
	paperTitle: {
		marginTop: -21,
		maxWidth: "fit-content",
		background: "white",
		paddingRight: 5,
		paddingLeft: 5
	},
	button: {
		backgroundColor: "#cd9988",
		color: "white",
		"&:hover": {
			backgroundColor: "#e8ad9c"
		}
	},
	chip: {
		margin: theme.spacing(1)
	},
	deleteDeliverable: {
		width: 32,
		height: 32,
		maxHeight: 32,
		minHeight: 32,
		right: 0,
		top: 0,
		position: "absolute",
		marginRight: -16,
		marginTop: -16,
		backgroundColor: "#cd9988",
		color: "white",
		"&:hover": {
			backgroundColor: "#e8ad9c"
		}
	},
	buttonProgressFinish: {
		position: "absolute",
		right: "35px",
		bottom: "13px"
	}
}));

const defaultManager = {
	firstname: "",
	lastname: "",
	email: "",
	role: ""
};

const roles = [
	{
		value: "manager",
		label: "Manager"
	},
	{
		value: "head-manager",
		label: "Head Manager"
	}
];

const rolesOptions = _ =>
	roles.map((option, key) => (
		<MenuItem key={key} value={option.value}>
			{option.label}
		</MenuItem>
	));

const NewManager = props => {
	const classes = useStyles();
	const [manager, setManager] = useState(cloneDeep(defaultManager));
	const [saving, setSaving] = useState(false);
	const [openDelete, setOpenDelete] = useState(false);
	const [deleteLoading, setDeleteLoading] = useState(false);
	const isMobile = useMediaQuery(useTheme().breakpoints.down("sm"));
	const [currErrors, setCurrErrors] = useState(false);

	useEffect(
		_ => {
			if (props.manager !== null) {
				setManager(cloneDeep(props.manager));
			}
		},
		[props.manager]
	);

	const checkErrors = _ => {
		if (
			manager.firstname === "" ||
			manager.lastname === "" ||
			!isemail.validate(manager.email) ||
			manager.role === ""
		) {
			return false;
		}
		return true;
	};

	const save = _ => {
		if (!checkErrors()) {
			setCurrErrors(true);
			setSaving(false);
		} else {
			setSaving(true);
			if (props.manager !== null) {
				if (props.manager.email !== manager.email) {
					props.updateManager(manager, _ => {
						props.updateUserEmail(manager, _ => {
							props.setUsers([]);
							setSaving(false);
							props.setOpen(false);
						});
					});
				} else {
					props.updateManager(manager, _ => {
						props.setUsers([]);
						setSaving(false);
						props.setOpen(false);
					});
				}
			} else {
				props.addUser(manager, _ => {
					props.setUsers([]);
					setSaving(false);
					props.setOpen(false);
				});
			}
		}
	};

	const deleteUser = _ => {
		setDeleteLoading(true);
		props.deleteUser(manager, _ => {
			props.setUsers([]);
			setDeleteLoading(false);
			setOpenDelete(false);
			props.setOpen(false);
		});
	};

	const handleChange = e => {
		setManager({ ...manager, [e.target.name]: e.target.value });
	};

	return (
		<>
			<Dialog
				onClose={_ => props.setOpen(false)}
				aria-labelledby="customized-dialog-title"
				open={props.open}
				onExited={_ => {
					setCurrErrors(false);
					setManager(cloneDeep(defaultManager));
					props.setManager(null);
				}}
				fullWidth
				maxWidth="lg"
				fullScreen={isMobile}
				keepMounted={false}
			>
				<MuiDialogTitle disableTypography className={classes.root}>
					<Typography
						variant="h6"
						style={{ textTransform: "uppercase", display: "inline-block" }}
					>
						{props.manager !== null ? "Edit" : "Add"} MANAGER
					</Typography>
					{props.manager !== null && (
						<Button
							color="primary"
							variant="contained"
							className={classes.button}
							style={{ marginLeft: 20 }}
							onClick={_ => setOpenDelete(true)}
						>
							Delete
						</Button>
					)}
					<IconButton
						aria-label="close"
						className={classes.closeButton}
						onClick={_ => props.setOpen(false)}
					>
						<CloseIcon />
					</IconButton>
				</MuiDialogTitle>
				<DialogContent dividers>
					<Grid
						container
						alignItems="center"
						justifyContent="center"
						className={classes.formContainer}
					>
						<Grid item xs={12}>
							<Grid container alignItems="center" justifyContent="center" spacing={2}>
								<Grid item xs={6}>
									<TextField
										fullWidth
										autoCapitalize="false"
										name="firstname"
										value={manager.firstname}
										onChange={handleChange}
										label="Firstname"
										margin="dense"
										variant="filled"
										error={
											currErrors && manager.firstname === "" ? true : false
										}
										color="primary"
									/>
								</Grid>
								<Grid item xs={6}>
									<TextField
										fullWidth
										autoCapitalize="false"
										label="Lastname"
										name="lastname"
										margin="dense"
										variant="filled"
										color="primary"
										value={manager.lastname}
										onChange={handleChange}
										error={currErrors && manager.lastname === "" ? true : false}
									/>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12}>
							<TextField
								fullWidth
								autoCapitalize="false"
								label="Email"
								name="email"
								margin="dense"
								variant="filled"
								color="primary"
								value={manager.email}
								onChange={handleChange}
								error={
									currErrors && !isemail.validate(manager.email) ? true : false
								}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								select
								fullWidth
								label="Role"
								margin="dense"
								name="role"
								value={manager.role}
								onChange={handleChange}
								variant="filled"
								error={currErrors && manager.role === "" ? true : false}
							>
								{rolesOptions()}
							</TextField>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button onClick={_ => props.setOpen(false)}>Close</Button>
					<div>
						<Button
							variant="contained"
							className={classes.button}
							onClick={save}
							disabled={saving}
						>
							{props.manager === null ? "Create" : "save"}
						</Button>
						{saving && (
							<CircularProgress
								size={24}
								className={classes.buttonProgressFinish}
							/>
						)}
					</div>
				</DialogActions>
			</Dialog>
			<Dialog open={openDelete} onClose={_ => setOpenDelete(false)}>
				<DialogTitle>
					Are you sure you want to delete{" "}
					{manager.firstname + " " + manager.lastname}?
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Once you delete an account, it will be permanently deleted.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={_ => setOpenDelete(false)} color="primary">
						Cancel
					</Button>
					<div>
						<Button
							color="primary"
							variant="contained"
							className={classes.button}
							onClick={deleteUser}
							disabled={deleteLoading}
						>
							Delete
						</Button>
						{deleteLoading && (
							<CircularProgress
								size={24}
								className={classes.buttonProgressFinish}
							/>
						)}
					</div>
				</DialogActions>
			</Dialog>
		</>
	);
};

function mapDispatchToProps(dispatch) {
	return {
		updateManager: (user, callback) =>
			dispatch(updateManagerWithCallback(user, callback)),
		updateUserEmail: (user, callback) =>
			dispatch(updateUserEmailWithCallback(user, callback)),
		addUser: (user, callback) => dispatch(addUserWithCallback(user, callback)),
		deleteUser: (user, callback) =>
			dispatch(deleteUserWithCallback(user, callback))
	};
}

function mapStateToProps(state) {
	return {
		user: state.user
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(NewManager);
