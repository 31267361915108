import React from "react";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import { ExpandMore, Height } from "@material-ui/icons";
import { Draggable } from "react-beautiful-dnd";
import {
	AppBar,
	CardActionArea,
	CircularProgress,
	Grid,
	IconButton,
	List,
	ListItem,
	ListItemText,
	Paper,
	Toolbar,
	Typography
} from "@material-ui/core";

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  margin: `0 0 32px 0`,

  // styles we need to apply on draggables
  ...draggableStyle
});

const ProjectListItem = props => {
	const {
		classes,
		isMobile,
		index,
		menuOpen,
		project,
		projectLoading,
		setMenuOpen,
		setView,
		setViewProject,
		speedDialActions
	} = props;

	return(
		<Draggable key={project ?project.id:null} draggableId={project ?project.id:null} index={index}>
		{ (provided, snapshot) =>(
			<Grid
				item xs={12}
				ref={provided.innerRef}
	      		{...provided.draggableProps}
	      		{...provided.dragHandleProps}
	      		style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
	      	>
				<AppBar position="relative" className={classes.projectBar}>
					<Toolbar>
					<IconButton edge="start" color="inherit">
				      <Height />
				    </IconButton>
						<Grid container alignItems="center">
							<Grid item xs={3}></Grid>
							<Grid item xs={6} className={classes.centerGrid}>
								<Typography
									variant="h6"
									style={{
										textTransform: "uppercase"
									}}
								>
									{project.brand}
								</Typography>
							</Grid>
							<Grid item xs={3} className={classes.rightGrid}>
								<SpeedDial
									style={{ top: isMobile ? 6 : 12 }}
									className={classes.speedDial}
									FabProps={{
										size: "medium",
										className: classes.editButton
									}}
									ariaLabel="House Actions"
									icon={
										projectLoading === index ? (
											<CircularProgress
												style={{ width: 20, height: 20 }}
											/>
										) : (
											<SpeedDialIcon />
										)
									}
									onClose={_ => setMenuOpen(-1)}
									onOpen={_ => setMenuOpen(index)}
									open={menuOpen === index}
									direction="down"
								>
									{speedDialActions.map(
										(action, keyAction) =>
											action !== null && (
												<SpeedDialAction
													key={keyAction}
													icon={
														<Typography
															variant="body1"
															style={{ textTransform: "none" }}
														>
															{action.label}
														</Typography>
													}
													FabProps={{
														classes: {
															root: classes.speedDialActionStyle
														},
														variant: "extended",
														style: { height: 42, borderRadius: 3 }
													}}
													onClick={_ => {
														setMenuOpen(false);
														action.action(project.id, index);
													}}
													tooltipTitle={action.label + "2"}
												/>
											)
									)}
								</SpeedDial>
							</Grid>
						</Grid>
					</Toolbar>
				</AppBar>
				<CardActionArea
					onClick={_ => {
						setViewProject(project);
						setView(true);
					}}
				>
					<Paper className={classes.projectContainer} elevation={4}>
						<Grid
							container
							direction="row"
							justifyContent="center"
							alignItems="center"
						>
							<Grid item xs={11}>
								<Grid container spacing={2}>
									{project.deliverables.map((deli, key) => (
										<Grid item lg={3} md={4} sm={6} xs={12} key={key} className={classes.deliListContainer}>
											<List dense className={classes.deliList}>
												<Typography
													variant="subtitle2"
													style={{ paddingLeft: 16, paddingBottom: 8 }}
												>
													{deli.name}
												</Typography>
												<ListItem className={classes.deliItem}>
													{(deli.approval.date  || deli.approval.startDate && !deli.approval.endDate)&& (
														<ListItemText
															primary={
																<>
																	Approval Date:{" "}
																	<b>
																		{deli.approval.date ? deli.approval.date.format("MMMM Do"):deli.approval.startDate.format("MMMM Do")}
																	</b>
																</>
															}
														/>
													)}
													{deli.approval.startDate && deli.approval.endDate &&(
														<>
															<ListItemText
																primary={
																	<>
																		Approval Start:{" "}
																		<b>
																			{deli.approval.startDate.format("MMMM Do")}
																		</b>
																	</>
																}
															/>
															<ListItemText
																primary={
																	<>
																		Approval End:{" "}
																		<b>
																			{deli.approval.endDate.format("MMMM Do")}
																		</b>
																	</>
																}
															/>
														</>
													)}
												</ListItem>
												<ListItem className={classes.deliItem}>
													{(deli.post.date || deli.post.startDate && !deli.post.endDate) && (
														<ListItemText
															primary={
																<>
																	Post Date:{" "}
																	<b>
																		{deli.post.date ? deli.post.date.format("MMMM Do") : deli.post.startDate.format("MMMM Do")}
																	</b>
																</>
															}
														/>
													)}
													{deli.post.startDate && deli.post.endDate && (
														<>
															<ListItemText
																primary={
																	<>
																		Post Start:{" "}
																		<b>
																			{deli.post.startDate.format("MMMM Do")}
																		</b>
																	</>
																}
															/>
															<ListItemText
																primary={
																	<>
																		Post End:{" "}
																		<b>
																			{deli.post.endDate.format("MMMM Do")}
																		</b>
																	</>
																}
															/>
														</>
													)}
												</ListItem>
												{
													project.dueDate && (
													<ListItem className={classes.deliItem}>
													<ListItemText
														primary={
															<>
																Payment Due:{" "}
																<b>
																	{project.dueDate.format("MMMM Do")}
																</b>
															</>
														}
													/>
												</ListItem>

												)}

											</List>
										</Grid>
									))}
								</Grid>
							</Grid>
							<Grid xs={1} item style={{ textAlign: "center" }}>
								<ExpandMore fontSize="large" />
							</Grid>
						</Grid>
					</Paper>
				</CardActionArea>
			</Grid>
			)}
		</Draggable>
	);
}

export default ProjectListItem;

