import React from "react";
import {
	Divider,
	Grid,
	Typography,
} from "@material-ui/core";
import {showDecimalsOnlyIfNonZero} from "../utils";

const CreatorStatistics = props =>{

	const {
		classes,
		currentStats,
		currentStatsName,
	} = props;

	const isInstagram = currentStatsName?.includes("insta");
	const isTikTok = currentStatsName?.includes("tiktok");

	return(
		<Grid item xs={12} id='CreatorStatistics'>
			<Grid
				container
				direction="row"
				alignItems="center"
			>
				<Grid item className={classes.topStats}>
					<Typography
						variant="h6"
						className={classes.topStatsLabel}
						noWrap
					>
						{isInstagram ? "Likes" : isTikTok ? "Followers": "Subscribers"}
					</Typography>
					<Typography
						variant="h6"
						noWrap
						className={classes.topStats}
					>
						{isInstagram ? showDecimalsOnlyIfNonZero(currentStats?.likes) : isTikTok ? showDecimalsOnlyIfNonZero(currentStats?.followers) : showDecimalsOnlyIfNonZero(currentStats?.subscribers)}
					</Typography>
				</Grid>
				<Divider
					className={classes.divider}
					orientation="vertical"
				/>
				<Grid item className={classes.topStats}>
					<Typography
						variant="h6"
						className={classes.topStatsLabel}
						noWrap
					>
						{isTikTok ? "Likes" : "ER%"}
					</Typography>
					<Typography
						variant="h6"
						noWrap
						className={classes.topStats}
					>
						{isTikTok ? `${showDecimalsOnlyIfNonZero(currentStats?.likes)}` : `${currentStats?.engagement}%`}
					</Typography>
				</Grid>
				<Divider
					className={classes.divider}
					orientation="vertical"
				/>
				<Grid item className={classes.topStats}>
					<Typography
						variant="h6"
						className={classes.topStatsLabel}
						noWrap
					>
						{isInstagram ? "Story Views" : "Views/Video"}
					</Typography>
					<Typography
						variant="h6"
						noWrap
						className={classes.topStats}
					>
						{showDecimalsOnlyIfNonZero(currentStats?.storyViews)}
					</Typography>
				</Grid>
				{ isInstagram &&	<Divider
					className={classes.divider}
					orientation="vertical"
				/>}
				{ isInstagram && (<Grid item className={classes.topStats}>
					<Typography
						variant="h6"
						className={classes.topStatsLabel}
						noWrap
					>
						Post Impressions
					</Typography>
					<Typography
						variant="h6"
						noWrap
						className={classes.topStats}
					>
						{showDecimalsOnlyIfNonZero(currentStats?.postImpressions)}
					</Typography>
				</Grid>
				)}
			</Grid>
		</Grid>
	);
}

export default CreatorStatistics;
