import React from "react";
import { Router } from "@reach/router";
import LoginForm from "./LoginForm";
import Welcome from "./Welcome";
import Recover from "./Recover";

const Login = props => {
	return (
		<Router>
			<Welcome path="/welcome/:oobCode" />
			<Recover path="/recover" />
			<LoginForm default />
		</Router>
	);
};

export default Login;
