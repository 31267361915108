import React, { useState } from "react";
import { connect } from "react-redux";

import { shareCreator } from "../actions/";

import { makeStyles } from "@material-ui/core/styles";
import {
	Dialog,
	DialogContent,
	DialogActions,
	DialogTitle,
	TextField,
	CircularProgress,
	Snackbar
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles(theme => ({
	button: {
		backgroundColor: "#cd9988",
		color: "white",
		"&:hover": {
			backgroundColor: "#e8ad9c"
		}
	},
	buttonProgressReset: {
		position: "absolute",
		right: 35,
		bottom: 13
	}
}));

const ShareCreator = props => {
	const classes = useStyles();
	const { openDialog, setOpenDialog } = props;
	const [shareEmail, setShareEmail] = useState("");
	const [sendingEmail, setSendingEmail] = useState(false);
	const [sendingEmailStatus, setSendingEmailStatus] = useState(false);

	const shareHouse = _ => {
		setSendingEmail(true);
		props.shareCreator(props.creator, shareEmail, _ => {
			setSendingEmail(false);
			setSendingEmailStatus(true);
		});
	};

	return (
		<Dialog
			open={openDialog}
			onClose={_ => setOpenDialog(false)}
			aria-labelledby="form-dialog-title"
			fullWidth
		>
			<DialogTitle id="form-dialog-title">Share Creator's House</DialogTitle>
			<DialogContent>
				<TextField
					margin="dense"
					id="name"
					label="Email"
					type="email"
					variant="filled"
					value={shareEmail}
					onChange={e => setShareEmail(e.target.value)}
					fullWidth
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={_ => setOpenDialog(false)}>Cancel</Button>
				<div>
					<Button
						onClick={shareHouse}
						color="primary"
						variant="contained"
						disabled={sendingEmail}
						className={classes.button}
					>
						Share
					</Button>
					{sendingEmail && (
						<CircularProgress
							size={24}
							className={classes.buttonProgressReset}
						/>
					)}
				</div>
			</DialogActions>
			<Snackbar
				anchorOrigin={{
					vertical: "top",
					horizontal: "center"
				}}
				open={sendingEmailStatus}
				onClose={_ => setSendingEmailStatus(false)}
				autoHideDuration={6000}
			>
				<MuiAlert
					elevation={6}
					variant="filled"
					onClose={_ => props.passwordResetAlertClose()}
					severity="success"
				>
					Profile successfully shared with {shareEmail} !
				</MuiAlert>
			</Snackbar>
		</Dialog>
	);
};

function mapDispatchToProps(dispatch) {
	return {
		shareCreator: (creator, email, callBack) =>
			dispatch(shareCreator(creator, email, "house", callBack))
	};
}

function mapStateToProps(state) {
	return {
		user: state.user
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(ShareCreator);
