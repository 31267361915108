import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import MomentUtils from "@date-io/moment";
import { cloneDeep } from "lodash";
import isemail from "isemail";

import { addEvent, updateEvent } from "../actions/";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
	Dialog,
	DialogTitle as MuiDialogTitle,
	IconButton,
	DialogContent,
	DialogActions,
	Grid,
	TextField,
	InputAdornment,
	Paper,
	MenuItem,
	CircularProgress,
	Chip
} from "@material-ui/core";
import {
	DatePicker,
	MuiPickersUtilsProvider,
	TimePicker
} from "@material-ui/pickers";
import { Close as CloseIcon, Event, Schedule, Email } from "@material-ui/icons";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles(theme => ({
	root: {
		width: "100%"
	},
	backButton: {
		marginRight: theme.spacing(1)
	},
	mobileStepper: {
		paddingRight: 0,
		paddingLeft: 0
	},
	instructions: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1)
	},
	closeButton: {
		position: "absolute",
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[900]
	},
	formContainer: {
		maxWidth: 500,
		margin: "auto"
	},
	paper: {
		justifyContent: "center",
		position: "relative",
		flexWrap: "wrap",
		marginTop: theme.spacing(3),
		padding: theme.spacing(1),
		backgroundColor: "transparent",
		border: "1px solid rgba(0, 0, 0, 0.23)"
	},
	paperTitle: {
		marginTop: -21,
		maxWidth: "fit-content",
		background: "white",
		paddingRight: 5,
		paddingLeft: 5
	},
	button: {
		backgroundColor: "#cd9988",
		color: "white",
		"&:hover": {
			backgroundColor: "#e8ad9c"
		}
	},
	chip: {
		margin: theme.spacing(1)
	},
	deleteDeliverable: {
		width: 32,
		height: 32,
		maxHeight: 32,
		minHeight: 32,
		right: 0,
		top: 0,
		position: "absolute",
		marginRight: -16,
		marginTop: -16,
		backgroundColor: "#cd9988",
		color: "white",
		"&:hover": {
			backgroundColor: "#e8ad9c"
		}
	},
	buttonProgressFinish: {
		position: "absolute",
		right: "35px",
		bottom: "13px"
	}
}));

const defaultEvent = {
	name: "",
	date: null,
	endDate: null,
	reminder: "",
	location: "",
	guests: []
};

const requiredStep = {
	name: false,
	date: false,
	endDate: false
};

const TextFieldDate = props => (
	<TextField
		{...props}
		fullWidth
		autoCapitalize="false"
		margin="dense"
		variant="filled"
	/>
);

const reminders = [
	{
		value: "-1",
		label: "No reminder"
	},
	{
		value: "0",
		label: "Day of"
	},
	{
		value: "1",
		label: "1 Day Before"
	},
	{
		value: "2",
		label: "2 Days Before"
	},
	{
		value: "3",
		label: "3 Days Before"
	},
	{
		value: "7",
		label: "1 Week Before"
	}
];

const remindersOptions = _ =>
	reminders.map((option, key) => (
		<MenuItem key={key} value={option.value}>
			{option.label}
		</MenuItem>
	));

const NewEvent = props => {
	const classes = useStyles();
	const [event, setEvent] = useState(cloneDeep(defaultEvent));
	const [guest, setGuest] = useState("");
	const [guestError, setGuestError] = useState(false);
	const [saving, setSaving] = useState(false);
	const isMobile = useMediaQuery(useTheme().breakpoints.down("sm"));
	const [currErrors, setCurrErrors] = useState({ ...requiredStep });
	const { mode } = props;

	useEffect(
		_ => {
			if (mode === "edit") {
				setEvent(cloneDeep(props.event));
			}
		},
		[props.event, mode]
	);

	const checkErrors = _ => {
		let required = { ...requiredStep };
		for (let [key, value] of Object.entries(event)) {
			Object.keys(required).forEach(keyReq => {
				if (key === keyReq) {
					if (key === "date" || key === "endDate") {
						if (value === null) {
							required[key] = true;
						} else {
							required[key] = false;
						}
					} else {
						if (value === "") {
							required[key] = true;
						} else if (keyReq === key && value !== "") {
							required[key] = false;
						}
					}
				}
			});
		}

		return Object.values(required).findIndex(item => item) === -1
			? false
			: required;
	};

	const save = _ => {
		const errors = checkErrors();
		if (errors) {
			setCurrErrors({ ...errors });
		} else {
			setSaving(true);
			if (mode === "edit") {
				props.updateEvent(event, props.creator, _ => {
					setSaving(false);
					props.setOpen(false);
				});
			} else {
				props.addEvent(event, props.creator, _ => {
					setSaving(false);
					props.setOpen(false);
				});
			}
		}
	};

	const handleChange = e => {
		setEvent({ ...event, [e.target.name]: e.target.value });
	};

	const handleChangeDate = (name, value) => {
		setEvent({ ...event, [name]: value });
	};

	const addGuest = _ => {
		if (
			!event.guests.some(guestE => guestE === guest) &&
			isemail.validate(guest)
		) {
			const guests = [...event.guests];
			guests.push(guest);
			setGuest("");
			setEvent({ ...event, guests: guests });
		} else {
			setGuestError(true);
		}
	};

	const deleteGuest = key => {
		const guests = [...event.guests];
		guests.splice(key, 1);
		setEvent({ ...event, guests: guests });
	};

	return (
		<MuiPickersUtilsProvider utils={MomentUtils}>
			<Dialog
				onClose={_ => props.setOpen(false)}
				aria-labelledby="customized-dialog-title"
				open={props.open}
				onExited={_ => {
					setCurrErrors({ ...requiredStep });
					setEvent(cloneDeep(defaultEvent));
					props.setEvent(null);
					setGuest("");
				}}
				fullWidth
				maxWidth="lg"
				fullScreen={isMobile}
				keepMounted={false}
			>
				<MuiDialogTitle disableTypography className={classes.root}>
					<Typography variant="h6" style={{ textTransform: "uppercase" }}>
						{mode} EVENT
					</Typography>
					<IconButton
						aria-label="close"
						className={classes.closeButton}
						onClick={_ => props.setOpen(false)}
					>
						<CloseIcon />
					</IconButton>
				</MuiDialogTitle>
				<DialogContent dividers>
					<Grid
						container
						alignItems="center"
						justifyContent="center"
						className={classes.formContainer}
					>
						<Grid item xs={12}>
							<TextField
								fullWidth
								autoCapitalize="false"
								label="Event Name"
								name="name"
								margin="dense"
								variant="filled"
								color="primary"
								value={event.name}
								onChange={handleChange}
								error={currErrors.name}
							/>
						</Grid>
						<Grid item xs={12}>
							<Grid container spacing={1}>
								<Grid item xs={6}>
									<DatePicker
										variant="filled"
										TextFieldComponent={TextFieldDate}
										name="date"
										label="Start Date"
										value={event.date}
										error={currErrors.date}
										onChange={date => handleChangeDate("date", date)}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<Event />
												</InputAdornment>
											)
										}}
									/>
								</Grid>
								<Grid item xs={6}>
									<TimePicker
										variant="filled"
										TextFieldComponent={TextFieldDate}
										name="date"
										label="Start Time"
										value={event.date}
										error={currErrors.date}
										onChange={date => handleChangeDate("date", date)}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<Schedule />
												</InputAdornment>
											)
										}}
									/>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12}>
							<Grid container spacing={1}>
								<Grid item xs={6}>
									<DatePicker
										variant="filled"
										TextFieldComponent={TextFieldDate}
										name="endDate"
										label="End Date"
										value={event.endDate}
										error={currErrors.endDate}
										onChange={date => handleChangeDate("endDate", date)}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<Event />
												</InputAdornment>
											)
										}}
									/>
								</Grid>
								<Grid item xs={6}>
									<TimePicker
										variant="filled"
										TextFieldComponent={TextFieldDate}
										name="endDate"
										label="End Time"
										value={event.endDate}
										error={currErrors.endDate}
										onChange={date => handleChangeDate("endDate", date)}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<Schedule />
												</InputAdornment>
											)
										}}
									/>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12}>
							<TextField
								id="filled-select-currency"
								select
								fullWidth
								label="Reminder"
								margin="dense"
								name="reminder"
								value={event.reminder}
								onChange={handleChange}
								variant="filled"
							>
								{remindersOptions()}
							</TextField>
						</Grid>
						<Grid item xs={12}>
							<TextField
								fullWidth
								autoCapitalize="false"
								label="Location"
								name="location"
								margin="dense"
								variant="filled"
								color="primary"
								value={event.location}
								onChange={handleChange}
							/>
						</Grid>
						<Grid item xs={12} style={{ marginTop: 20 }}>
							<Grid
								container
								alignItems="center"
								justifyContent="center"
								className={classes.formContainer}
							>
								<Grid item xs={12}>
									<TextField
										fullWidth
										autoCapitalize="false"
										label="Guest Email"
										name="guest"
										margin="dense"
										variant="filled"
										color="primary"
										value={guest}
										onChange={e => {
											setGuest(e.target.value);
											setGuestError(false);
										}}
										error={guestError}
									/>
								</Grid>
								<Grid item xs={12}>
									<Button
										variant="contained"
										onClick={addGuest}
										style={{ marginTop: 10 }}
									>
										Add Guest
									</Button>
								</Grid>

								<Grid item xs={12}>
									<Paper
										className={classes.paper}
										elevation={0}
										style={{ display: "flex" }}
									>
										{event.guests.length < 1 ? (
											<Typography>No guests</Typography>
										) : null}
										{event.guests.map((guest, key) => (
											<Chip
												key={key}
												icon={<Email fontSize="small" />}
												label={guest}
												className={classes.chip}
												onDelete={_ => deleteGuest(key)}
											/>
										))}
									</Paper>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button onClick={_ => props.setOpen(false)}>Close</Button>
					<div>
						<Button
							variant="contained"
							className={classes.button}
							onClick={save}
							disabled={saving}
						>
							{mode === "new" ? "Create" : "save"}
						</Button>
						{saving && (
							<CircularProgress
								size={24}
								className={classes.buttonProgressFinish}
							/>
						)}
					</div>
				</DialogActions>
			</Dialog>
		</MuiPickersUtilsProvider>
	);
};

function mapDispatchToProps(dispatch) {
	return {
		addEvent: (event, creator, callback) =>
			dispatch(addEvent(event, creator, callback)),
		updateEvent: (event, creator, callback) =>
			dispatch(updateEvent(event, creator, callback))
	};
}

function mapStateToProps(state) {
	return {
		user: state.user
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(NewEvent);
