const initialState = {
	projects_list:{
		active:{},
		closed:{}
	},
	orderedProjects:{
		active:[],
		closed:[]
	}
}

export default (state = initialState, action) => {
	switch (action.type) {
		case "REORDERED_PROJECTS_LIST":
			const projectStatus = action.payload.projectStatus;
			const projectListUpdated = action.payload.projectList;

			return {
				...state,
				 projects_list: {
				 	...state.projects_list, 
				 	[projectStatus]: projectListUpdated
				}
			}
		case "REORDERED_ORDERED_PROJECTS":
			const orderedProjectStatus = action.payload.projectStatus;
			const orderedProjects = action.payload.orderedProjects;
			//console.log(orderedProjects)

			return {
				...state,
				 orderedProjects: {
				 	...state.orderedProjects, 
				 	[orderedProjectStatus]: orderedProjects
				}
			}
			
		default:
			return state;
	}
};
