import {Avatar, Box, Grid, IconButton, Typography} from "@material-ui/core";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram, faYoutube, faTiktok } from '@fortawesome/free-brands-svg-icons'
import numeral from "numeral";




const SocialMediaLinks = props =>{
	const useStyles = makeStyles(theme => ({
		box:{
			marginTop: theme.spacing(2),
			width:'100%',
			height:'100%',
		},
		container: {
			display: 'flex',
			alignItems:'center',
			justifyContent:'space-evenly',
			height:'100%',
			color:'white'
		},
		columnContainer: {
			display: 'flex',
			alignItems:'center',
			justifyContent:'center',
			height:'100%',
			color:'white',
		},
		avatar:{
			backgroundColor: 'white',
			color:'black',
			width: theme.spacing(7),
			height: theme.spacing(7),
		},
	bigContainer:{
		marginBottom: theme.spacing(2),
	}
	}));
	const {creator}=props;
	const classes = useStyles();
	return(
		<Grid item xs={12} md={5} id='SocialMediaLinks' className={classes.bigContainer}>
			<Box className={classes.box} bgcolor='black' >
				<Grid
					className={classes.container}

				>
					<Grid item container xs={4} className={classes.columnContainer} direction={"column"}>
						<Avatar className={classes.avatar} >
							<IconButton style={{fontSize:'2rem'}} href={
								"https://instagram.com/" +
								(creator === null ? null : creator.instagram)
							}>
								<FontAwesomeIcon icon={faInstagram} color='black' />
							</IconButton>
						</Avatar>
						<Typography variant="h6" style={{	marginTop:'1rem', fontWeight:400}}>
							{numeral(creator?.followers).format('0.0a')}
						</Typography>
						<Typography variant="h6" style={{	marginTop:'-0.5rem',fontWeight:400, paddingLeft:2, paddingRight:2}}>
							{`@${creator?.instagram}`}
						</Typography>
					</Grid>
					{ creator?.youtubeStats.link &&
					<Grid item container xs={4} className={classes.columnContainer} direction={"column"}>
						<Avatar className={classes.avatar}>
							<IconButton style={{fontSize:'2rem'}} href={creator?.youtubeStats?.link?.startsWith('http')? creator?.youtubeStats?.link: 'https://'+ creator?.youtubeStats?.link}>
							<FontAwesomeIcon icon={faYoutube} color='black'  />
							</IconButton>
						</Avatar>
						<Typography variant="h6" style={{	marginTop:'1rem', fontWeight:400}}>
							{numeral(creator?.youtubeStats.subscribers).format('0.0a')}
						</Typography>
						<Typography variant="h6" style={{	marginTop:'-0.5rem',fontWeight:400}}>
							{`@${creator?.instagram}`}
						</Typography>
					</Grid>
					}
					{creator?.tiktokStats.link &&
					<Grid item container xs={4} className={classes.columnContainer} direction={"column"}>
						<Avatar className={classes.avatar}>
							<IconButton style={{fontSize:'2rem'}} href={creator?.tiktokStats?.link?.startsWith('http')? creator?.tiktokStats?.link: 'https://'+ creator?.tiktokStats?.link}>
								<FontAwesomeIcon icon={faTiktok} color='black'  />
							</IconButton>
						</Avatar>
						<Typography variant="h6" style={{	marginTop:'1rem', fontWeight:400}}>
							{numeral(creator?.tiktokStats.followers).format('0.0a')}
						</Typography>
						<Typography variant="h6" style={{	marginTop:'-0.5rem',fontWeight:400}}>
							{`@${creator?.instagram}`}
						</Typography>
					</Grid>}
				</Grid>
			</Box>
		</Grid>
	);
}
export default SocialMediaLinks;
