import React, {useState} from "react";
import {Box, CardContent, Grid, Paper, Typography} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import SpeedDialButton from './SpeedDialButton'
import {Skeleton} from "@material-ui/lab";


const CreatorBio = props => {
	const useStyles = makeStyles((theme) => ({
		root: {
			display: 'flex',
		},

		bio: {
			display: 'flex',
			flexDirection: 'column',
			alignItems:'center',
			justifyContent:'center',
			margin:'3rem',
		},
		box:{
			display:'flex',
			alignItems:'center',
			justifyContent:'center',
			width:'100%',
			height:'100%',
			color: 'white',
		},
		header:{

		},
		img:{
			objectFit: 'cover',
			aspectRatio: '2/3',
			width:'100%',
			height:'100%',
		},
		content: {
			flex: '1 0 auto',
			textAlign:'justify',
		},
		imageLeft: {
			maxHeight: 400,
		},
		imageLeftMobile: {
			// width: "100%",
			maxHeight: 250
		},
	}));

	const { creator, isMobile } = props;
	const classes = useStyles();
	const [hasLoaded, setHasLoaded] = useState(false);
	const handleImageLoad = () =>{
		setHasLoaded(true)
	}

	return (
		<>
			{creator === null && !hasLoaded &&(
				<Grid
					container
					className={classes.container}
					justifyContent="flex-end"
					direction='row'
					spacing={3}
				>
					<Grid
						item
						style={{ height:'5rem', position:"relative"}}
						data-html2canvas-ignore
					>
						<Skeleton
							variant="circle"
							width={42}
							height={42}
							style={{ position: "absolute", right: 48 }}
						/>
					</Grid>
					<Grid item xs={12}  className={isMobile ? classes.imageLeftMobile : classes.imageLeft}>
						<Skeleton
							animation="wave"
							variant="rect"
							height={isMobile ? 250 : 400}
						/>
					</Grid>
				</Grid>
			)}
			{creator && (
				<>
					<SpeedDialButton
						{...props}
					/>
					<Paper elevation={3} style={{width:'100%'}} >
						<Grid container justifyContent="center" id='CreatorBio'>
							<Grid item xs={12} md={5} className={ isMobile ? classes.imageLeftMobile : classes.imageLeft}>
								{!hasLoaded && (<Skeleton
									animation="wave"
									variant="rect"
									height={isMobile ? 250 : 400}
								/>)}
								<img
									className={classes.img}
									src={creator?.photos[0] ?? null}
									alt={creator?.name}
									onLoad={handleImageLoad}
								/>
							</Grid>
							<Grid item xs={12} md={7} className={ isMobile ? "" : classes.imageLeft}>
								{!hasLoaded && (<Skeleton
									animation="wave"
									variant="rect"
									height={isMobile ? 250 : 400}
								/>)}
								<Box bgcolor="pink.main" className={classes.box}>
									<div className={classes.bio}>
										<Typography variant="h6">
											{creator?.firstname?.toUpperCase() + " " + creator?.lastname?.toUpperCase()}
										</Typography>
										<Typography variant="h6" style={{fontWeight:300}}>
											{`${creator?.address.city}, ${creator?.address.state} `}
										</Typography>

										<CardContent className={classes.content}>
											<Typography variant="h6" style={{fontWeight:400}} >
												{creator?.bio}
											</Typography>
										</CardContent>
									</div>
								</Box>
							</Grid>
						</Grid>
					</Paper>
				</>
			)}
		</>

	)
}
export default CreatorBio;
