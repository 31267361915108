import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from "@reach/router";
import { signOut } from "../actions";

import { AppBar, useMediaQuery, Button } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Grow from "@material-ui/core/Grow";
import Grid from "@material-ui/core/Grid";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import IconButton from "@material-ui/core/IconButton";
import Avatar from "@material-ui/core/Avatar";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import grey from "@material-ui/core/colors/grey";

import { Settings, ExitToApp } from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		backgroundColor: "#000000"
	},
	headerLogo: {
		height: 50,
		flexGrow: 1,
		verticalAlign: "bottom"
	},
	toolBar: {
		paddingTop: "7px",
		paddingBottom: "7px"
	},
	rightItems: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1)
	},
	rightGrid: {
		textAlign: "right"
	},
	centerGrid: {
		textAlign: "center"
	},
	listMenuIcon: {
		minWidth: "48px",
		color: grey[800]
	},
	menu: {
		borderRadius: "2px"
	},
	avatar: {
		backgroundColor: theme.palette.pink.main
	},
	buttonHeader: {
		backgroundColor: "transparent",
		color: "white",
		"&:hover": {
			backgroundColor: "transparent"
		}
	},
	menuButton: {
		color: "white",
		marginRight: theme.spacing(2),
		backgroundColor: "transparent",
		"&:hover": {
			backgroundColor: "transparent"
		}
	}
}));

const Appbar = props => {
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const classes = useStyles();
	const isMobile = useMediaQuery(useTheme().breakpoints.down("sm"));

	const handleToggle = event => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = event => {
		setAnchorEl(null);
	};

	return (
		<AppBar position="fixed" className={classes.appBar}>
			<Toolbar className={classes.toolBar}>
				<Grid alignItems="center" container>
					<Grid item xs={3}>
						{props.user !== null && props.user.role !== "creator" ? (
							<Button
								color="primary"
								aria-controls="menu-list-grow"
								aria-haspopup="true"
								component={Link}
								className={classes.menuButton}
								to="/"
							>
								Creators
							</Button>
						) : (
							props.user !== null &&
							props.user.role === "creator" && (
								<>
									<Button
										color="primary"
										aria-controls="menu-list-grow"
										aria-haspopup="true"
										component={Link}
										className={classes.menuButton}
										to="/"
									>
										House
									</Button>
									<Button
										color="primary"
										aria-controls="menu-list-grow"
										aria-haspopup="true"
										component={Link}
										className={classes.menuButton}
										to={"/profile/" + props.user.creator}
									>
										Profile
									</Button>
								</>
							)
						)}
					</Grid>
					<Grid item xs={6} className={classes.centerGrid}>
						<Grow in={true}>
							<img
								className={classes.headerLogo}
								src="/i+amhouse_handwritten_white.png"
								alt="I+AM Logo"
							/>
						</Grow>
					</Grid>
					<Grid item xs={3} className={classes.rightGrid}>
						{props.user === null ? (
							<Button
								variant="contained"
								color="primary"
								aria-controls="menu-list-grow"
								aria-haspopup="true"
								component={Link}
								to="/"
								className={classes.buttonHeader}
							>
								Login
							</Button>
						) : isMobile ? (
							<IconButton
								aria-label="Avatar"
								size="small"
								color="primary"
								className={classes.rightItems}
								aria-controls="menu-list-grow"
								aria-haspopup="true"
								onClick={handleToggle}
							>
								<Avatar className={classes.avatar}>
									{props.user.firstname.charAt(0).toUpperCase() +
										props.user.lastname.charAt(0).toUpperCase()}
								</Avatar>
							</IconButton>
						) : (
							<Button
								variant="contained"
								color="primary"
								aria-controls="menu-list-grow"
								aria-haspopup="true"
								onClick={handleToggle}
								className={classes.buttonHeader}
							>
								{props.user.firstname + " " + props.user.lastname}
							</Button>
						)}

						<Menu
							id="menu-appbar"
							anchorEl={anchorEl}
							anchorOrigin={{
								vertical: "top",
								horizontal: "right"
							}}
							keepMounted
							transformOrigin={{
								vertical: "top",
								horizontal: "right"
							}}
							open={open}
							onClose={handleClose}
							classes={{ paper: classes.menu }}
						>
							<MenuItem onClick={handleClose} component={Link} to="/settings">
								<ListItemIcon className={classes.listMenuIcon}>
									<Settings />
								</ListItemIcon>
								Settings
							</MenuItem>
							<MenuItem onClick={props.signOut}>
								<ListItemIcon className={classes.listMenuIcon}>
									<ExitToApp />
								</ListItemIcon>
								Disconnect
							</MenuItem>
						</Menu>
					</Grid>
				</Grid>
			</Toolbar>
		</AppBar>
	);
};

function mapDispatchToProps(dispatch, ownProps) {
	return {
		signOut: _ => dispatch(signOut()),
		classes: ownProps.classes,
		toggleSideNav: ownProps.toggleSideNav
	};
}

function mapStateToProps(state) {
	return {
		user: state.user
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Appbar);
