import React from "react";
import {Checkbox, FormControlLabel, Grid, Typography, withStyles} from "@material-ui/core";
import { FavoriteBorder, Favorite } from "@material-ui/icons";
import BasicInput from "./BasicInput";
import LinkInput from "./LinkInput";
import PictureInput from "./PictureInput";
import TopAgeInput from "./TopAgeInput";
import TopCitiesInput from "./TopCitiesInput";
import TopCountriesInput from "./TopCountriesInput";

 const SocialMediaInput = props => {
	const {
		addField,
		classes,
		creator,
		currentStatsName,
		handleChangeObject,
		handleChangeArrayObject,
		hasLinkInput,
		hasPictureInput,
		socialMediaName,
	} = props;

	 const [checked, setChecked] = React.useState(creator[currentStatsName].primary ?? false);
	 const handlePrimaryChannel = e => {
		 setChecked(e.target.checked);
	 }
	 const PinkCheckbox = withStyles({
		 root: {
			 color: "#cd9988",
			 '&$checked': {
				 color: '#e8ad9c',
			 },
		 },
		 checked: {},
	 })((props) => <Checkbox color="default" {...props} />);

	 return (
		<Grid
	 	container
	 	alignItems="center"
	 >
			<Grid item lg={6} xs={12} style={{marginTop: "1rem"}}>
				<Typography variant="h6" className={classes.formContainer}>
					{socialMediaName}
				</Typography>
				<Grid
					container
					alignItems="center"
					justifyContent="flex-end"
					className={classes.formContainer}
				>
					<Grid item xs={4}>
						<FormControlLabel
							control={
								<PinkCheckbox
									checked={checked}
									onChange={handleChangeObject(currentStatsName,handlePrimaryChannel)}
									icon={<FavoriteBorder />}
									checkedIcon={<Favorite />}
									name="primary"
								/>
							}
							label={checked ? "Primary Channel" : "Make Primary"}
						/>
					</Grid>
				</Grid>

				<Grid
					container
					alignItems="center"
					justifyContent="center"
					className={classes.formContainer}
				>
					{hasLinkInput &&
						<LinkInput
							errors={props.errors}
							handleChangeObject={handleChangeObject(currentStatsName)}
							currentStats={creator[currentStatsName]}
						/>
					}
					<BasicInput
						classes={classes}
						errors={props.errors}
						currentStatsName={currentStatsName}
						handleChangeObject={handleChangeObject(currentStatsName)}
						currentStats={creator[currentStatsName]}
						isInstagram={true}
					/>
					<TopAgeInput
						addField={addField}
						classes={classes}
						currentStatsName={currentStatsName}
						currentStats={creator[currentStatsName]}
						handleChangeArrayObject={handleChangeArrayObject}
					/>
					{hasPictureInput &&
						<PictureInput
							{...props}
						/>
					}
				</Grid>
			</Grid>
	 		<Grid item lg={6} xs={12}>
				<Grid
					container
					alignItems="center"
					justifyContent="center"
					className={classes.formContainer}
				>
					<TopCitiesInput
						addField={addField}
						classes={classes}
						currentStatsName={currentStatsName}
						currentStats={creator[currentStatsName]}
						handleChangeArrayObject={handleChangeArrayObject}
					/>
					<TopCountriesInput
						addField={addField}
						classes={classes}
						currentStatsName={currentStatsName}
						currentStats={creator[currentStatsName]}
						handleChangeArrayObject={handleChangeArrayObject}
					/>
				</Grid>
	 		</Grid>
		</Grid>
	);
}
export default SocialMediaInput;
