import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { fetchManagers } from "../../actions/ManagerActions";

import {
	Grid,
	TextField,
	CircularProgress,
	Button,
	Paper,
	Typography,
	Chip,
	Avatar,
	InputAdornment
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

const NewCreatorStep3 = props => {
	const {
		handleChangeArrayManagers,
		handleDeleteArrayManagers,
		creator,
		classes,
		fetchManagers
	} = props;
	const [open, setOpen] = useState(false);
	const [error, setError] = useState(false);
	const [manager, setManager] = useState("");

	useEffect(
		_ => {
			fetchManagers();
		},
		[fetchManagers]
	);

	const addManager = _ => {
		let managerToAdd = props.managers.find(
			managerCurr => managerCurr.fullName === manager
		);
		if (
			typeof managerToAdd !== "undefined" &&
			!creator.managers.some(
				managerCurr => managerCurr.uid === managerToAdd.uid
			)
		) {
			handleChangeArrayManagers(managerToAdd);
		} else {
			setError(true);
		}
	};

	return (
		<Grid
			container
			alignItems="center"
			justifyContent="center"
			className={classes.formContainer}
		>
			<Grid item xs={12}>
				<Autocomplete
					id="asynchronous-demo"
					open={open}
					onOpen={() => {
						setOpen(true);
					}}
					onClose={() => {
						setOpen(false);
					}}
					getOptionSelected={(option, value) =>
						option.fullName === value.fullName
					}
					onInputChange={(e, v, r) => {
						setManager(v);
						setError(false);
					}}
					getOptionLabel={option => {
						return option.fullName;
					}}
					options={props.managers}
					loading={props.managers.length === 0 ? true : false}
					renderInput={params => (
						<TextField
							{...params}
							label="Search Manager"
							fullWidth
							error={error}
							value={manager}
							variant="filled"
							InputProps={{
								...params.InputProps,
								endAdornment: (
									<>
										{props.managers.length === 0 ? (
											<CircularProgress color="inherit" size={20} />
										) : null}
										{params.InputProps.endAdornment}
									</>
								)
							}}
						/>
					)}
				/>
			</Grid>
			<Grid item xs={12}>
				<Button
					variant="contained"
					onClick={addManager}
					style={{ marginTop: 10 }}
				>
					Add Manager
				</Button>
			</Grid>
			<Grid item xs={12}>
				<Paper
					className={classes.paper}
					elevation={0}
					style={{ display: "flex" }}
				>
					{creator.managers.length < 1 ? (
						<Typography>No manager assigned</Typography>
					) : null}
					{creator.managers.map((manager, key) => (
						<Chip
							key={key}
							avatar={<Avatar>{manager.fullName.charAt(0)}</Avatar>}
							label={manager.fullName}
							className={classes.chip}
							onDelete={_ => handleDeleteArrayManagers(key)}
						/>
					))}
				</Paper>
			</Grid>
			<Grid item xs={12} style={{ marginTop: 16 }}>
				<TextField
					fullWidth
					autoCapitalize="false"
					name="commission"
					value={creator.commission}
					onChange={props.handleChange}
					label="Manager's Commission"
					margin="dense"
					variant="filled"
					error={props.errors.commission}
					color="primary"
					InputProps={{
						endAdornment: <InputAdornment position="end">%</InputAdornment>
					}}
				/>
			</Grid>
		</Grid>
	);
};

function mapDispatchToProps(dispatch) {
	return { fetchManagers: _ => dispatch(fetchManagers()) };
}

function mapStateToProps(state) {
	return {
		managers: state.managers
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(NewCreatorStep3);
