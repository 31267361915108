import { combineReducers } from "redux";
import UserReducer from "./User";
import AppReducer from "./App";
import CreatorsReducer from "./Creators";
import ManagersReducer from "./Managers";
import ProjectsReducer from "./Projects";

export default combineReducers({
	user: UserReducer,
	app: AppReducer,
	creators: CreatorsReducer,
	managers: ManagersReducer,
	projects: ProjectsReducer,
});
