import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import MomentUtils from "@date-io/moment";
import mime from "mime-types";
import { cloneDeep } from "lodash";
import numeral from "numeral";

import { addProject, updateProject } from "../../actions/";
import currencies from "../../data/Currencies";
import { storageRef } from "../../data/Firebase";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
	Checkbox,
	Dialog,
	DialogTitle as MuiDialogTitle,
	IconButton,
	DialogContent,
	DialogActions,
	Grid,
	TextField,
	InputAdornment,
	Paper,
	MenuItem,
	Fab,
	List,
	ListItemText,
	ListItem,
	CircularProgress,
	RadioGroup,
	Radio,
	FormControl,
	FormGroup,
	FormControlLabel,
	ListItemSecondaryAction
} from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { grey } from "@material-ui/core/colors";
import {
	Close as CloseIcon,
	Event,
	Clear,
	CloudUpload
} from "@material-ui/icons";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles(theme => ({
	root: {
		width: "100%"
	},
	backButton: {
		marginRight: theme.spacing(1)
	},
	mobileStepper: {
		paddingRight: 0,
		paddingLeft: 0
	},
	instructions: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1)
	},
	closeButton: {
		position: "absolute",
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[900]
	},
	formContainer: {
		maxWidth: 500,
		margin: "auto"
	},
	paper: {
		justifyContent: "center",
		position: "relative",
		flexWrap: "wrap",
		marginTop: theme.spacing(3),
		padding: theme.spacing(1),
		backgroundColor: "transparent",
		border: "1px solid rgba(0, 0, 0, 0.23)"
	},
	paperTitle: {
		marginTop: -21,
		maxWidth: "fit-content",
		background: "white",
		paddingRight: 5,
		paddingLeft: 5
	},
	button: {
		backgroundColor: "#cd9988",
		color: "white",
		"&:hover": {
			backgroundColor: "#e8ad9c"
		}
	},
	chip: {
		margin: theme.spacing(1)
	},
	deleteDeliverable: {
		width: 32,
		height: 32,
		maxHeight: 32,
		minHeight: 32,
		right: 0,
		top: 0,
		position: "absolute",
		marginRight: -16,
		marginTop: -16,
		backgroundColor: "#cd9988",
		color: "white",
		"&:hover": {
			backgroundColor: "#e8ad9c"
		}
	},
	buttonProgressFinish: {
		position: "absolute",
		right: "35px",
		bottom: "13px"
	},
	formControlLabel: {
		fontSize: '12px',
		color: grey[900]
	},
	radioButton: {
		padding: 1,
	},
}));


const StyledRadio = (props) => {
  const classes = useStyles();

  return (
    <Radio
      classes={{root: classes.radioButton}}
      color="default"
      {...props}
    />
  );
}

const defaultProject = {
	brand: "",
	budget: "",
	currency:"",
	dueDate: null,
	guidelines: "",
	handles: "",
	hashtags: "",
	link: "",
	notes: "",
	status: "active",
	terms: "",
	payment: "due",
	paymentMethod:"wire",
	invoiceType:"invoiced",
	fees:"yes",
	exclusivity:"",
	organicSocial: false,
	whitelistingPaidAds: false,
	web: false,
	email:false,
	print:false,
	attachments: [],
	deliverables: [
		{
			approval: {
				date: null,
				reminder: "",
				startDate: null,
				endDate: null,
			},
			post: {
				date: null,
				reminder: "",
				startDate: null,
				endDate: null,
			},
			name: ""
		}
	]
};

const requiredStep = {
	brand: false,
	budget: false,
	deliverables: false,
	currency: false
};

const TextFieldDate = props => (
	<TextField
		{...props}
		fullWidth
		autoCapitalize="false"
		margin="dense"
		variant="filled"
		InputProps={{
			endAdornment: (
				<InputAdornment position="end">
					<Event />
				</InputAdornment>
			)
		}}
	/>
);

const reminders = [
	{
		value: "-1",
		label: "No reminder"
	},
	{
		value: "0",
		label: "Day of"
	},
	{
		value: "1",
		label: "1 Day Before"
	},
	{
		value: "2",
		label: "2 Days Before"
	},
	{
		value: "3",
		label: "3 Days Before"
	},
	{
		value: "7",
		label: "1 Week Before"
	}
];

const remindersOptions = _ =>
	reminders.map((option, key) => (
		<MenuItem key={key} value={option.value}>
			{option.label}
		</MenuItem>
	));

const NewProject = props => {
	const classes = useStyles();

	const [project, setProject] = useState(cloneDeep(defaultProject));
	const [saving, setSaving] = useState(false);
	const [uploading, setUploading] = useState(false);
	const [currErrors, setCurrErrors] = useState({ ...requiredStep });

	const { editProject } = props;
	const mode = editProject === null ? "new" : "edit";
	const isMobile = useMediaQuery(useTheme().breakpoints.down("sm"));

	useEffect(
		_ => {
			if (mode === "edit") {
				setProject(cloneDeep(editProject));
			}
		},
		[editProject, mode]
	);

	const uploadAttachment = async e => {
		let files = Object.values(e.target.files);
		const promises = [];
		setUploading(true);
		files.forEach(file => {
			const name =
				"projects/" +
				file.name.replace(/\.[^/.]+$/, "") +
				"-" +
				Date.now() +
				"." +
				mime.extension(file.type);
			var fileRef = storageRef.child(name);
			promises.push(
				fileRef.put(file).then(function(snapshot) {
					snapshot.ref.getDownloadURL().then(function(downloadURL) {
						handleChangeArrayAttachments(downloadURL);
					});
				})
			);
		});
		await Promise.all(promises);
		setUploading(false);
	};

	const checkErrors = _ => {
		let required = { ...requiredStep };
		for (let [key, value] of Object.entries(project)) {
			Object.keys(required).forEach(keyReq => {
				if (key === keyReq) {
					if (Array.isArray(value) && key === "deliverables") {
						if (
							value.some(
								item =>
									typeof item === "object" &&
									!(
										item.approval.startDate !== null &&
										item.post.startDate !== null &&
										item.name !== ""
									)
							)
						) {
							required[key] = true;
						} else required[key] = false;
					} else if (key === "dueDate") {
						if (value === null) {
							required[key] = true;
						} else {
							required[key] = false;
						}
					} else {
						if (value === "") {
							required[key] = true;
						} else if (keyReq === key && value !== "") {
							required[key] = false;
						}
					}
				}
			});
		}

		return Object.values(required).findIndex(item => item) === -1
			? false
			: required;
	};

	const save = _ => {
		const errors = checkErrors();
		if (errors) {
			setCurrErrors({ ...errors });
		} else {
			setSaving(true);
			if (mode === "edit") {
				props.updateProject(project, props.creator, _ => {
					setSaving(false);
					props.setOpen(false);
				});
			} else {
				props.addProject(project, props.creator, _ => {
					setSaving(false);
					props.setOpen(false);
				});
			}
		}
	};

	const handleChange = e => {
		switch(e.target.name) {
		  case "organicSocial":
		  case "whitelistingPaidAds":
		  case "web":
		  case "email":
		  case "print":
		  	setProject({ ...project, [e.target.name]: e.target.checked });
		  	break;
		  default:
		    setProject({ ...project, [e.target.name]: e.target.value });
		}
	};

	const handleChangeDate = (name, value) => {
		setProject({ ...project, [name]: value });
	};

	const deleteDeliverable = index => {
		let newArray = [...project.deliverables];
		newArray.splice(index, 1);
		setProject({ ...project, deliverables: newArray });
	};

	const deleteAttachment = index => {
		let newArray = [...project.attachments];
		newArray.splice(index, 1);
		setProject({ ...project, attachments: newArray });
	};

	const handleChangeArray = (index, attribute) => e => {
		let newArray = project[e.target.name];
		newArray[index][attribute] = e.target.value;
		setProject({ ...project, [e.target.name]: newArray });
	};

	const handleChangeArrayObject = (
		arrayName,
		index,
		attribute,
		subAttribute,
		value
	) => {
		let newArray = [...project[arrayName]];
		newArray[index][attribute][subAttribute] = value;
		setProject({ ...project, [arrayName]: newArray });
	};

	const handleChangeArrayAttachments = value => {
		let newArray = project.attachments;
		newArray.push(value);
		setProject({ ...project, attachments: newArray });
	};

	const addField = (voidObject, target) => {
		let newArray = [...project[target]];
		newArray.push(voidObject);
		setProject({ ...project, [target]: newArray });
	};

	return (
		<MuiPickersUtilsProvider utils={MomentUtils}>
			<Dialog
				onClose={_ => props.setOpen(false)}
				aria-labelledby="customized-dialog-title"
				open={props.open}
				onExited={_ => {
					setCurrErrors({ ...requiredStep });
					setProject(cloneDeep(defaultProject));
					props.setEditProject(null);
				}}
				fullWidth
				maxWidth="lg"
				fullScreen={isMobile}
				keepMounted={false}
			>
				<MuiDialogTitle disableTypography className={classes.root}>
					<Typography variant="h6" style={{ textTransform: "uppercase" }}>
						{mode} PROJECT
					</Typography>
					<IconButton
						aria-label="close"
						className={classes.closeButton}
						onClick={_ => props.setOpen(false)}
					>
						<CloseIcon />
					</IconButton>
				</MuiDialogTitle>
				<DialogContent dividers>
					<Grid container>
						<Grid item lg={6} xs={12}>
							<Grid
								container
								alignItems="center"
								justifyContent="center"
								className={classes.formContainer}
							>
								<Grid item xs={12}>
									<Grid
										container
										alignItems="center"
										justifyContent="center"
										spacing={2}
									>
										<Grid item xs={6}>
											<TextField
												fullWidth
												autoCapitalize="false"
												label="Brand Name"
												name="brand"
												margin="dense"
												variant="filled"
												color="primary"
												value={project.brand}
												onChange={handleChange}
												error={currErrors.brand}
											/>
										</Grid>
										<Grid item xs={3}>
											<TextField
												fullWidth
												autoCapitalize="false"
												label="Budget"
												name="budget"
												margin="dense"
												variant="filled"
												color="primary"
												value={numeral(project.budget).value()}
												onChange={handleChange}
												error={currErrors.budget}
											/>
										</Grid>
										<Grid item xs={3}>
												<TextField
													id="filled-select-currency"
													select
													fullWidth
													label="Currency"
													margin="dense"
													name="currency"
													value={project.currency}
													onChange={handleChange}
													error={currErrors.currency}
													variant="filled"

												>
													{currencies.map(option => (
														<MenuItem key={option.code} value={option.code}>
															{option.sign} {option.code}
														</MenuItem>
													))}
												</TextField>
											</Grid>
									</Grid>
								</Grid>
								<Grid item xs={12}>
									<TextField
										fullWidth
										autoCapitalize="false"
										label="Handles"
										name="handles"
										margin="dense"
										variant="filled"
										color="primary"
										value={project.handles}
										onChange={handleChange}
										error={currErrors.handles}
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										fullWidth
										autoCapitalize="false"
										label="Hashtags"
										name="hashtags"
										margin="dense"
										variant="filled"
										color="primary"
										value={project.hashtags}
										onChange={handleChange}
										error={currErrors.hashtags}
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										fullWidth
										autoCapitalize="false"
										label="Bio or Swipe up link"
										name="link"
										margin="dense"
										variant="filled"
										color="primary"
										value={project.link}
										onChange={handleChange}
										error={currErrors.link}
									/>
								</Grid>
								<Grid item xs={12}>
									<Grid
										container
										alignItems="center"
										justifyContent="center"
										spacing={2}
									>
										<Grid item xs={6}>
											<TextField
												fullWidth
												autoCapitalize="false"
												label="Payment Terms"
												name="terms"
												margin="dense"
												variant="filled"
												color="primary"
												value={project.terms}
												onChange={handleChange}
												error={currErrors.terms}
											/>
										</Grid>
										<Grid item xs={6}>
											<DatePicker
												variant="filled"
												TextFieldComponent={TextFieldDate}
												name="dueDate"
												label="Due Date"
												value={project.dueDate}
												error={currErrors.dueDate}
												onChange={date => handleChangeDate("dueDate", date)}
											/>
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={12} >
									<Grid
										container
										alignItems="center"
										justifyContent="center"
										spacing={2}
										style={{paddingLeft:12}}
									>
										<Grid item xs={8}>
											<FormControl >
												<RadioGroup aria-label="paymentMethod" name="paymentMethod" value={project.paymentMethod} onChange={handleChange} row>
												    <FormControlLabel value="wire" control={<StyledRadio />} label={<Typography className={classes.formControlLabel}>Wire</Typography>} />
												    <FormControlLabel value="paypal" control={<StyledRadio />} label={<Typography className={classes.formControlLabel}>PayPal</Typography>}/>
												    <FormControlLabel value="cheque" control={<StyledRadio />} label={<Typography className={classes.formControlLabel}>Cheque</Typography>} />
												    <FormControlLabel value="etransfer" control={<StyledRadio />} label={<Typography className={classes.formControlLabel}>E-transfer</Typography>}/>
											  	</RadioGroup>
										  	</FormControl>
										</Grid>
										<Grid item xs={1}>
											<Typography className={classes.formControlLabel}>Fees: </Typography>
										</Grid>
										<Grid item xs={3}>
											<FormControl>
												<RadioGroup aria-label="fees" name="fees" value={project.fees} onChange={handleChange} row>
												    <FormControlLabel value="yes" control={<StyledRadio  />} label={<Typography className={classes.formControlLabel}>Yes</Typography>} />
												    <FormControlLabel value="no" control={<StyledRadio  />} label={<Typography className={classes.formControlLabel}>No</Typography>}/>
												</RadioGroup>
										  	</FormControl>
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={12}>
									<FormControl style={{paddingLeft:12}}>
										<RadioGroup aria-label="invoice" name="invoiceType" value={project.invoiceType} onChange={handleChange} row>
										    <FormControlLabel value="invoiced" control={<StyledRadio  />} label={<Typography className={classes.formControlLabel}>Invoiced</Typography>} />
										    <FormControlLabel value="invoiceCompletion" control={<StyledRadio  />} label={<Typography className={classes.formControlLabel}>Invoice upon completion</Typography>}/>
										</RadioGroup>
								  	</FormControl>
								</Grid>
								<Grid item xs={12}>
									<TextField
										fullWidth
										multiline
										rows={6}
										autoCapitalize="false"
										label="Notes"
										name="notes"
										margin="dense"
										variant="filled"
										color="primary"
										value={project.notes}
										onChange={handleChange}
									/>
								</Grid>
							</Grid>
						</Grid>
						<Grid item lg={6} xs={12}>
							<Grid
								container
								alignItems="center"
								justifyContent="center"
								className={classes.formContainer}
							>
								<Grid item xs={12}>
									{project.deliverables.map((value, key) => (
										<Paper key={key} className={classes.paper} elevation={0}>
											<Typography className={classes.paperTitle}>
												Deliverable #{key + 1}
											</Typography>
											{project.deliverables.length !== 1 && (
												<Fab
													className={classes.deleteDeliverable}
													aria-label="new"
													onClick={_ => deleteDeliverable(key)}
													size="small"
												>
													<Clear />
												</Fab>
											)}
											<Grid
												container
												alignItems="center"
												justifyContent="center"
												spacing={1}
											>
												<Grid item xs={12}>
													<TextField
														fullWidth
														autoCapitalize="false"
														name="deliverables"
														value={value.name}
														onChange={handleChangeArray(key, "name")}
														label={"Deliverable Name"}
														margin="dense"
														variant="filled"
														color="primary"
														error={currErrors.deliverables && value.name === ""}
													/>
												</Grid>
												<Grid item xs={5}>
													<DatePicker
														variant="filled"
														TextFieldComponent={TextFieldDate}
														name="deliverables"
														label="Approval Start"
														onChange={date =>
															handleChangeArrayObject(
																"deliverables",
																key,
																"approval",
																"startDate",
																date
															)
														}
														value={value.approval.startDate}
														error={
															currErrors.deliverables &&
															value.approval.startDate === null
														}
													/>
												</Grid>
												<Grid item xs={4}>
													<DatePicker
														variant="filled"
														TextFieldComponent={TextFieldDate}
														name="deliverables"
														label="Approval End"
														onChange={date =>
															handleChangeArrayObject(
																"deliverables",
																key,
																"approval",
																"endDate",
																date
															)
														}
														value={value.approval.endDate}
													/>
												</Grid>
												<Grid item xs={3}>
													<TextField
														id="filled-select-currency"
														select
														fullWidth
														label="Reminder"
														margin="dense"
														name="deliverables"
														value={value.approval.reminder}
														onChange={e =>
															handleChangeArrayObject(
																"deliverables",
																key,
																"approval",
																"reminder",
																e.target.value
															)
														}
														variant="filled"
													>
														{remindersOptions()}
													</TextField>
												</Grid>
												<Grid item xs={5}>
													<DatePicker
														variant="filled"
														TextFieldComponent={TextFieldDate}
														name="deliverables"
														label="Post Start"
														value={value.post.startDate}
														error={
															currErrors.deliverables &&
															value.post.startDate === null
														}
														onChange={date =>
															handleChangeArrayObject(
																"deliverables",
																key,
																"post",
																"startDate",
																date
															)
														}
													/>
												</Grid>
												<Grid item xs={4}>
													<DatePicker
														variant="filled"
														TextFieldComponent={TextFieldDate}
														name="deliverables"
														label="Post End"
														value={value.post.endDate}
														onChange={date =>
															handleChangeArrayObject(
																"deliverables",
																key,
																"post",
																"endDate",
																date
															)
														}
													/>
												</Grid>
												<Grid item xs={3}>
													<TextField
														id="filled-select-currency"
														select
														fullWidth
														label="Reminder"
														margin="dense"
														name="post"
														value={value.post.reminder}
														onChange={e =>
															handleChangeArrayObject(
																"deliverables",
																key,
																"post",
																"reminder",
																e.target.value
															)
														}
														variant="filled"
													>
														{remindersOptions()}
													</TextField>
												</Grid>
											</Grid>
										</Paper>
									))}
									<Grid item xs={8} style={{ margin: "auto", marginTop: 10 }}>
										<Button
											variant="contained"
											onClick={_ =>
												addField(
													{
														approval: {
															reminder: "",
															startDate: null,
															endDate: null,
														},
														post: {
															reminder: "",
															startDate: null,
															endDate: null,
														},
														name: ""
													},
													"deliverables"
												)
											}
											fullWidth
										>
											Add Deliverable
										</Button>
									</Grid>
								</Grid>
								<Grid item xs={12}>
									<TextField
										id="exclusivity"
										fullWidth
										label="Exclusivity"
										margin="dense"
										name="exclusivity"
										value={project.exclusivity}
										onChange={handleChange}
										variant="filled"
									/>

								</Grid>
								<Grid item xs={12}>
									<Paper className={classes.paper} elevation={0}>
										<Typography className={classes.paperTitle}>
											Usage
										</Typography>
										<Grid container justifyContent="center">
											<FormGroup row >
												<FormControlLabel
													control={<Checkbox checked={project.organicSocial} onChange={handleChange} name="organicSocial" color="primary" />}
													label={<Typography className={classes.formControlLabel}>Organic Social</Typography>}
												/>
												<FormControlLabel
													control={<Checkbox checked={project.whitelistingPaidAds} onChange={handleChange} name="whitelistingPaidAds" color="primary" />}
													label={<Typography className={classes.formControlLabel}>Whitelisting/Paid Ads</Typography>}
												/>
												<FormControlLabel
													control={<Checkbox checked={project.web} onChange={handleChange} name="web" color="primary" />}
													label={<Typography className={classes.formControlLabel}>Web</Typography>}
												/>
												<FormControlLabel
													control={<Checkbox checked={project.email} onChange={handleChange} name="email" color="primary" />}
													label={<Typography className={classes.formControlLabel}>Email</Typography>}
												/>
												<FormControlLabel
													control={<Checkbox checked={project.print} onChange={handleChange} name="print" color="primary" />}
													label={<Typography className={classes.formControlLabel}>Print</Typography>}
												/>
											</FormGroup>
										</Grid>
									</Paper>

								</Grid>
								<Grid item xs={12}>
									<Paper className={classes.paper} elevation={0}>
										<Typography className={classes.paperTitle}>
											Attachments
										</Typography>
										<Grid
											container
											alignItems="center"
											justifyContent="center"
											spacing={1}
										>
											<List dense className={classes.deliList}>
												{project.attachments.length === 0 ? (
													<ListItem className={classes.deliItem}>
														<ListItemText primary={"No attachments"} />
													</ListItem>
												) : (
													project.attachments.map((value, key) => (
														<ListItem key={key} className={classes.deliItem}>
															<ListItemText
																primary={
																	<a
																		href={value}
																		rel="noopener noreferrer"
																		target="_blank"
																		style={{
																			color: "black",
																			fontWeight: "bold"
																		}}
																	>
																		{decodeURIComponent(
																			value
																				.split("%2F")[1]
																				.split("?alt=media")[0]
																		)}
																	</a>
																}
															/>
															<ListItemSecondaryAction>
																<IconButton
																	edge="end"
																	aria-label="delete"
																	size="small"
																	onClick={_ => deleteAttachment(key)}
																>
																	<Clear />
																</IconButton>
															</ListItemSecondaryAction>
														</ListItem>
													))
												)}
											</List>
											<Grid item xs={12}>
												<input
													accept="*"
													style={{ display: "none" }}
													id="project-attachments"
													multiple
													type="file"
													onChange={uploadAttachment}
												/>
												<label htmlFor="project-attachments">
													<Button
														disabled={uploading}
														fullWidth
														variant="contained"
														component="span"
														startIcon={
															uploading ? (
																<CircularProgress size={16} />
															) : (
																<CloudUpload />
															)
														}
													>
														Upload attachments
													</Button>
												</label>
											</Grid>
										</Grid>
									</Paper>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12}>
							<Grid
								container
								alignItems="center"
								justifyContent="center"
								style={{
									maxWidth: "90.5%",
									margin: "auto"
								}}
							>
								<Grid item xs={12}>
									<TextField
										fullWidth
										multiline
										rows={8}
										autoCapitalize="false"
										label="Guidelines"
										name="guidelines"
										margin="dense"
										variant="filled"
										color="primary"
										value={project.guidelines}
										onChange={handleChange}
										error={currErrors.guidelines}
									/>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button onClick={_ => props.setOpen(false)}>Close</Button>
					<div>
						<Button
							variant="contained"
							className={classes.button}
							onClick={save}
							disabled={saving}
						>
							{mode === "new" ? "Create" : "save"}
						</Button>
						{saving && (
							<CircularProgress
								size={24}
								className={classes.buttonProgressFinish}
							/>
						)}
					</div>
				</DialogActions>
			</Dialog>
		</MuiPickersUtilsProvider>
	);
};

function mapDispatchToProps(dispatch) {
	return {
		addProject: (project, creator, callback) =>
			dispatch(addProject(project, creator, callback)),
		updateProject: (project, creator, callback) =>
			dispatch(updateProject(project, creator, callback))
	};
}

function mapStateToProps(state) {
	return {
		user: state.user
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(NewProject);
