import { connectedRef, analytics } from "../data/Firebase";
import store from "../Store";

export function appInitializationSuccess() {
	if (
		(store.getState().app.connectionStatus && store.getState().app.userFetch) ||
		(store.getState().app.connectionStatus && store.getState().user === null)
	) {
		analytics.logEvent("app_init_success");
		return {
			type: "APP_INIT",
			payload: true
		};
	}
	return {
		type: "APP_INIT",
		payload: false
	};
}

export function loginAlertClose() {
	return {
		type: "LOGIN_ALERT_CLOSE"
	};
}

export function passwordResetAlertClose() {
	return {
		type: "RESET_PASSWORD_ALERT_CLOSE"
	};
}

export function appInitializationLoading() {
	return {
		type: "APP_INIT",
		payload: false
	};
}

export function ledgersLoading(status) {
	return {
		type: "LEDGERS_LOADING",
		payload: status
	};
}

export function ledgerLoading(status) {
	return {
		type: "LEDGER_DETAILS_LOADING",
		payload: status
	};
}

export function fetchData(uid) {
	return dispatch => {};
}

export function online() {
	return {
		type: "APP_ONLINE"
	};
}

export function offline() {
	return {
		type: "APP_OFFLINE"
	};
}

connectedRef.on("value", function(snap) {
	if (store.getState().app.initialized === false) {
		store.dispatch(appInitializationLoading());
	}
	if (snap.val() === true) {
		store.dispatch(online());
		store.dispatch(appInitializationSuccess());
	} else {
		store.dispatch(offline());
	}
});
