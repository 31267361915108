import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "@reach/router";
import { Helmet } from "react-helmet-async";
import numeral from "numeral";

import { fetchCreators } from "../actions";
import NewCreator from "./NewCreator/NewCreator";

import { makeStyles } from "@material-ui/core/styles";
import ContentLoader from "react-content-loader";

import {
	Paper,
	IconButton,
	TextField,
	Button,
	Fade,
	CardActions,
	Card,
	CardContent,
	CardMedia,
	Typography,
	Grid,
	Chip,
	Fab,
	Box,
	Popper,
	ClickAwayListener,
	FormControlLabel,
	Switch,
	CardHeader
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import {
	Search as SearchIcon,
	FilterList,
	Add,
	Done
} from "@material-ui/icons";
import { isCreatorFound} from "./utils";

const useStyles = makeStyles(theme => ({
	root: {
		padding: "2px 4px",
		display: "flex",
		alignItems: "center",
		backgroundColor: "transparent",
		boxShadow: "none",
		width: "100%",
		marginTop: theme.spacing(3)
	},
	input: {
		marginLeft: theme.spacing(1),
		flex: 1
	},
	searchIcon: {
		padding: 10,
		fontSize: "40px"
	},
	divider: {
		height: 28,
		margin: 4
	},
	addButton: {
		backgroundColor: "#cd9988",
		marginTop: 30,
		marginLeft: 16,
		color: "white",
		height: 42,
		minWidth: 42,
		width: 42,
		"&:hover": {
			backgroundColor: "#e8ad9c"
		}
	},
	container: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(3)
	},
	media: {
		height: 140
	},
	chip: {
		marginRight: 5
	},
	searchContainer: {
		display: "flex"
	},
	popper: {
		zIndex: 1,
		'&[x-placement*="bottom"] $arrow': {
			top: 0,
			left: 0,
			marginTop: "-0.9em",
			width: "3em",
			height: "1em",
			"&::before": {
				borderWidth: "0 1em 1em 1em",
				borderColor: `transparent transparent ${theme.palette.background.paper} transparent`
			}
		},
		'&[x-placement*="top"] $arrow': {
			bottom: 0,
			left: 0,
			marginBottom: "-0.9em",
			width: "3em",
			height: "1em",
			"&::before": {
				borderWidth: "1em 1em 0 1em",
				borderColor: `${theme.palette.background.paper} transparent transparent transparent`
			}
		},
		'&[x-placement*="right"] $arrow': {
			left: 0,
			marginLeft: "-0.9em",
			height: "3em",
			width: "1em",
			"&::before": {
				borderWidth: "1em 1em 1em 0",
				borderColor: `transparent ${theme.palette.background.paper} transparent transparent`
			}
		},
		'&[x-placement*="left"] $arrow': {
			right: 0,
			marginRight: "-0.9em",
			height: "3em",
			width: "1em",
			"&::before": {
				borderWidth: "1em 0 1em 1em",
				borderColor: `transparent transparent transparent ${theme.palette.background.paper}`
			}
		}
	},
	arrow: {
		position: "absolute",
		fontSize: 7,
		width: "3em",
		height: "3em",
		"&::before": {
			content: '""',
			margin: "auto",
			display: "block",
			width: 0,
			height: 0,
			borderStyle: "solid"
		}
	},
	filterButtonOpen: {
		backgroundColor: "rgba(205, 153, 136, 0.04)"
	},
	filterPaper: {
		padding: theme.spacing(2),
		maxWidth: 260
	},
	filterChip: {
		margin: 2
	},
	filterChipSelected: {
		margin: 2,
		backgroundColor: grey[400]
	},
	descendingForm: {
		flex: 1,
		textAlign: "right",
		display: "block",
		marginRight: 0,
		marginLeft: 0,
		marginTop: -3
	},
	filterTitle: {
		display: "flex"
	},
	creatorDisabled: {
		position: "absolute",
		top: 0,
		right: 0,
		left: 0,
		bottom: 0,
		backgroundColor: "rgba(205, 153, 136, 0.1)"
	},
	card: {
		position: "relative"
	}
}));

const placeHolders = [
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	"",
	""
];

const orderByList = [
	{ label: "Followers", field: "followers" },
	{ label: "Firstname", field: "firstname" },
	{ label: "Lastname", field: "lastname" },
	{ label: "Name", field: "name" },
	{ label: "Avg. Likes", field: "likes" },
	{ label: "Male %", field: "male" },
	{ label: "Female %", field: "female" },
	{ label: "Engagement %", field: "engagement" },
	{ label: "Avg. Post Impr.", field: "postImpressions" }
];

const ManageCreator = props => {
	const classes = useStyles();
	const { fetchCreators } = props;
	const [openNew, setOpenNew] = useState(false);
	const [search, setSearch] = useState("");
	const [anchorEl, setAnchorEl] = useState(null);
	const [open, setOpen] = useState(false);
	const [placement, setPlacement] = useState();
	const [arrowRef, setArrowRef] = useState(null);
	const [orderBy, setOrderBy] = useState({});
	const [descending, setDescending] = useState(false);

	useEffect(
		_ => {
			fetchCreators();
		},
		[fetchCreators]
	);

	const handleClick = newPlacement => event => {
		setAnchorEl(event.currentTarget);
		setOpen(prev => placement !== newPlacement || !prev);
		setPlacement(newPlacement);
	};

	const setTitle = _ => {
		return (
			<Helmet>
				<title>Manage Creators</title>
			</Helmet>
		);
	};

	const comparatorDescending = (a,b) =>{
		return descending
			? a[orderBy.field] > b[orderBy.field]
				? -1
				: 1
			: a[orderBy.field] < b[orderBy.field]
				? -1
				: 1
	}

	const externalCreators = props.creators.filter(creator=> creator.external);
	const internalCreators = props.creators.filter(creator=> !creator.external);

	const creatorCard = (creator, key) => (
		<Fade key={key} in={true}>
			<Grid item lg={3} md={4} sm={6} xs={12}>
				<Card className={classes.card} elevation={2}>
					<div
						style={{
							display: creator.status === "active" ? "none" : "block"
						}}
						className={classes.creatorDisabled}
					></div>
					<CardMedia
						className={classes.media}
						image={creator.photos[0]}
						title={creator.firstname + " " + creator.lastname}
					/>
					<CardHeader
						title={creator.firstname + " " + creator.lastname}
						style={{ paddingBottom: 0 }}
						subheader={
							<Typography variant="overline">
								<b>{numeral(creator?.followers).format("0.0a")}</b> followers
							</Typography>
						}
					/>
					<CardContent style={{ paddingTop: 5 }}>
						<Typography component="div">
							{creator.status === "active" ? (
								creator.managers.map((manager, key) => (
									<Chip
										className={classes.chip}
										key={key}
										size="small"
										label={manager.fullName}
										onClick={_ => null}
									/>
								))
							) : (
								<Chip
									className={classes.chip}
									size="small"
									label={"Creator Archived"}
									onClick={_ => null}
								/>
							)}
						</Typography>
					</CardContent>
					<CardActions style={{ padding: 16, paddingTop: 0 }}>
						<Button
							size="small"
							className={classes.buttonAdd}
							variant="contained"
							component={Link}
							to={"/house/" + creator.id}
						>
							Manage
						</Button>
						<Button
							size="small"
							className={classes.buttonAdd}
							variant="contained"
							component={Link}
							to={"/profile/" + creator.id}
						>
							Profile
						</Button>
					</CardActions>
				</Card>
			</Grid>
		</Fade>
	);

	return (
		<>
			{setTitle()}
			<Fade
				in={true}
				mountOnEnter
				unmountOnExit
				{...(true ? { timeout: 500 } : {})}
			>
				<Box className={classes.searchContainer}>
					<Paper component="form" className={classes.root} elevation={2}>
						<SearchIcon className={classes.searchIcon} />
						<TextField
							autoCorrect="false"
							autoComplete="false"
							autoCapitalize="false"
							className={classes.input}
							value={search}
							size="medium"
							onChange={e => setSearch(e.target.value)}
							placeholder="Search Creators"
							inputProps={{ "aria-label": "search creators" }}
						/>

						<IconButton
							aria-label="filter"
							className={open ? classes.filterButtonOpen : ""}
							onClick={handleClick("bottom")}
						>
							<FilterList />
						</IconButton>
						<Popper
							open={open}
							anchorEl={anchorEl}
							placement={placement}
							className={classes.popper}
							transition
							modifiers={{
								flip: {
									enabled: true
								},
								preventOverflow: {
									enabled: true,
									boundariesElement: "scrollParent"
								},
								arrow: {
									enabled: true,
									element: arrowRef
								}
							}}
						>
							{({ TransitionProps }) => (
								<>
									<Fade {...TransitionProps}>
										<span className={classes.arrow} ref={setArrowRef} />
									</Fade>
									<ClickAwayListener onClickAway={_ => setOpen(false)}>
										<Fade {...TransitionProps}>
											<Paper className={classes.filterPaper} elevation={3}>
												<Typography
													variant="caption"
													className={classes.filterTitle}
												>
													Order by
													<FormControlLabel
														className={classes.descendingForm}
														variant="caption"
														control={
															<Switch
																size="small"
																color="primary"
																checked={descending}
																onChange={_ => setDescending(!descending)}
															/>
														}
														label={
															<Typography variant="caption">Desc.</Typography>
														}
													/>
												</Typography>
												<Box>
													{orderByList.map((item, key) => (
														<Chip
															className={
																orderBy === item
																	? classes.filterChipSelected
																	: classes.filterChip
															}
															key={key}
															size="small"
															label={item.label}
															icon={orderBy === item ? <Done /> : null}
															onClick={_ =>
																orderBy === item
																	? setOrderBy({})
																	: setOrderBy(item)
															}
														/>
													))}
												</Box>
											</Paper>
										</Fade>
									</ClickAwayListener>
								</>
							)}
						</Popper>
					</Paper>
					<Fab
						className={classes.addButton}
						aria-label="new"
						onClick={_ => setOpenNew(true)}
						size="medium"
					>
						<Add />
					</Fab>
				</Box>
			</Fade>
			<Grid container className={classes.container} spacing={2}>
				{props.creatorsLoading ? (
					placeHolders.map((item, key) => (
						<Grid item lg={3} md={4} sm={6} xs={12} key={key}>
							<ContentLoader
								speed={1}
								interval={0.1}
								width="100%"
								height={300}
								backgroundColor="#f3f3f3"
								foregroundColor="#ecebeb"
							>
								<rect x="19" y="163" rx="2" ry="2" width="186" height="20" />
								<rect x="19" y="192" rx="2" ry="2" width="140" height="10" />
								<rect x="2" y="2" rx="2" ry="2" width="100%" height="147" />
								<rect x="19" y="216" rx="10" ry="10" width="131" height="22" />
								<rect x="25" y="253" rx="2" ry="2" width="93" height="33" />
								<rect x="135" y="253" rx="2" ry="2" width="93" height="33" />
								<rect x="165" y="216" rx="10" ry="10" width="100" height="22" />
							</ContentLoader>
						</Grid>
					))
				) : props.creators
						.filter(
							creator => isCreatorFound(creator, search)
						)
						.sort(comparatorDescending)
						.sort((a, b) => (a.status < b.status ? -1 : 1)).length === 0 ? (
					<Fade in={true}>
						<Grid item xs={12}>
							<Typography
								align="center"
								color="textSecondary"
								style={{ paddingTop: 100 }}
							>
								No creators to show
							</Typography>
						</Grid>
					</Fade>
				) : (
					<Grid container className={classes.container} spacing={2}>
						{internalCreators
							.filter(
								creator => isCreatorFound(creator, search)
							)
							.sort(comparatorDescending)
							.filter(creator => creator.status === "active")
							.map((creator, key) => creatorCard(creator, key))
						}
						{externalCreators.filter(creator => creator.status === "active").length > 0 &&(
							<Grid item xs={12}>
								<Typography
									variant="h6"
									style={{ paddingTop: 40 }}
								>
									External Creators
								</Typography>
							</Grid>
						)}
						<Grid container className={classes.container} spacing={2}>
							{externalCreators
								.filter(
									creator => isCreatorFound(creator, search)
								)
								.sort(comparatorDescending)
								.filter(creator => creator.status === "active")
								.map((creator, key) => creatorCard(creator, key))
							}
						</Grid>
					</Grid>
				)}
			</Grid>
			{!props.creatorsLoading &&
				props.creators
					.filter(
						creator => isCreatorFound(creator, search)
					)
					.sort(comparatorDescending)
					.filter(creator => creator.status === "archived").length > 0 && (
					<Grid container className={classes.container} spacing={2}>
						<Grid item xs={12}>
							<Typography variant="h6" style={{ fontSize: "1.45rem" }}>
								Archived
							</Typography>
						</Grid>
						{
							<Grid container className={classes.container} spacing={2}>
								{internalCreators
									.filter(
										creator => isCreatorFound(creator, search)
									)
									.sort(comparatorDescending)
									.filter(creator => creator.status === "archived")
									.map((creator, key) => creatorCard(creator, key))
								}
								{externalCreators.filter(creator => creator.status === "archived").length > 0 &&(
									<Grid item xs={12}>
										<Typography
											variant="h6"
											style={{ paddingTop: 40 }}
										>
											External Creators
										</Typography>
									</Grid>
								)}
								<Grid container className={classes.container} spacing={2}>
									{externalCreators
										.filter(
											creator => isCreatorFound(creator, search)
										)
										.sort(comparatorDescending)
										.filter(creator => creator.status === "archived")
										.map((creator, key) => creatorCard(creator, key))
									}
								</Grid>
							</Grid>
						}
					</Grid>
				)}
			<NewCreator open={openNew} setOpen={setOpenNew} />
		</>
	);
};

function mapDispatchToProps(dispatch) {
	return { fetchCreators: _ => dispatch(fetchCreators()) };
}

function mapStateToProps(state) {
	return {
		creators: state.creators,
		creatorsLoading: state.app.creatorsLoading
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageCreator);
