import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import reducers from "./reducers";
import { composeWithDevTools } from "redux-devtools-extension";

const middleware = [thunk];

export default createStore(
	reducers,
	{
		user: null,
		creators: [],
		managers: [],
		app: {
			initialized: false,
			loginError: null,
			connectionStatus: false,
			userFetch: false,
			resetingPassword: false,
			resetPasswordEmailStatus: "none",
			creatorsLoading: false,
			addingCreator: false
		}
	},
	composeWithDevTools(applyMiddleware(...middleware))
);
