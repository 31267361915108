import React from "react";
import { Grid, TextField, InputAdornment, MenuItem, Checkbox, FormControlLabel } from "@material-ui/core";
import { AlternateEmail } from "@material-ui/icons";
import numeral from "numeral";
import countries from "../../data/Countries";
import currencies from "../../data/Currencies";

const NewCreatorStep1 = props => {
	const { handleChange, handleChangeObject, creator, classes } = props;

	return (
		<Grid
			container
			alignItems="center"
			justifyContent="center"
			className={classes.formContainer}
		>
			<Grid item xs={12}>
				<Grid container alignItems="center" justifyContent="center" spacing={2}>
					<Grid item xs={6}>
						<TextField
							fullWidth
							autoCapitalize="false"
							name="firstname"
							value={creator.firstname}
							onChange={handleChange}
							label="Firstname"
							margin="dense"
							variant="filled"
							error={props.errors.firstname}
							color="primary"
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							fullWidth
							autoCapitalize="false"
							label="Lastname"
							name="lastname"
							margin="dense"
							variant="filled"
							color="primary"
							value={creator.lastname}
							onChange={handleChange}
							error={props.errors.lastname}
						/>
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<TextField
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<AlternateEmail fontSize="small" />
							</InputAdornment>
						)
					}}
					fullWidth
					autoCapitalize="false"
					label="Instagram"
					name="instagram"
					margin="dense"
					variant="filled"
					color="primary"
					value={creator.instagram}
					onChange={handleChange}
					error={props.errors.instagram}
				/>
			</Grid>
			<Grid item xs={12}>
				<TextField
					fullWidth
					autoCapitalize="false"
					name="followers"
					label="Followers"
					margin="dense"
					variant="filled"
					color="primary"
					value={numeral(creator.followers).value()}
					onChange={handleChange}
					error={props.errors.followers}
				/>
			</Grid>
			<Grid item xs={12}>
				<TextField
					fullWidth
					autoCapitalize="false"
					label="Email"
					name="email"
					margin="dense"
					variant="filled"
					color="primary"
					value={creator.email}
					onChange={handleChange}
					error={props.errors.email}
				/>
			</Grid>
			<Grid item xs={12}>
				<TextField
					fullWidth
					autoCapitalize="false"
					label="Phone number"
					name="phone"
					margin="dense"
					variant="filled"
					color="primary"
					value={creator.phone}
					onChange={handleChange}
					error={props.errors.phone}
				/>
			</Grid>
			<Grid item xs={12}>
				<TextField
					fullWidth
					autoCapitalize="false"
					label="Street address"
					name="street"
					margin="dense"
					variant="filled"
					color="primary"
					value={creator.address.street}
					onChange={handleChangeObject("address")}
					error={props.errors.address}
				/>
			</Grid>
			<Grid item xs={12}>
				<Grid container alignItems="center" justifyContent="center" spacing={2}>
					<Grid item xs={6}>
						<TextField
							fullWidth
							autoCapitalize="false"
							name="zip"
							value={creator.address.zip}
							onChange={handleChangeObject("address")}
							label="ZIP code"
							margin="dense"
							variant="filled"
							error={props.errors.address}
							color="primary"
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							fullWidth
							autoCapitalize="false"
							label="City"
							name="city"
							margin="dense"
							variant="filled"
							color="primary"
							value={creator.address.city}
							onChange={handleChangeObject("address")}
							error={props.errors.address}
						/>
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<Grid container alignItems="center" justifyContent="center" spacing={2}>
					<Grid item xs={6}>
						<TextField
							fullWidth
							autoCapitalize="false"
							label="Province/State"
							name="state"
							margin="dense"
							variant="filled"
							color="primary"
							value={creator.address.state}
							onChange={handleChangeObject("address")}
							error={props.errors.address}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							id="filled-select-country"
							select
							fullWidth
							label="Country"
							margin="dense"
							name="country"
							value={creator.address.country}
							onChange={handleChangeObject("address")}
							variant="filled"
							error={props.errors.address}
						>
							{countries.map(option => (
								<MenuItem key={option.name} value={option.name}>
									{option.name}
								</MenuItem>
							))}
						</TextField>
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<Grid container alignItems="center" justifyContent="center" spacing={2}>
					<Grid item xs={6}>
						<TextField
							id="filled-select-currency"
							select
							fullWidth
							label="Currency"
							margin="dense"
							name="currency"
							value={creator.currency}
							onChange={handleChange}
							variant="filled"
							error={props.errors.currency}
						>
							{currencies.map(option => (
								<MenuItem key={option.code} value={option.code}>
									{option.sign} {option.code}
								</MenuItem>
							))}
						</TextField>
					</Grid>
					<Grid item xs={6}>
						<FormControlLabel
					        control={<Checkbox checked={creator.external} onChange={handleChange} name="external" color="primary" />}
					        label="External"
					      />
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default NewCreatorStep1;
