export default (state = [], action) => {
	switch (action.type) {
		case "SIGN_IN_SUCCESS":
			return action.payload;
		case "USER_FETCH_SUCCESS":
			state = { ...state };
			delete state.googleCode;
			return { ...state, ...action.payload };
		case "SIGN_OUT_SUCCESS":
			return null;
		default:
			return state;
	}
};
