import axios from "axios";
import numeral from "numeral";
import currencies from "./data/Currencies";

export const getCurrenciesRates = (baseCurrency) => {
	let currencyCodes = baseCurrency === "EUR"? "CAD,USD,GBP" : "CAD,USD,GBP,EUR";
	let url = 'https://exchange-rates.abstractapi.com/v1/live/'; // 500 requests/month
	let api_key = "22bd642a4c4d4406b84a32dadf1b4f8e"
  	return axios.get(`${url}?api_key=${api_key}&base=${baseCurrency}&target=${currencyCodes}`)
}

export const computeTotalCurrency = (currentFilterProjects, baseCurrency, newCurrencyRates) => {
	let total = 0;
	currentFilterProjects.forEach(project => {
		let budget = numeral(project.budget).value();
		if(project.currency !== baseCurrency){
			budget = budget / (newCurrencyRates[project.currency]);
		}
		total += budget;
	});
	return total;
};

export const getCurrencySign = (code) =>{
	let currency = currencies.find(currency => currency.code === code);
	return currency.sign;
}
