import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { navigate } from "@reach/router";
import { Helmet } from "react-helmet-async";
import numeral from "numeral";
import { difference } from "lodash";

import { fetchCreators, updateCreatorWithCallback, fetchProjectsList } from "../actions";
import { deleteUserCreator } from "../actions/";
import { getCurrencySign } from "../helper";
import currencies from "../data/Currencies";

import { makeStyles, useTheme } from "@material-ui/core/styles";

import {
	Grid,
	Typography,
	Divider,
	List,
	ListItem,
	ListItemText,
	ListItemSecondaryAction,
	Fab,
	Dialog,
	DialogTitle,
	DialogContent,
	TextField,
	Button,
	DialogActions,
	IconButton,
	MenuItem,
	Chip,
	Box,
	Tabs,
	Tab,
	AppBar,
	useMediaQuery,
	CircularProgress
} from "@material-ui/core";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import { Skeleton } from "@material-ui/lab";
import { grey } from "@material-ui/core/colors";
import { Share, Archive, Edit, Add, Clear } from "@material-ui/icons";

import EditCreator from "./EditCreator";
import ShareCreator from "./ShareCreator";
import Projects from "./Project/Projects";
import Calendar from "./Calendar";
import Payments from "./Payments/Payments";

const useStyles = makeStyles(theme => ({
	dividerTop: {
		height: 10,
		marginLeft: 10,
		marginRight: 10,
		backgroundColor: grey[700],
		display: "inline-block"
	},
	addButton: {
		backgroundColor: "#cd9988",
		color: "white",
		height: 42,
		minWidth: 42,
		width: 42,
		"&:hover": {
			backgroundColor: "#e8ad9c"
		}
	},
	container: {
		marginTop: theme.spacing(4)
	},
	media: {
		height: 140
	},
	chip: {
		marginRight: 5
	},
	speedDialActionStyle: {
		backgroundColor: "#cd9988",
		color: "white",
		"&:hover": {
			backgroundColor: "#e8ad9c"
		}
	},
	SpeedDial: {
		position: "absolute",
		marginTop: 10,
		right: 0
	},
	rateList: {
		maxWidth: 300,
		backgroundColor: grey[200]
	},
	addRateButton: {
		width: 32,
		height: 32,
		maxHeight: 32,
		minHeight: 32,
		right: 0,
		top: 0,
		position: "absolute",
		marginRight: -16,
		marginTop: -16,
		backgroundColor: "#cd9988",
		color: "white",
		"&:hover": {
			backgroundColor: "#e8ad9c"
		}
	},
	rateItem: {
		paddingTop: 0,
		paddingBottom: 0
	},
	button: {
		backgroundColor: "#cd9988",
		color: "white",
		"&:hover": {
			backgroundColor: "#e8ad9c"
		}
	},
	tabIndicator: {
		backgroundColor: "#cd9988"
	},
	tabLabel: {
		color: grey[700]
	},
	tabLabelSelected: {
		color: grey[900]
	},
	buttonProgressRate: {
		position: "absolute",
		right: "30px",
		bottom: "13px"
	}
}));

function a11yProps(index) {
	return {
		id: `scrollable-auto-tab-${index}`,
		"aria-controls": `scrollable-auto-tabpanel-${index}`,
		style: { minWidth: "unset", padding: "6px 32px" }
	};
}

function TabPanel(props) {
	const { children, value, index } = props;

	return (
		<Box style={{ position: "relative" }}>
			{value === index && <Box p={3}>{children}</Box>}
		</Box>
	);
}

const ConfirmDialog = (props) => {
  const { title, children, open, setOpen, onConfirm } = props;
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="confirm-dialog"
    >
      <DialogTitle id="confirm-dialog">{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => setOpen(false)}
          color="default"
        >
          No
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            setOpen(false);
            onConfirm();
          }}
          color="default"
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const CreatorHouse = props => {
	const classes = useStyles();
	const [creator, setCreator] = useState(null);
	const [creatorEdit, setCreatorEdit] = useState(null);
	const [menuOpen, setMenuOpen] = useState(false);
	const [confirmOpen, setConfirmOpen] = useState(false);
	const [editOpen, setEditOpen] = useState(false);
	const [rateSaving, setRateSaving] = useState(false);
	const [shareOpen, setShareOpen] = useState(false);
	const [addRateOpen, setAddRateOpen] = useState(false);
	const [tab, setTab] = useState(0);
	const isMobile = useMediaQuery(useTheme().breakpoints.down("sm"));

	const { fetchCreators, fetchProjectsList, projects, creators, creatorId, user } = props;
	const prevCreator = usePrevious(creator);

	useEffect(
		_ => {
			const creatorIdQuery = user.role === "creator" ? user.creator : creatorId;
			if (
				creators.length === 0 ||
				typeof creators.find(creator => creator.id === creatorIdQuery)
					.projects === "undefined"
			) {
				fetchCreators();
			} else if (
				creator === null ||
				creator.projects.length !==
					creators.find(creator => creator.id === creatorIdQuery).projects
						.length ||
				creator.events.length !==
					creators.find(creator => creator.id === creatorIdQuery).events
						.length ||
				difference(
					creator.projects,
					creators.find(creator => creator.id === creatorIdQuery).projects
				).length !== 0
			) {
				setCreator({
					...creators.find(creator => creator.id === creatorIdQuery)
				});
				setCreatorEdit({
					...creators.find(creator => creator.id === creatorIdQuery)
				});
			}
		},
		[fetchCreators, creators, creator, creatorId, user]
	);

	useEffect( _ => {
		if(creator) {
			if(!prevCreator || prevCreator.id !== creator.id ){
				fetchProjectsList(creator);
			}
		}

	});

	function usePrevious(value) {
	  const ref = useRef();

	  useEffect(() => {
	    ref.current = value;
	  }, [value]);

	  return ref.current;
	}



	const setTitle = _ => {
		return (
			<Helmet>
				<title>
					{creator === null
						? "Creator"
						: Object.keys(creator).length === 0
						? "Creator"
						: creator.firstname}
					's house
				</title>
			</Helmet>
		);
	};

	const handleChangeTab = (event, newValue) => {
		setTab(newValue);
	};

	const handleChangeRate = (index, attribute) => e => {
		let newArray = creatorEdit[e.target.name];
		const newObject = {
			name: newArray[index].name,
			amount: newArray[index].amount,
			currency: newArray[index].currency
		};
		newObject[attribute] = e.target.value;
		newArray[index] = newObject;
		setCreatorEdit({ ...creatorEdit, [e.target.name]: newArray });
	};

	const deleteRate = index => {
		let newArray = [...creatorEdit.rates];
		newArray.splice(index, 1);
		setCreatorEdit({ ...creatorEdit, rates: newArray });
	};

	const saveRates = _ => {
		setRateSaving(true);
		props.updateCreator(creatorEdit, _ => {
			setCreator({ ...creator, rates: [...creatorEdit.rates] });
			setRateSaving(false);
			setAddRateOpen(false);
		});
	};

	const archiveCreator = _ => {
		const status = creator.status === "archived" ? "active" : "archived";
		setCreator({ ...creator, status: status });
		setCreatorEdit({ ...creator, status: status });
		props.updateCreator({ ...creator, status: status }, _ => null);
	};

	const isManager = (props.user !== null && props.user.role === "head-manager") ||
			  (props.user !== null &&
					props.user.role === "manager" &&
					creator.managers.some(val => val.uid === props.user.uid));

	const creatorExist = creator !== null && Object.keys(creator).length !== 0;


	const speedDialActions = [
		{
			label: "Edit",
			icon: <Edit />,
			action: _ => {
				setEditOpen(true);
				setMenuOpen(false);
			}
		},
		{
			label: "Profile",
			icon: <Share />,
			action: _ => navigate("/profile/" + creator.id)
		},
		{
			label: "Share",
			icon: <Share />,
			action: _ => {
				setShareOpen(true);
				setMenuOpen(false);
			}
		},
		creatorExist && isManager ?
		{
			label: creator.status === "active" ? "Archive" : "Restore",
			icon: <Archive />,
			action: _ => {
				setMenuOpen(false);
				archiveCreator();
			}
	  }: null,
	  creatorExist && isManager ?
		{
		  	label: "Delete",
			icon: <Edit />,
			action: _ => {
				setConfirmOpen(true);
			}
		}: null,
	];

	return (
		<>
			{setTitle()}
			<Grid container className={classes.container} spacing={2}>
				{creator === null ? (
					<>
						<Grid item sm={4} xs={12} style={{ paddingTop: 20 }}>
							<Skeleton animation="wave" variant="text" width={200} />
							<Skeleton animation="wave" variant="text" width={200} />
							<Skeleton animation="wave" variant="text" width={200} />
							<Skeleton animation="wave" variant="text" width={200} />
						</Grid>
						<Grid item sm={4} xs={12} style={{ textAlign: "center" }}>
							<Typography
								variant="h4"
								style={{ textTransform: "uppercase", textAlign: "center" }}
							>
								<Skeleton animation="wave" />
							</Typography>
							<Typography
								style={{ textTransform: "uppercase", textAlign: "center" }}
							>
								<Skeleton
									width={300}
									style={{ margin: "auto" }}
									variant="text"
								/>
							</Typography>
							<Typography
								style={{ marginTop: 30 }}
								component="div"
								variant="h4"
							>
								<Skeleton
									style={{
										display: "inline-block",
										marginRight: 5,
										verticalAlign: "bottom"
									}}
									width={100}
								/>
								<Skeleton
									animation="wave"
									style={{ display: "inline-block", verticalAlign: "bottom" }}
									width={100}
								/>
							</Typography>
							<Typography
								variant="body2"
								style={{ textTransform: "uppercase" }}
							>
								<Skeleton animation="wave" variant="text" />
							</Typography>
							<Typography
								variant="body2"
								style={{ textTransform: "uppercase" }}
								gutterBottom
							>
								<Skeleton
									variant="text"
									width={300}
									style={{ margin: "auto" }}
								/>
							</Typography>
							<Typography
								variant="body2"
								style={{ textTransform: "uppercase" }}
								gutterBottom
							>
								<Skeleton
									variant="text"
									width={130}
									style={{ margin: "auto" }}
								/>
							</Typography>
						</Grid>
						<Grid item sm={4} xs={12} style={{ position: "relative" }}>
							<Skeleton
								variant="circle"
								width={42}
								height={42}
								style={{ position: "absolute", right: 16 }}
							/>
						</Grid>
						<Grid item xs={12} style={{ marginTop: 30 }}>
							<Skeleton animation="wave" variant="rect" height={500} />
						</Grid>
					</>
				) : Object.keys(creator).length === 0 ? (
					<Typography
						variant="h5"
						style={{ width: "100%", textAlign: "center" }}
					>
						No creator found or you don't have access to it!
					</Typography>
				) : (
					<>
						<Grid item sm={4} xs={12}>
							<List dense className={classes.rateList}>
								<Typography
									variant="subtitle2"
									style={{ paddingLeft: 16, paddingBottom: 8 }}
								>
									Rates
									<Fab
										className={classes.addRateButton}
										aria-label="new"
										onClick={_ => {
											setAddRateOpen(true);
											if (creator.rates.length === 0) {
												let rates = [...creatorEdit.rates];
												rates.push({ name: "", amount: "", currency:creator.currency });
												setCreatorEdit({ ...creatorEdit, rates: rates });
											}
										}}
										size="small"
									>
										<Add />
									</Fab>
								</Typography>
								{creator.rates.length === 0 ? (
									<ListItem className={classes.rateItem}>
										<ListItemText primary="No rates yet" />
									</ListItem>
								) : (
									creator.rates.map((rate, key) => (
										<ListItem key={key} className={classes.rateItem}>
											<ListItemText primary={rate.name} />
											<ListItemSecondaryAction>
												<Typography variant="caption">
													{rate.currency} {getCurrencySign(rate.currency)}{rate.amount}
												</Typography>
											</ListItemSecondaryAction>
										</ListItem>
									))
								)}
							</List>
						</Grid>
						<Grid item sm={4} xs={12} style={{ textAlign: "center" }}>
							{creator.status === "archived" && (
								<Typography gutterBottom component="div">
									<Chip
										className={classes.chip}
										size="small"
										label={"Creator Archived"}
										onClick={_ => null}
									/>
								</Typography>
							)}
							<Typography
								variant="h4"
								style={{ textTransform: "uppercase", textAlign: "center" }}
							>
								{creator.firstname + " " + creator.lastname}
							</Typography>
							<Typography
								variant="body2"
								component="span"
								style={{ textTransform: "uppercase" }}
							>
								@<b>{creator.instagram}</b>
							</Typography>
							<Divider className={classes.dividerTop} orientation="vertical" />
							<Typography
								variant="body2"
								component="span"
								style={{ textTransform: "uppercase" }}
								gutterBottom
							>
								<b>{numeral(creator.followers).format("0.0a")}</b> followers
							</Typography>
							<Typography
								style={{ marginTop: 30 }}
								gutterBottom
								component="div"
							>
								{creator.managers.map((manager, key) => (
									<Chip
										className={classes.chip}
										key={key}
										size="small"
										label={manager.fullName}
										onClick={_ => null}
									/>
								))}
							</Typography>
							<Typography
								variant="body2"
								style={{ textTransform: "uppercase" }}
							>
								{creator.address.street}
							</Typography>
							<Typography
								variant="body2"
								style={{ textTransform: "uppercase" }}
							>
								{creator.address.zip + " " + creator.address.city + ", " + creator.address.state + ", "}
								<b>{creator.address.country}</b>
							</Typography>
							<Typography
								variant="body2"
								style={{ textTransform: "uppercase" }}
							>
								{creator.phone}
							</Typography>
						</Grid>
						<Grid item sm={4} xs={12} style={{ position: "relative" }}>
							<SpeedDial
								className={classes.SpeedDial}
								style={isMobile ? { marginTop: -10 } : null}
								FabProps={{ size: "medium", className: classes.addButton }}
								ariaLabel="House Actions"
								icon={<SpeedDialIcon />}
								onClose={_ => setMenuOpen(false)}
								onOpen={_ => setMenuOpen(true)}
								open={menuOpen}
								direction="down"
							>
								{speedDialActions.map(
									(action, key) =>
										action !== null && (
											<SpeedDialAction
												key={key}
												icon={
													<Typography
														variant="body1"
														style={{ textTransform: "none" }}
													>
														{action.label}
													</Typography>
												}
												FabProps={{
													classes: { root: classes.speedDialActionStyle },
													variant: "extended",
													style: { height: 42, borderRadius: 3 }
												}}
												onClick={_ => {
													setMenuOpen(false);
													action.action();
												}}
												tooltipTitle={action.label + "2"}
											/>
										)
								)}
							</SpeedDial>
						</Grid>
						<Grid item xs={12} style={{ marginTop: 30 }}>
							<div className={classes.root}>
								<AppBar
									position="static"
									color="default"
									style={{ backgroundColor: grey[50] }}
								>
									<Tabs
										value={tab}
										onChange={handleChangeTab}
										indicatorColor="primary"
										textColor="primary"
										variant="scrollable"
										scrollButtons="on"
										classes={{ indicator: classes.tabIndicator }}
										aria-label="scrollable auto tabs example"
									>
										<Tab
											className={classes.tabLabel}
											color="default"
											label="Current Projects"
											{...a11yProps(0)}
										/>
										<Tab
											className={classes.tabLabel}
											label="Closed Projects"
											{...a11yProps(1)}
										/>
										<Tab
											className={classes.tabLabel}
											label="Calendar"
											{...a11yProps(2)}
										/>
										<Tab
											className={classes.tabLabel}
											label="Payments"
											{...a11yProps(3)}
										/>
									</Tabs>
								</AppBar>

								<TabPanel value={tab} index={0}>
									<Projects creator={creator} type="current" projects={projects}/>
								</TabPanel>
								<TabPanel value={tab} index={1}>
									<Projects creator={creator} type="closed" projects={projects}/>
								</TabPanel>
								<TabPanel value={tab} index={2}>
									<Calendar creator={creator} />
								</TabPanel>
								<TabPanel value={tab} index={3}>
									<Payments creator={creator} />
								</TabPanel>
							</div>
						</Grid>
						<Dialog
							open={addRateOpen}
							onClose={_ => {
								setAddRateOpen(false);
								setCreatorEdit(creator);
							}}
							aria-labelledby="form-dialog-title"
							fullWidth
						>
							<DialogTitle id="form-dialog-title">Edit Rates</DialogTitle>
							<DialogContent>
								<Grid
									container
									alignItems="center"
									justifyContent="center"
									spacing={1}
								>
									{creatorEdit.rates.map((value, key) => (
										<React.Fragment key={key}>
											<Grid item xs={5}>
												<TextField
													fullWidth
													autoCapitalize="false"
													name="rates"
													value={value.name}
													onChange={handleChangeRate(key, "name")}
													label={"Rate #" + (key + 1)}
													margin="dense"
													variant="filled"
													color="primary"
												/>
											</Grid>
											<Grid item xs={3}>
												<TextField
													fullWidth
													autoCapitalize="false"
													label="Amount"
													name="rates"
													margin="dense"
													variant="filled"
													color="primary"
													value={value.amount}
													onChange={handleChangeRate(key, "amount")}
												/>
											</Grid>
											<Grid item xs={3}>
												<TextField
													id="filled-select-currency"
													select
													fullWidth
													label="Currency"
													margin="dense"
													name="rates"
													defaultValue={creator.currency}
													value={value.currency}
													onChange={handleChangeRate(key, "currency")}
													variant="filled"

												>
													{currencies.map(option => (
														<MenuItem key={option.code} value={option.code}>
															{option.sign} {option.code}
														</MenuItem>
													))}
												</TextField>
											</Grid>
											<Grid item xs={1}>
												<IconButton
													aria-label="filter"
													onClick={_ => deleteRate(key)}
												>
													<Clear />
												</IconButton>
											</Grid>
										</React.Fragment>
									))}
									<Grid item xs={12}>
										<Button
											variant="contained"
											onClick={_ => {
												let rates = [...creatorEdit.rates];
												rates.push({ name: "", amount: "", currency:creator.currency });
												setCreatorEdit({ ...creatorEdit, rates: rates });
											}}
											fullWidth
										>
											Add Rate
										</Button>
									</Grid>
								</Grid>
							</DialogContent>
							<DialogActions>
								<Button
									onClick={_ => {
										setAddRateOpen(false);
										setCreatorEdit({ ...creator });
									}}
								>
									Cancel
								</Button>
								<div>
									<Button
										onClick={saveRates}
										color="primary"
										variant="contained"
										className={classes.button}
										disabled={rateSaving}
									>
										Save
									</Button>
									{rateSaving && (
										<CircularProgress
											size={24}
											className={classes.buttonProgressRate}
										/>
									)}
								</div>
							</DialogActions>
						</Dialog>
						 <ConfirmDialog
						    title={"Deleting " + creator.firstname}
						    open={confirmOpen}
						    setOpen={setConfirmOpen}
						    onConfirm={_ => {
						    	props.deleteUser(creator.id, _ => {
								});
						    }}
						  >
						    <Typography
								variant="body1"

							>
								Are you sure you want to delete {creator.firstname} {creator.lastname} ?
							</Typography>
						    <p> This action is permanent and cannot be undone.</p>
						  </ConfirmDialog>
						<EditCreator
							creator={creatorEdit}
							setCreator={setCreator}
							open={editOpen}
							setOpen={setEditOpen}
						/>
						<ShareCreator
							creator={creator}
							openDialog={shareOpen}
							setOpenDialog={setShareOpen}
						/>
					</>
				)}
			</Grid>
		</>
	);
};

function mapDispatchToProps(dispatch) {
	return {
		fetchCreators: _ => dispatch(fetchCreators()),
		updateCreator: (creator, callback) =>
			dispatch(updateCreatorWithCallback(creator, callback)),
		fetchProjectsList: (creator) =>
			dispatch(fetchProjectsList(creator)),
		deleteUser: (user, callback) =>
			dispatch(deleteUserCreator(user, callback))
	};
}

function mapStateToProps(state) {
	return {
		creators: state.creators,
		creatorsLoading: state.app.creatorsLoading,
		user: state.user,
		projects: state.projects
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(CreatorHouse);
