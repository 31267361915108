import React, { useState } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet-async";
import {
	signIn,
	loginAlertClose,
	sendPasswordResetEmail,
	passwordResetAlertClose
} from "../actions";
import { Link } from "@reach/router";

import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import {
	InputAdornment,
	IconButton,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions
} from "@material-ui/core";
import Fade from "@material-ui/core/Fade";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { grey } from "@material-ui/core/colors";

const useStyles = makeStyles(theme => ({
	container: {
		height: "100%",
		position: "absolute"
	},
	subContainter: {},
	logo: {
		height: 50,
		flexGrow: 1,
		marginBottom: 20
	},
	loader: {
		color: "#b9b9b9"
	},
	slogan: {
		marginTop: "15px",
		marginBottom: "20px"
	},
	button: {
		margin: "5px",
		marginLeft: "0px",
		marginRight: "0px"
	},
	buttonForgot: {
		margin: "5px",
		marginLeft: "0px",
		marginRight: "0px",
		color: "white"
	},
	buttonProgress: {
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12
	},
	buttonProgressReset: {
		position: "absolute",
		right: "45px",
		bottom: "13px"
	},
	buttonLoader: {
		position: "relative"
	},
	error: {
		backgroundColor: theme.palette.error.light
	},
	icon: {
		fontSize: 20
	},
	iconVariant: {
		opacity: 0.9,
		marginRight: theme.spacing(1)
	},
	message: {
		display: "flex",
		alignItems: "center"
	},
	resetTextField: {
		color: grey[900]
	}
}));

const LoginForm = props => {
	const classes = useStyles();
	const { signIn } = props;
	const [login, setLogin] = useState({ email: "", password: "" });
	const [fieldsError, setFieldsError] = useState({
		email: false,
		password: false
	});
	const [showPassword, setShowPassword] = useState(false);
	const [openDialog, setOpenDialog] = useState(false);
	const [resetEmail, setResetEmail] = useState("");

	const handleMouseDownPassword = event => {
		event.preventDefault();
	};

	const setTitle = _ => {
		return (
			<Helmet>
				<title>Log in to your account</title>
			</Helmet>
		);
	};

	const handleSubmit = e => {
		e.preventDefault();
		let fieldsErrorNew = {};
		Object.keys(login).forEach(key => {
			if (login[key] === "") {
				fieldsErrorNew = { ...fieldsErrorNew, [key]: true };
			} else {
				fieldsErrorNew = { ...fieldsErrorNew, [key]: false };
			}
		});
		setFieldsError({ ...fieldsErrorNew });
		if (!Object.values(fieldsErrorNew).find(el => el === true)) {
			signIn(login.email, login.password);
		}
	};

	const handleChange = name => event => {
		let val = event.target.value;
		setLogin({ ...login, [name]: val });
	};

	const closeAlert = _ => {
		props.loginAlertClose();
	};

	return (
		<>
			{setTitle()}
			<Fade in={true}>
				<form noValidate autoComplete="off" onSubmit={handleSubmit}>
					<Grid
						container
						direction="column"
						justifyContent="center"
						alignItems="center"
						className={classes.container}
					>
						<Grid item>
							<img
								className={classes.logo}
								src="/i+amhouse_handwritten_white.png"
								alt="Creators Manager"
							/>
						</Grid>
						<Grid
							container
							alignItems="center"
							justifyContent="center"
							className={classes.subContainter}
						>
							<Grid item xs={8} sm={4} md={3} lg={2}>
								<TextField
									fullWidth
									autoCapitalize="false"
									id="outlined-email"
									label="Email"
									margin="dense"
									variant="filled"
									value={login.email}
									onChange={handleChange("email")}
									error={fieldsError.email}
								/>
							</Grid>
						</Grid>
						<Grid
							container
							alignItems="center"
							justifyContent="center"
							className={classes.subContainter}
						>
							<Grid item xs={8} sm={4} md={3} lg={2}>
								<TextField
									fullWidth
									id="outlined-password"
									label="Password"
									margin="dense"
									variant="filled"
									type={showPassword ? "text" : "password"}
									value={login.password}
									onChange={handleChange("password")}
									error={fieldsError.password}
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<IconButton
													aria-label="toggle password visibility"
													onClick={_ => setShowPassword(!showPassword)}
													onMouseDown={handleMouseDownPassword}
													color="secondary"
												>
													{showPassword ? <Visibility /> : <VisibilityOff />}
												</IconButton>
											</InputAdornment>
										)
									}}
								/>
							</Grid>
						</Grid>
						<Grid
							container
							alignItems="center"
							justifyContent="center"
							className={classes.subContainter}
						>
							<Grid
								item
								xs={8}
								sm={4}
								md={3}
								lg={2}
								className={classes.buttonLoader}
								style={{ textAlign: "right" }}
							>
								<Button
									className={classes.buttonForgot}
									color="primary"
									size="small"
									style={{ marginTop: 0, fontSize: "smaller" }}
									component="a"
									onClick={_ => setOpenDialog(true)}
								>
									Forgot password?
								</Button>
							</Grid>
						</Grid>
						<Grid
							container
							alignItems="center"
							justifyContent="center"
							className={classes.subContainter}
						>
							<Grid
								item
								xs={8}
								sm={4}
								md={3}
								lg={2}
								className={classes.buttonLoader}
							>
								<Button
									className={classes.button}
									variant="contained"
									color="primary"
									type="submit"
									fullWidth
									disabled={props.loginLoading}
								>
									Log in
								</Button>
								{props.loginLoading && (
									<CircularProgress
										size={24}
										className={classes.buttonProgress}
									/>
								)}
							</Grid>
						</Grid>
					</Grid>
					<Button
						component={Link}
						className={classes.menuButton}
						to="/privacy-policy"
						style={{
							color: "white",
							position: "absolute",
							bottom: 0,
							left: "50%",
							transform: "translateX(-50%)"
						}}
						size="small"
					>
						Privacy Policy
					</Button>
					<Snackbar
						anchorOrigin={{
							vertical: "bottom",
							horizontal: "center"
						}}
						open={props.loginError !== null}
						onClose={closeAlert}
						autoHideDuration={6000}
					>
						<MuiAlert
							elevation={6}
							variant="filled"
							onClose={closeAlert}
							severity="error"
						>
							Invalid email or password
						</MuiAlert>
					</Snackbar>
				</form>
			</Fade>
			<Dialog
				open={openDialog}
				onClose={_ => setOpenDialog(false)}
				aria-labelledby="form-dialog-title"
			>
				<DialogTitle id="form-dialog-title">Forgot Password</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Lost your password? Please enter your email address. You will
						receive a link to create a new password via email.
					</DialogContentText>
					<TextField
						margin="dense"
						id="name"
						label="Email"
						type="email"
						variant="filled"
						InputProps={{ className: classes.resetTextField }}
						InputLabelProps={{ className: classes.resetTextField }}
						classes={{ root: classes.resetTextField }}
						color="secondary"
						value={resetEmail}
						onChange={e => setResetEmail(e.target.value)}
						fullWidth
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={_ => setOpenDialog(false)}>Cancel</Button>
					<div>
						<Button
							onClick={_ => props.sendPasswordResetEmail(resetEmail)}
							variant="contained"
							disabled={props.resetingPassword}
						>
							Recover
						</Button>
						{props.resetingPassword && (
							<CircularProgress
								size={24}
								className={classes.buttonProgressReset}
							/>
						)}
					</div>
				</DialogActions>
				<Snackbar
					anchorOrigin={{
						vertical: "top",
						horizontal: "center"
					}}
					open={props.resetPasswordEmailStatus !== "none"}
					onClose={_ => props.passwordResetAlertClose()}
					autoHideDuration={6000}
				>
					<MuiAlert
						elevation={6}
						variant="filled"
						onClose={_ => props.passwordResetAlertClose()}
						severity={
							props.resetPasswordEmailStatus === "none"
								? "success"
								: props.resetPasswordEmailStatus
						}
					>
						{props.resetPasswordEmailStatus === "error"
							? "Email " + resetEmail + " is invalid or account doesn't exist"
							: "Reset email successfully sent at " + resetEmail}
					</MuiAlert>
				</Snackbar>
			</Dialog>
		</>
	);
};

function mapDispatchToProps(dispatch) {
	return {
		signIn: (email, password) => dispatch(signIn(email, password)),
		loginAlertClose: _ => dispatch(loginAlertClose()),
		sendPasswordResetEmail: email => dispatch(sendPasswordResetEmail(email)),
		passwordResetAlertClose: _ => dispatch(passwordResetAlertClose())
	};
}

function mapStateToProps(state, ownProps) {
	return {
		loginLoading: state.app.loginLoading,
		loginError: state.app.loginError,
		resetingPassword: state.app.resetingPassword,
		resetPasswordEmailStatus: state.app.resetPasswordEmailStatus
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
