import React from "react";
import { Grid, Grow, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
	container: {
		height: "100%",
		position: "absolute",
		width: "100%"
	},
	logo: {
		height: 50,
		flexGrow: 1
	},
	loader: {
		color: "#e0dede"
	}
});

const Initializing = props => {
	const classes = useStyles();
	return (
		<>
			<Grid
				container
				direction="column"
				justifyContent="center"
				alignItems="center"
				className={classes.container}
				spacing={1}
			>
				<Grid item>
					<Grow in={true}>
						<img
							className={classes.logo}
							src="/iam-house-logo.png"
							alt="Creators Manager"
						/>
					</Grow>
				</Grid>
				<Grid item>
					<CircularProgress
						className={classes.loader}
						variant="indeterminate"
						size={20}
					/>
				</Grid>
			</Grid>
		</>
	);
};

export default Initializing;
